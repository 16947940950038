import React, { useContext, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import PatientDetails from '../../../Components/PatientInformation';
import SaveAndNavigate from '../../../Components/SaveAndNavigate';
import { PageDesignConstants, SideMenuItems, HTTPResponse, ToastMessageType, ToastMessages, NDMMCohortTreatmentID, NDMMCohortAgent } from '../../../Helpers/Enums';
import SideBarMenu from '../../../Components/SideBarMenu';
import ErrorField from "../../../Components/ErrorField";
import {
  CommonError_Msgs,
  NDMMTreatmentCohort_Msgs,
  hyperlinkTextQ21,
  predefinedReasons,
} from "../../../Helpers/HelperText";
import { AppContext } from "../../../Contexts/AppContextProvider";
import {
  GetLocalStorageData,
  GetLoggedInUserID,
  isStringEmpty,
  AllowNumberWithOneDecimal,
  AllowOnlyOneDecimal,
  getMaxFirstDate,
  getMaxLastDate,
  DateInRange,
  IsAdmin,
} from "../../../Helpers/Utilities";
import NDMMAgentData from "./NDMMAgentData";
import { NDMMTreatmentCohortService } from "../../../WebApiServices/NDMMTreatmentCohortService.service";
import InfoPopUp from "../../../Components/InfoPopUp";
import dayjs from "dayjs";

const NDMMTreatmentCohort = () => {
  const [rrmmAvailable, setRrmmAvailable] = useState(false);
  const navigatePaths = {
    prevPage: "/CohortDefiningTreatment",
    currPage: "/NDMMTreatmentCohort",
    nextPage: rrmmAvailable
                ? "/BispecificsCartTreatmentCohort"
                : "/LineOfTherapy"
    // nextPage: "/LineOfTherapy",
  };

  const pid = GetLocalStorageData("patientId") ?? 0;

  const storedData = GetLocalStorageData("CohortTreatment");
  let cohortTreatmentModelListt = [];
  if (storedData) {
    cohortTreatmentModelListt = JSON.parse(storedData);
  }
  const { ShowToast, ToggleLoader, HandleSessionTimeout,ShowActionPopUp,isSideMenuActive,setIsSideMenuActive  } =
    useContext(AppContext);
  const [showTopErrMsg, setShowTopErrMsg] = useState(false);
  const [showHideHyperlinkModal, setShowHideHyperlinkModal] = useState(false);

  //DN 23/7/24 Questions data
  const [ndmmTreatmentCohortDetails, setNDMMTreatmentCohortDetails] = useState({
    userId: GetLoggedInUserID(),

    q22_agentList: [],
    q23_agentList: [],
    q24_agentList: [],
    q25_agentList: [],
    cohortTreatmentSCT: null,

    endOfFollowUp: null,
    cohortNdmmId: null,
    ndmmDisplayName: null,
    ndmmIndexDate: null,

    q21_data: null,
    q21_1_IT: true,
    q21_1_CT: false,
    q21_1_MT: false,
    q22_agentData: [],
    q23_agentData: [],
    q24_agentData: [],
    q25_agentData: [],

    q22_org_agentData: [],
    q23_org_agentData: [],
    q24_org_agentData: [],
    q25_org_agentData: [],

    nDMMAgentBridgeToDeleteLists: [],

    show_q21_q25: false,
    show_q21_1: false,
    show_q25: false,
    show_q25_tie: false,
  });

  // DN 23/7/24 Initial load
  useEffect(() => {
    async function FetchData() {
      setRrmmAvailable(cohortTreatmentModelListt.filter(c => c.isEligible == true && c.ndmm == false)?.length > 0)
      LoadTableData(); // Fetch all Table agent lists
      if (!isNaN(pid) && Number(pid) !== 0) {
        ToggleLoader(true);
        await GetNDMMCohortTreatmentData(); // Fetch all data of the patient & Static rows
        ToggleLoader(false);
      }
    }
    FetchData();
  }, []);

  async function LoadTableData() {
    
    const response = await NDMMTreatmentCohortService.GetTableData(pid);
    if (response?.status !== HTTPResponse.OK) {
      if (response?.status === HTTPResponse.Unauthorized)
        HandleSessionTimeout();
      else if (response?.status === HTTPResponse.BadRequest)
        throw response.error;
    } else {
      const { data } = response;
      setNDMMTreatmentCohortDetails((prev) => ({
        ...prev,
        q22_agentList: data.q22_agentLists,
        q23_agentList: data.q23_agentLists,
        q24_agentList: data.q24_agentLists,
        q25_agentList: data.q25_agentLists,
      }));
    }
  }

  async function GetNDMMCohortTreatmentData() {
    if (cohortTreatmentModelListt) {
      cohortTreatmentModelListt.filter(f => f.ndmm && f.isEligible).forEach((data) => {
        setNDMMTreatmentCohortDetails((prev) => ({
          ...prev,
            cohortNdmmId: data.cohortTreatmentID,// data.cohortTreatmentName,
            ndmmDisplayName: (data.cohortTreatmentName === "DKRd (TE or TIE)") ? (data.cohortTreatmentSCT === 1 ? "DKRd (TE)" : (data.cohortTreatmentSCT === 2 ? "DKRd (TIE)" : data.cohortTreatmentName)): data.cohortTreatmentName,

          ndmmIndexDate: data.cohortIndexDate,
          show_q21_q25: (data.cohortTreatmentID == NDMMCohortTreatmentID.DVRd_TE || (data.cohortTreatmentID == NDMMCohortTreatmentID.DKRd_TE && data.cohortTreatmentSCT === 1) || data.cohortTreatmentID == NDMMCohortTreatmentID.VRd_TE || data.cohortTreatmentID == NDMMCohortTreatmentID.ISAVRD_TE) ? true : false,
          show_q25_tie: (data.cohortTreatmentID == NDMMCohortTreatmentID.DRd_TIE || data.cohortTreatmentID == NDMMCohortTreatmentID.DVRd_TIE || (data.cohortTreatmentID == NDMMCohortTreatmentID.DKRd_TIE && data.cohortTreatmentSCT === 2) || data.cohortTreatmentID == NDMMCohortTreatmentID.VRd_TIE || data.cohortTreatmentID == NDMMCohortTreatmentID.ISAVRD_TIE) ? true : false,
        }));
      });
    }

    const response =
      await NDMMTreatmentCohortService.GetNDMMCohortTreatmentData(pid);

    if (response?.status !== HTTPResponse.OK) {
      if (response?.status === HTTPResponse.Unauthorized)
        HandleSessionTimeout();
      else if (response?.status === HTTPResponse.BadRequest)
        throw response.error;
    } else {
      ToggleLoader(false);
      const { data } = response;
      setNDMMTreatmentCohortDetails((prev) => ({
        ...prev,
        endOfFollowUp: data.endOfFollowUpDate,
        show_q21_1: data.chartDifferent,
        show_q25: data.chartDifferent === false ? true : false,
        q21_data:
          data.chartDifferent === true
            ? 1
            : data.chartDifferent === false
              ? 2
              : 0,
        q21_1_IT: data.inductionPhase,
        q21_1_CT: data.consolidatePhase,
        q21_1_MT: data.maintenancePhase,
        q22_agentData:
          data.agentTreatmentLists != null
            ? data.agentTreatmentLists.filter((p) => p.phaseid === 1)
            : [],
        q23_agentData:
          data.agentTreatmentLists != null
            ? data.agentTreatmentLists.filter((p) => p.phaseid === 2)
            : [],
        q24_agentData:
          data.agentTreatmentLists != null
            ? data.agentTreatmentLists.filter((p) => p.phaseid === 3)
            : [],
        q25_agentData:
          data.agentTreatmentLists != null
            ? data.agentTreatmentLists.filter((p) => p.phaseid === 4)
            : [],
        q22_org_agentData:
          data.agentTreatmentLists != null
            ? data.agentTreatmentLists.filter((p) => p.phaseid === 1)
            : [],
        q23_org_agentData:
          data.agentTreatmentLists != null
            ? data.agentTreatmentLists.filter((p) => p.phaseid === 2)
            : [],
        q24_org_agentData:
          data.agentTreatmentLists != null
            ? data.agentTreatmentLists.filter((p) => p.phaseid === 3)
            : [],
        q25_org_agentData:
          data.agentTreatmentLists != null
            ? data.agentTreatmentLists.filter((p) => p.phaseid === 4)
            : [],
        nDMMAgentBridgeToDeleteLists: [],
      }));
    }
  }

  async function ValidateForm() {
   
    let result = true;

    //Q21
    if (ndmmTreatmentCohortDetails.show_q21_q25) {
      if (isStringEmpty(ndmmTreatmentCohortDetails.q21_data)) {
        result = false;
        setHardErrList((prev) => ({
          ...prev,
          q21Empty: true,
        }));
      } else {
        setHardErrList((prev) => ({
          ...prev,
          q21Empty: false,
        }));
      }
    }

    //Q21.1
    if (
      ndmmTreatmentCohortDetails.q21_1_CT ||
      ndmmTreatmentCohortDetails.q21_1_MT
    ) {
      if (
        ndmmTreatmentCohortDetails.q21_1_CT &&
        ndmmTreatmentCohortDetails.q23_agentData.length === 0
      ) {
        result = false;
        setHardErrList((prev) => ({
          ...prev,
          q23Empty: true,
        }));
      } else {
        setHardErrList((prev) => ({
          ...prev,
          q23Empty: false,
        }));
      }

      if (
        ndmmTreatmentCohortDetails.q21_1_MT &&
        ndmmTreatmentCohortDetails.q24_agentData.length === 0
      ) {
        result = false;
        setHardErrList((prev) => ({
          ...prev,
          q24Empty: true,
        }));
      } else {
        setHardErrList((prev) => ({
          ...prev,
          q24Empty: false,
        }));
      }
    }

    //Q22 and Q25 - Check if the start date of any rows is same as index date
    // debugger
    if (
      ndmmTreatmentCohortDetails.show_q21_q25 &&
      !ndmmTreatmentCohortDetails.show_q25
    ) {
      // const isUnkSelectedq22 = ndmmTreatmentCohortDetails.q22_agentData.some(row => row.firstDateUnk === 1);
      // if (!isUnkSelectedq22) {
      let listOfDatesExceptUnk =
        ndmmTreatmentCohortDetails.q22_agentData.filter(
          (r) => r.firstDateUnk !== 1
        );
      if (listOfDatesExceptUnk.length > 0) {
        const start_indexAnyq22 = listOfDatesExceptUnk.some(
          (row) => row.firstDate === ndmmTreatmentCohortDetails.ndmmIndexDate
        );

        if (!start_indexAnyq22) {
          result = false;
          ndmmTreatmentCohortDetails.q22_agentData =
            ndmmTreatmentCohortDetails.q22_agentData.map((item) => {
              return {
                ...item,
                showColB_IdxDateError:
                  item.firstDate === "" || item.firstDate === null ||  item.firstDate === "Invalid Date" || !DateInRange(item.firstDate, dayjs("01-01-1920"), dayjs())
                    ? false
                    : true,
              };
            });
        } else {
          ndmmTreatmentCohortDetails.q22_agentData =
            ndmmTreatmentCohortDetails.q22_agentData.map((item) => {
              return { ...item, showColB_IdxDateError: false };
            });
        }
      }
      //}
    }

    if (
      (!ndmmTreatmentCohortDetails.show_q21_q25 &&
        ndmmTreatmentCohortDetails.show_q25_tie) ||
      (ndmmTreatmentCohortDetails.show_q21_q25 &&
        ndmmTreatmentCohortDetails.show_q25)
    ) {
      // const isUnkSelectedq25 = ndmmTreatmentCohortDetails.q25_agentData.some(row => row.firstDateUnk === 1);
      // if (!isUnkSelectedq25) {

      let listOfDatesExceptUnk =
        ndmmTreatmentCohortDetails.q25_agentData.filter(
          (r) => r.firstDateUnk !== 1
        );
      if (listOfDatesExceptUnk.length > 0) {
        const start_indexAnyq25 = listOfDatesExceptUnk.some(
          (row) => row.firstDate === ndmmTreatmentCohortDetails.ndmmIndexDate
        );

        if (!start_indexAnyq25) {
          result = false;
          ndmmTreatmentCohortDetails.q25_agentData =
            ndmmTreatmentCohortDetails.q25_agentData.map((item) => {
              return {
                ...item,
                showColB_IdxDateError:
                  item.firstDate === "" || item.firstDate === null ||  item.firstDate === "Invalid Date"
                    ? false
                    : true,
              };
            });
        } else {
          ndmmTreatmentCohortDetails.q25_agentData =
            ndmmTreatmentCohortDetails.q25_agentData.map((item) => {
              return { ...item, showColB_IdxDateError: false };
            });
        }
        // }
      }
    }

    let repeaterarray_q22 = ndmmTreatmentCohortDetails.q22_agentData;
    let repeaterarray_q23 = ndmmTreatmentCohortDetails.q23_agentData;
    let repeaterarray_q24 = ndmmTreatmentCohortDetails.q24_agentData;
    let repeaterarray_q25 = ndmmTreatmentCohortDetails.q25_agentData;

    let resultPromise = [];
    if (
      ndmmTreatmentCohortDetails.show_q21_q25 &&
      !ndmmTreatmentCohortDetails.show_q25
    ) {
      if (
        ndmmTreatmentCohortDetails.q21_1_CT &&
        repeaterarray_q23.length > 0 &&
        ndmmTreatmentCohortDetails.q21_1_MT &&
        repeaterarray_q24.length > 0
      ) {
        resultPromise = await Promise.all([
          ValidateRepeater(repeaterarray_q22),
          ValidateRepeater(repeaterarray_q23),
          ValidateRepeater(repeaterarray_q24),
        ]);
      } else if (
        (ndmmTreatmentCohortDetails.q21_1_CT &&
          repeaterarray_q23.length > 0 &&
          !ndmmTreatmentCohortDetails.q21_1_MT) ||
        (ndmmTreatmentCohortDetails.q21_1_CT &&
          repeaterarray_q23.length > 0 &&
          ndmmTreatmentCohortDetails.q21_1_MT &&
          repeaterarray_q24.length == 0)
      ) {
        resultPromise = await Promise.all([
          ValidateRepeater(repeaterarray_q22),
          ValidateRepeater(repeaterarray_q23),
        ]);
      } else if (
        (ndmmTreatmentCohortDetails.q21_1_MT &&
          repeaterarray_q24.length > 0 &&
          !ndmmTreatmentCohortDetails.q21_1_CT) ||
        (ndmmTreatmentCohortDetails.q21_1_MT &&
          repeaterarray_q24.length > 0 &&
          ndmmTreatmentCohortDetails.q21_1_CT &&
          repeaterarray_q23.length == 0)
      ) {
        resultPromise = await Promise.all([
          ValidateRepeater(repeaterarray_q22),
          ValidateRepeater(repeaterarray_q24),
        ]);
      } else {
        resultPromise = await Promise.all([
          ValidateRepeater(repeaterarray_q22),
        ]);
      }
    } else if (
      (ndmmTreatmentCohortDetails.show_q21_q25 &&
        ndmmTreatmentCohortDetails.show_q25) ||
      (!ndmmTreatmentCohortDetails.show_q21_q25 &&
        ndmmTreatmentCohortDetails.show_q25_tie)
    ) {
      resultPromise = await Promise.all([ValidateRepeater(repeaterarray_q25)]);
    }

    if (resultPromise.includes(false)) {
      result = false;
    }
    return result;
  }

  async function ValidateRepeater(list) {
    // Your asynchronous processing logic for the list goes here
    const promises = list.map((item) => ValidateList(item));
    const results = await Promise.all(promises);
    return !results.includes(false);
  }

  async function ValidateList(item) {
    // Your asynchronous processing logic for an individual item
    return new Promise((resolve) => {
      setTimeout(() => {
        const isValid = ValidateRepeaterData(item);
        resolve(isValid);
      }, 1000); // Simulate async work
    });
  }

  async function ValidateRepeaterData(repeaterarray) {
    let result = true;

    //Q22 a
    if (
      isStringEmpty(repeaterarray.agentName) ||
      repeaterarray.agentName === null
    ) {
      result = false;
      repeaterarray.showColAError = true;
    } else {
      repeaterarray.showColAError = false;

      if (
        repeaterarray.agentName.includes("Other") &&
        (repeaterarray.agentOtherValue === null ||
          repeaterarray.agentOtherValue === "")
      ) {
        result = false;
        repeaterarray.showColAOthError = true;
      } else {
        if (repeaterarray.agentName.includes("Other")) {
          const enteredValue = repeaterarray.agentOtherValue
            ? repeaterarray.agentOtherValue.trim().toLowerCase()
            : "";

          //check if Other text has same value as the agent dropdown or same as any other text from the list, display error
          if (repeaterarray.phaseid === 2) {
            let savedAgentList =
              ndmmTreatmentCohortDetails.q23_agentList;

            let unsavedAgentList =
              ndmmTreatmentCohortDetails.q23_agentData;

            // Count the occurrences of the entered value in the unsaved agent list
            const unsavedOtherTextCount = unsavedAgentList.reduce(
              (count, agent) => {
                const agentOtherValue = agent.agentOtherValue
                  ? agent.agentOtherValue.trim().toLowerCase()
                  : "";
                const agentName = agent.agentName
                  ? agent.agentName.trim().toLowerCase()
                  : "";
                if (
                  agentOtherValue === enteredValue ||
                  agentName === enteredValue
                ) {
                  return count + 1;
                }
                return count;
              },
              0
            );

            const otherTextExist = savedAgentList.some(
              (agent) =>
                agent.agentName.trim().toLowerCase() === enteredValue
            );

            if (otherTextExist || unsavedOtherTextCount > 1) {
              result = false;
              repeaterarray.showColAOthDupError = true;
            } else {
              repeaterarray.showColAOthDupError = false;
            }
          }

          if (repeaterarray.phaseid === 3) {
            let savedAgentList =
              ndmmTreatmentCohortDetails.q24_agentList;

            let unsavedAgentList =
              ndmmTreatmentCohortDetails.q24_agentData;

            // Count the occurrences of the entered value in the unsaved agent list
            const unsavedOtherTextCount = unsavedAgentList.reduce(
              (count, agent) => {
                const agentOtherValue = agent.agentOtherValue
                  ? agent.agentOtherValue.trim().toLowerCase()
                  : "";
                const agentName = agent.agentName
                  ? agent.agentName.trim().toLowerCase()
                  : "";
                if (
                  agentOtherValue === enteredValue ||
                  agentName === enteredValue
                ) {
                  return count + 1;
                }
                return count;
              },
              0
            );

            const otherTextExist = savedAgentList.some(
              (agent) =>
                agent.agentName.trim().toLowerCase() === enteredValue
            );

            if (otherTextExist || unsavedOtherTextCount > 1) {
              result = false;
              repeaterarray.showColAOthDupError = true;
            } else {
              repeaterarray.showColAOthDupError = false;
            }
          }
        } else {
          repeaterarray.showColAOthDupError = false;
        }

        repeaterarray.showColAOthError = false;
      }
    }
      debugger;
     debugger
    //Q22 b
    if (
      isStringEmpty(repeaterarray.firstDate) &&
      isStringEmpty(repeaterarray.firstDateRadio) &&
      isStringEmpty(repeaterarray.firstDateUnk) //&& 
      //DateInRange(repeaterarray.firstDate, dayjs("01-01-1920"), dayjs())
    ) {
      result = false;
      repeaterarray.showColBError = true;
    } else {
      if (
        repeaterarray.firstDateRadio === 1 &&
        isStringEmpty(repeaterarray.firstDate) && repeaterarray.firstDate !== "Invalid Date"  && DateInRange(repeaterarray.firstDate, dayjs("01-01-1920"), dayjs())
      ) {
        result = false;
        repeaterarray.showColBError = true;
      }      
      else repeaterarray.showColBError = false;
    }
    if (repeaterarray.firstDate === "Invalid Date")
    {
      result = false;
      repeaterarray.showColBInvalidDate = true;
    }
    else if (repeaterarray.firstDate !== "Invalid Date" && repeaterarray.firstDate !== "" && repeaterarray.firstDate !== null && !DateInRange(repeaterarray.firstDate, dayjs("01-01-1920"), dayjs())){
      result = false;
        repeaterarray.showColBInvalidDate = true;
    }  

    //Q22 c
    if (
      isStringEmpty(repeaterarray.lastDate) &&
      isStringEmpty(repeaterarray.lastDateRadio) &&
      isStringEmpty(repeaterarray.lastDateUnk) //&&
      // DateInRange(repeaterarray.firstDate, dayjs("01-01-1920"), dayjs())

    ) {
      result = false;
      repeaterarray.showColCError = true;
    } else {
      if (
        repeaterarray.lastDateRadio === 1 &&
        isStringEmpty(repeaterarray.lastDate) && DateInRange(repeaterarray.lastDate, dayjs("01-01-1920"), dayjs())
      ) {
        result = false;
        repeaterarray.showColCError = true;
      } else repeaterarray.showColCError = false;
    }
    if (repeaterarray.lastDate === "Invalid Date")
    {
      result = false;
      repeaterarray.showColCInvalidDate = true;
    }
    else if (repeaterarray.lastDate !== "Invalid Date" && repeaterarray.lastDate !== "" && repeaterarray.lastDate !== null && !DateInRange(repeaterarray.lastDate, dayjs("01-01-1920"), dayjs())){
      result = false;
        repeaterarray.showColCInvalidDate = true;
    }  

    //Q22 d
    if (
      (isStringEmpty(repeaterarray.noOfAdmission) &&
        isStringEmpty(repeaterarray.noOfAdmissionRadio) &&
        isStringEmpty(repeaterarray.noOfAdmissionUnk) &&
        isStringEmpty(repeaterarray.noOfAdmissionUnkTxt)) ||
      parseInt(repeaterarray.noOfAdmission) < 1 ||
      parseInt(repeaterarray.noOfAdmission) > 4000 ||
      (repeaterarray.noOfAdmissionRadio === 1 &&
        isStringEmpty(repeaterarray.noOfAdmission))
    ) {
      result = false;
      repeaterarray.showColDError = true;
    } else {
      repeaterarray.showColDError = false;
      
                    
      if (repeaterarray.noOfAdmissionUnk === 1 && isStringEmpty(repeaterarray.noOfAdmissionUnkTxt)) {
        result = false;
        repeaterarray.showColDUkTxtError = true;
      }
      else
        repeaterarray.showColDUkTxtError = false;
      }


      if (repeaterarray.phaseid === 1 || repeaterarray.phaseid === 4) {
          //Q22G
          if (
              (isStringEmpty(repeaterarray.doseAdministrationmg) && isStringEmpty(repeaterarray.doseAdministrationRadiomg)) &&
              (isStringEmpty(repeaterarray.doseAdministrationkg) && isStringEmpty(repeaterarray.doseAdministrationRadiokg)) &&
              (isStringEmpty(repeaterarray.doseAdministrationmgm) && isStringEmpty(repeaterarray.doseAdministrationRadiomgm))
          ) {

              result = false;
              repeaterarray.showColGError = true;
          }
          else {
              if ((repeaterarray.doseAdministrationRadiomg === 1 && (isStringEmpty(repeaterarray.doseAdministrationmg) || (parseFloat(repeaterarray.doseAdministrationmg) < 0.01) || (parseFloat(repeaterarray.doseAdministrationmg) > 4000))) ||
                  (repeaterarray.doseAdministrationRadiokg === 1 && isStringEmpty(repeaterarray.doseAdministrationkg) || (parseFloat(repeaterarray.doseAdministrationkg) < 0.01) || (parseFloat(repeaterarray.doseAdministrationkg) > 4000)) ||
                  (repeaterarray.doseAdministrationRadiomgm === 1 && isStringEmpty(repeaterarray.doseAdministrationmgm) || (parseFloat(repeaterarray.doseAdministrationmgm) < 0.01) || (parseFloat(repeaterarray.doseAdministrationmgm) > 4000))
              ) {
                  result = false;
                  repeaterarray.showColGError = true;
              }
              else
                  repeaterarray.showColGError = false;
          } 
      }


    //Q22 e
    if (isStringEmpty(repeaterarray.discontinueAgent)) {
      result = false;
      repeaterarray.showColEError = true;
    } else {
      repeaterarray.showColEError = false;
    }

    //Q22 f
    if (repeaterarray.discontinueAgent === 1) {
      const isAnySelected = repeaterarray.reasonForDiscontinuingAgents.some(
        (item) => item.isSelected
      );
      if (
        isAnySelected ||
        repeaterarray.reasonForDiscontinuingAgents[0].reasonUnk === true ||
        repeaterarray.reasonForDiscontinuingAgents[0].reasonUnk === 1
      ) {
        repeaterarray.showColFError = false;

        const isReasonOtherSelected =
          repeaterarray.reasonForDiscontinuingAgents.some(
            (item) =>
              item.isSelected &&
              item.reasonName.includes("Other") &&
              (item.reasonText === null || item.reasonText.trim() === "")
          );
        if (isReasonOtherSelected) {
          result = false;
          repeaterarray.showColFOthError = true;
        } else repeaterarray.showColFOthError = false;
      } else {
        result = false;
        repeaterarray.showColFError = true;
      }
    }
// debugger
    //if Q22 to Q25
    const startDt =
      repeaterarray.firstDate === null || repeaterarray.firstDate === "" || repeaterarray.firstDate === "Invalid Date"  || !DateInRange(repeaterarray.firstDate, dayjs("01-01-1920"), dayjs())
        ? null
        : parseDate(repeaterarray.firstDate);
    const endDt =
      repeaterarray.lastDate === null || repeaterarray.lastDate === "" || repeaterarray.lastDate === "Invalid Date" || !DateInRange(repeaterarray.lastDate, dayjs("01-01-1920"), dayjs())
        ? null
        : parseDate(repeaterarray.lastDate);
    const endOfFollowUpDt =
      ndmmTreatmentCohortDetails.endOfFollowUp === null ||
        ndmmTreatmentCohortDetails.endOfFollowUp === ""
        ? null
        : parseDate(ndmmTreatmentCohortDetails.endOfFollowUp);
        
    if (repeaterarray.phaseid === 1 || repeaterarray.phaseid === 4) {
      // If Q22 and Q25
      // Validate the parsed dates
      if (repeaterarray.lastDateUnk !== 1) {
        // Start, end, and end of follow-up present
        if (startDt != null && endDt != null && endOfFollowUpDt != null) {
          // Check if the start date is valid
          const isStartDateValid = startDt <= endDt;

          // Check if the end date is valid
            const isEndDateValid = endDt <= endOfFollowUpDt;// && isStartDateValid

          // Set error flags
          if (!isStartDateValid) {
            repeaterarray.showColB_SEFDateError = true; // Start date is invalid
            result = false;
          } else {
            repeaterarray.showColB_SEFDateError = false; // Start date is valid
            }

          if (!isStartDateValid) {//if (!isEndDateValid) {
            repeaterarray.showColC_SEFDateError = true; // End date is invalid
            result = false;
          } else {
            repeaterarray.showColC_SEFDateError = false; // End date is valid
          }
        }
      }
    } else if (repeaterarray.phaseid === 2 || repeaterarray.phaseid === 3) {
      // if Q23 and Q24
      let maxLastDate = "";

      if (
        repeaterarray.phaseid === 2 ||
        (repeaterarray.phaseid === 3 &&
          ndmmTreatmentCohortDetails.q21_1_CT === false)
      ) {
        let listOfDatesExceptUnk =
          ndmmTreatmentCohortDetails.q22_agentData.filter(
            (item) => item.lastDate !== null && item.lastDate !== ""
          );
        if (listOfDatesExceptUnk.length > 0) {
          const maxLastDate22 = getMaxLastDate(listOfDatesExceptUnk);
          maxLastDate = maxLastDate22;
        }
      } else if (
        repeaterarray.phaseid === 3 &&
        ndmmTreatmentCohortDetails.q21_1_CT === true &&
        ndmmTreatmentCohortDetails.q23_agentData.length > 0
      ) {
        let listOfDatesExceptUnk =
          ndmmTreatmentCohortDetails.q23_agentData.filter(
            (item) => item.lastDate !== null && item.lastDate !== ""
          );
        if (listOfDatesExceptUnk.length > 0) {
          const maxLastDate23 = getMaxLastDate(listOfDatesExceptUnk);
          maxLastDate = maxLastDate23;
        }
      }

      // Validate date ranges with maxLastDate
      if (
        maxLastDate !== "" &&
        startDt != null &&
        endDt != null &&
        endOfFollowUpDt != null
      ) {
        const isStartDateValid = maxLastDate <= startDt && startDt <= endDt;
        const isEndDateValid = endDt <= endOfFollowUpDt;

        // Set error flags
        if (!isStartDateValid) {
          repeaterarray.showColB_SEFDateError = true; // Start date is invalid
          result = false;
        } else {
          repeaterarray.showColB_SEFDateError = false; // Start date is valid
          }

        if (!isStartDateValid) {//if (!isEndDateValid) {
          repeaterarray.showColC_SEFDateError = true; // End date is invalid
          result = false;
        } else {
          repeaterarray.showColC_SEFDateError = false; // End date is valid
        }
      } else if (startDt != null && endDt != null && endOfFollowUpDt != null) {
        // Validate without maxLastDate
        const isStartDateValid = startDt <= endDt;
        const isEndDateValid = endDt <= endOfFollowUpDt;

        // Set error flags
        if (!isStartDateValid) {
          repeaterarray.showColB_SEFDateError = true; // Start date is invalid
          result = false;
        } else {
          repeaterarray.showColB_SEFDateError = false; // Start date is valid
          }

        if (!isStartDateValid) {//if (!isEndDateValid) {
          repeaterarray.showColC_SEFDateError = true; // End date is invalid
          result = false;
        } else {
          repeaterarray.showColC_SEFDateError = false; // End date is valid
        }
      }

      //New Changes: induction agent start date ≤ induction agent end date ≤ consolidation agent start date ≤ consolidation agent end date ≤ maintenance agent start date ≤ maintenance agent end date ≤ end of follow up
      let maxIStartDate = "";
      let maxILastDate = "";
      let maxCStartDate = "";
      let maxCLastDate = "";
      let maxMStartDate = "";
      let maxMLastDate = "";
      if (ndmmTreatmentCohortDetails.q22_agentData.length > 0) {
        let listOfIFirstDatesExceptUnk =
          ndmmTreatmentCohortDetails.q22_agentData.filter(
            (item) => item.firstDate !== null && item.firstDate !== "" && item.firstDate !== "Invalid Date"
          );
        if (listOfIFirstDatesExceptUnk.length > 0) {
          const maxFirstDate22 = getMaxFirstDate(listOfIFirstDatesExceptUnk);
          maxIStartDate = maxFirstDate22;
        }

        let listOfILastDatesExceptUnk =
          ndmmTreatmentCohortDetails.q22_agentData.filter(
            (item) => item.lastDate !== null && item.lastDate !== "" && item.firstDate !== "Invalid Date"
          );

        if (listOfILastDatesExceptUnk.length > 0) {
          const maxLastDate22 = getMaxLastDate(listOfILastDatesExceptUnk);
          maxILastDate = maxLastDate22;
        }
      }
      if (ndmmTreatmentCohortDetails.q23_agentData.length > 0) {
        let listOfCFirstDatesExceptUnk =
          ndmmTreatmentCohortDetails.q23_agentData.filter(
            (item) => item.firstDate !== null && item.firstDate !== "" && item.firstDate !== "Invalid Date"
          );

        if (listOfCFirstDatesExceptUnk.length > 0) {
          const maxFirstDate22 = getMaxFirstDate(listOfCFirstDatesExceptUnk);
          maxCStartDate = maxFirstDate22;
        }

        let listOfCLastDatesExceptUnk =
          ndmmTreatmentCohortDetails.q23_agentData.filter(
            (item) => item.lastDate !== null && item.lastDate !== "" && item.lastDate !== "Invalid Date"
          );

        if (listOfCLastDatesExceptUnk.length > 0) {
          const maxLastDate22 = getMaxLastDate(listOfCLastDatesExceptUnk);
          maxCLastDate = maxLastDate22;
        }
      }
      if (ndmmTreatmentCohortDetails.q24_agentData.length > 0) {
        let listOfMFirstDatesExceptUnk =
          ndmmTreatmentCohortDetails.q24_agentData.filter(
            (item) => item.firstDate !== null && item.firstDate !== "" && item.firstDate !== "Invalid Date"
          );

        if (listOfMFirstDatesExceptUnk.length > 0) {
          const maxFirstDate22 = getMaxFirstDate(listOfMFirstDatesExceptUnk);
          maxMStartDate = maxFirstDate22;
        }

        let listOfMLastDatesExceptUnk =
          ndmmTreatmentCohortDetails.q24_agentData.filter(
            (item) => item.lastDate !== null && item.lastDate !== "" && item.lastDate !== "Invalid Date"
          );

        if (listOfMLastDatesExceptUnk.length > 0) {
          const maxLastDate22 = getMaxLastDate(listOfMLastDatesExceptUnk);
          maxMLastDate = maxLastDate22;
        }
      }

      // consolidation validation
      if (repeaterarray.phaseid === 2) {
        //if (startDt && endDt) {
          const isMaxIStartDateValid =
            maxIStartDate !== "" && maxIStartDate !== null;
          const ismaxILastDateValid =
            maxILastDate !== "" && maxILastDate !== null;

          if (startDt) {
            const isStartDateValid =
              (!isMaxIStartDateValid || maxIStartDate <= startDt) &&
              (!ismaxILastDateValid || maxILastDate <= startDt) &&
              startDt <= endDt;

            if (isStartDateValid) {
              repeaterarray.showColB_SEFDateError = false; // Start date is valid
            } else {
              result = false;
              repeaterarray.showColB_SEFDateError = true; // Start date is invalid
            }
          }

          if (endDt) {
            const isEndDateValid =
              endDt <= endOfFollowUpDt &&
              (!isMaxIStartDateValid || maxIStartDate <= endOfFollowUpDt) &&
              (!ismaxILastDateValid || maxILastDate <= endOfFollowUpDt);

            if (isEndDateValid) {
              repeaterarray.showColC_SEFDateError = false; // End date is valid
            } else {
              result = false;
              repeaterarray.showColC_SEFDateError = true; // End date is invalid
            }
          }
       // }
      }

      // maintenance validation
      if (repeaterarray.phaseid === 3) {

        const isInductionStartDateValid =
          maxIStartDate !== "" && maxIStartDate !== null;
        const isInductionEndDateValid =
          maxILastDate !== "" && maxILastDate !== null;
        const isConsolidationStartDateValid =
          maxCStartDate !== "" && maxCStartDate !== null;
        const isConsolidationEndDateValid =
          maxCLastDate !== "" && maxCLastDate !== null;
        const isMaintenanceStartDateValid = startDt !== "" && startDt !== null;
        const isMaintenanceEndDateValid = endDt !== "" && endDt !== null;
        const isEndOfFollowUpValid =
          endOfFollowUpDt !== "" && endOfFollowUpDt !== null;

        if (startDt) {
          // Validate start date sequence
          const isStartDateValid =
            (!isInductionStartDateValid ||!isInductionEndDateValid|| maxIStartDate <= maxILastDate) &&
            (!isInductionEndDateValid || !isConsolidationStartDateValid|| maxILastDate <= maxCStartDate) &&
            (!isConsolidationStartDateValid || !isConsolidationEndDateValid || maxCStartDate <= maxCLastDate) &&
          (!isInductionEndDateValid || maxILastDate <= startDt) &&
            (!isConsolidationEndDateValid || maxCLastDate <= startDt);

          // Set error flags
          if (isStartDateValid) {
            repeaterarray.showColB_SEFDateError = false; // Start date is valid
          } else {
            repeaterarray.showColB_SEFDateError = true; // Start date is invalid
            return false;
          }
        }

        if (endDt) {
          // Validate end date sequence
          const isEndDateValid =
            (!isMaintenanceStartDateValid || startDt <= endDt) &&
            (!isMaintenanceEndDateValid || endDt <= endOfFollowUpDt) &&
            (!isEndOfFollowUpValid ||
              (isMaintenanceEndDateValid && endDt <= endOfFollowUpDt));

          if (isEndDateValid) {
            repeaterarray.showColC_SEFDateError = false; // End date is valid
          } else {
            repeaterarray.showColC_SEFDateError = true; // End date is invalid
            return false;
          }
        }
      }
    }

    //No Start, end and end of follow up present
    //if (repeaterarray.lastDateUnk !== 1) {
    if (startDt === null && endDt != null && endOfFollowUpDt != null) {
      // Check if the last radiotherapy date is within the range
      if (endDt <= endOfFollowUpDt) {
        repeaterarray.showColC_EFDateError = false; //valid
      } else {
        result = false;
        repeaterarray.showColC_EFDateError =
          repeaterarray.lastDate === "" || repeaterarray.lastDate === null
            ? false
            : true; //in-valid
      }
    }
    //}

    //No End, start and end of follow up present
    //if (repeaterarray.firstDateUnk !== 1) {
    if (startDt != null && endDt === null && endOfFollowUpDt != null) {
      // Check if the last radiotherapy date is within the range
      if (startDt <= endOfFollowUpDt) {
        repeaterarray.showColB_SFDateError = false; //valid
      } else {
        result = false;
        repeaterarray.showColB_SFDateError =
          repeaterarray.firstDate === "" || repeaterarray.firstDate === null
            ? false
            : true; //in-valid
      }
    }
    //}

    //No End of follow up, start and end present
    //if (repeaterarray.firstDateUnk !== 1) {
    if (startDt != null && endDt !== null && endOfFollowUpDt === null) {
      // Check if the last radiotherapy date is within the range
      if (startDt <= endDt) {
        repeaterarray.showColB_SEDateError = false; //valid
      } else {
        result = false;
        repeaterarray.showColB_SEDateError =
          repeaterarray.firstDate === "" || repeaterarray.firstDate === null
            ? false
            : true; //in-valid
      }
    }
    //}

    return result;
  }

  function parseDate(dateString) {
    const [month, day, year] = dateString.split("/");
    return new Date(year, month - 1, day);
  }

  function checkValidation(validate) {
    let isValidPromise;
    if (validate) {
      isValidPromise = ValidateForm();
    } else {
      isValidPromise = Promise.resolve(true);
    }

    return isValidPromise;
  }

  const SavePage = async (validate) => {
    try {
      ToggleLoader(true);
      setHardErrList(InitialHardErrList);
      let isValid = validate ? await checkValidation(validate) : true;
      if (isValid) {
        setShowTopErrMsg(false);
        let agentListCombined = [];
        if (
          ndmmTreatmentCohortDetails.show_q25 ||
          ndmmTreatmentCohortDetails.show_q25_tie
        )
          agentListCombined = ndmmTreatmentCohortDetails.q25_agentData;
        else
          agentListCombined = [
            ...ndmmTreatmentCohortDetails.q22_agentData,
            ...ndmmTreatmentCohortDetails.q23_agentData,
            ...ndmmTreatmentCohortDetails.q24_agentData,
          ];

        let ndmmTreatmentModel = {
          patientid: pid,
          cohorttreatmentid: ndmmTreatmentCohortDetails.cohortNdmmId,
          chartDifferent:
            ndmmTreatmentCohortDetails.q21_data == 1
              ? true
              : ndmmTreatmentCohortDetails.q21_data == 2
                ? false
                : null,
          inductionPhase: true,
          consolidatePhase: ndmmTreatmentCohortDetails.q21_1_CT,
          maintenancePhase: ndmmTreatmentCohortDetails.q21_1_MT,
          agentTreatmentLists: agentListCombined,
          nDMMAgentBridgeToDeleteLists:
            ndmmTreatmentCohortDetails.nDMMAgentBridgeToDeleteLists,
          loggedInUserId: GetLoggedInUserID(),
          isValidated: validate,
          IsAdmin: IsAdmin()
        };
        if (pid > 0) {
          const response =
            await NDMMTreatmentCohortService.SaveNDMMCohortTreatmentData(
              ndmmTreatmentModel
            );
          if (response.status !== HTTPResponse.OK) {
            ToggleLoader(false);
            if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();
            } else throw response.error;
          } else {
            await GetNDMMCohortTreatmentData();
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
            let cohortNDMMList = cohortTreatmentModelListt.filter(
              (c) => c.isEligible == true && c.ndmm == false
            );
            // navigatePaths.nextPage =
            //   cohortNDMMList.length > 0
            //     ? "/BispecificsCartTreatmentCohort"
            //     : "/NonCohortDefiningTreatment";
          }

          if (response?.data?.showDateChangeAffectAlert) {
            ShowAlertBox();
        }
        }
        return true;
      } else {
        ToggleLoader(false);
        setShowTopErrMsg(true);
        return false;
      }
    } catch (error) {
      ToggleLoader(false);
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };

      /* <summary>
        date: 27-08-2024
        Name: BV
        description: Show an alert message when other page(end of follow up) could be affected
        <summary>*/
        const ShowAlertBox = async () => {
          const choice = await ShowActionPopUp(
              "Alert", //title
              CommonError_Msgs.DateChangeAlert, //message
              "Ok", //Confirm button text
              true
          );
      };

  const InitialHardErrList = {
    q21Empty: false,
    q23Empty: false,
    q24Empty: false,
  };
  const [hardErrList, setHardErrList] = useState(InitialHardErrList);

  //DN 23/7/24
  const HandleFieldChange = (e) => {
    const { name, id, value } = e.target;

    switch (name) {
      case "q21_data": {
        //Q21
        const q21_Value = Number(value);

        setNDMMTreatmentCohortDetails((prev) => ({
          ...prev,
          [name]: q21_Value,
          show_q21_1: q21_Value === 1 ? true : false,
          show_q25: q21_Value === 2 ? true : false,
          q21_1_CT: false,
          q21_1_MT: false,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q21Empty: false,
          q23Empty: false,
          q24Empty: false,
        }));

        setShowTopErrMsg(false);

        //
        setNDMMTreatmentCohortDetails((prev) => ({
          ...prev,
          q22_agentData: ndmmTreatmentCohortDetails.q22_agentData
            .filter((f) => f.defaultAgent)
            .map((item) => {
              return {
                ...item,
                showColAError: false,
                showColAOthError: false,
                showColAOthDupError: false,
                showColBError: false,
                showColBInvalidDate: false,
                showColB_IdxDateError: false,
                showColB_SFDateError: false,
                showColB_SEDateError: false,
                showColCError: false,
                showColC_SEFDateError: false,
                showColB_SEFDateError: false, 
                showColC_EFDateError: false,
                showColDError: false,
                showColEError: false,
                showColF: false,
                showColFError: false,
                showColFOthError: false,
                agentName: item.defaultAgent === true ? item.agentName : null,
                agentid: item.defaultAgent === true ? item.agentid : null,
                discontinueAgent: null,
                firstDate: null,
                firstDateRadio: null,
                firstDateUnk: null,
                lastDate: null,
                lastDateRadio: null,
                lastDateUnk: null,
                noOfAdmission: null,
                noOfAdmissionRadio: null,
                  noOfAdmissionUnk: null,
                  noOfAdmissionUnkTxt: null,
                  doseAdministrationRadiomg: null,
                  doseAdministrationmg: null,
                  doseAdministrationRadiokg: null,
                  doseAdministrationkg: null,
                  doseAdministrationRadiomgm: null,
                  doseAdministrationmgm: null,
                reasonForDiscontinuingAgents: predefinedReasons.map(
                  (reason) => ({
                    ...reason,
                    isSelected: null,
                    reasonUnk: null, // Ensure isselected is set to null
                  })
                ),
              };
            }),
          q23_agentData: ndmmTreatmentCohortDetails.q23_agentData
            .filter((f) => f.defaultAgent)
            .map((item) => {
              return {
                ...item,
                showColAError: false,
                showColAOthError: false,
                showColAOthDupError: false,
                showColBError: false,
                showColBInvalidDate: false,
                showColB_IdxDateError: false,
                showColB_SFDateError: false,
                showColB_SEDateError: false,
                showColCError: false,
                showColC_SEFDateError: false,
                showColB_SEFDateError: false,
                showColC_EFDateError: false,
                showColDError: false,
                showColEError: false,
                showColF: false,
                showColFError: false,
                showColFOthError: false,
                agentName: item.defaultAgent === true ? item.agentName : null,
                agentid: item.defaultAgent === true ? item.agentid : null,
                discontinueAgent: null,
                firstDate: null,
                firstDateRadio: null,
                firstDateUnk: null,
                lastDate: null,
                lastDateRadio: null,
                lastDateUnk: null,
                noOfAdmission: null,
                noOfAdmissionRadio: null,
                  noOfAdmissionUnk: null,
                  noOfAdmissionUnkTxt: null,
                  doseAdministrationRadiomg: null,
                  doseAdministrationmg: null,
                  doseAdministrationRadiokg: null,
                  doseAdministrationkg: null,
                  doseAdministrationRadiomgm: null,
                  doseAdministrationmgm: null,
                reasonForDiscontinuingAgents: predefinedReasons.map(
                  (reason) => ({
                    ...reason,
                    isSelected: null,
                    reasonUnk: null, // Ensure isselected is set to null
                  })
                ),
              };
            }),
          q24_agentData: ndmmTreatmentCohortDetails.q24_agentData
            .filter((f) => f.defaultAgent)
            .map((item) => {
              return {
                ...item,
                showColAError: false,
                showColAOthError: false,
                showColAOthDupError: false,
                showColBError: false,
                showColBInvalidDate: false,
                showColB_IdxDateError: false,
                showColB_SFDateError: false,
                showColB_SEDateError: false,
                showColCError: false,
                showColC_SEFDateError: false,
                showColB_SEFDateError: false,
                showColC_EFDateError: false,
                showColDError: false,
                showColEError: false,
                showColF: false,
                showColFError: false,
                showColFOthError: false,
                agentName: item.defaultAgent === true ? item.agentName : null,
                agentid: item.defaultAgent === true ? item.agentid : null,
                discontinueAgent: null,
                firstDate: null,
                firstDateRadio: null,
                firstDateUnk: null,
                lastDate: null,
                lastDateRadio: null,
                lastDateUnk: null,
                noOfAdmission: null,
                noOfAdmissionRadio: null,
                  noOfAdmissionUnk: null,
                  noOfAdmissionUnkTxt: null,
                  doseAdministrationRadiomg: null,
                  doseAdministrationmg: null,
                  doseAdministrationRadiokg: null,
                  doseAdministrationkg: null,
                  doseAdministrationRadiomgm: null,
                  doseAdministrationmgm: null,
                reasonForDiscontinuingAgents: predefinedReasons.map(
                  (reason) => ({
                    ...reason,
                    isSelected: null,
                    reasonUnk: null, // Ensure isselected is set to null
                  })
                ),
              };
            }),
          q25_agentData: ndmmTreatmentCohortDetails.q25_agentData
            .filter((f) => f.defaultAgent)
            .map((item) => {
              return {
                ...item,
                showColAError: false,
                showColAOthError: false,
                showColAOthDupError: false,
                showColBError: false,
                showColBInvalidDate: false,
                showColB_IdxDateError: false,
                showColB_SFDateError: false,
                showColB_SEDateError: false,
                showColCError: false,
                showColC_SEFDateError: false,
                showColB_SEFDateError: false,
                showColC_EFDateError: false,
                showColDError: false,
                showColEError: false,
                showColF: false,
                showColFError: false,
                showColFOthError: false,
                agentName: item.defaultAgent === true ? item.agentName : null,
                agentid: item.defaultAgent === true ? item.agentid : null,
                discontinueAgent: null,
                firstDate: null,
                firstDateRadio: null,
                firstDateUnk: null,
                lastDate: null,
                lastDateRadio: null,
                lastDateUnk: null,
                noOfAdmission: null,
                noOfAdmissionRadio: null,
                  noOfAdmissionUnk: null,
                  noOfAdmissionUnkTxt: null,
                  doseAdministrationRadiomg: null,
                  doseAdministrationmg: null,
                  doseAdministrationRadiokg: null,
                  doseAdministrationkg: null,
                  doseAdministrationRadiomgm: null,
                  doseAdministrationmgm: null,
                reasonForDiscontinuingAgents: predefinedReasons.map(
                  (reason) => ({
                    ...reason,
                    isSelected: null,
                    reasonUnk: null, // Ensure isselected is set to null
                  })
                ),
              };
            }),
        }));

        break;
      }
      case "q21_1_CT": {
        //Q21.1
        setNDMMTreatmentCohortDetails((prev) => ({
          ...prev,
          [name]: !prev.q21_1_CT,
          q23_agentData: ndmmTreatmentCohortDetails.q21_1_CT
            ? []
            : ndmmTreatmentCohortDetails.q23_agentData,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q23Empty: false,
        }));

        break;
      }
      case "q21_1_MT": {
        //Q21.1
        setNDMMTreatmentCohortDetails((prev) => ({
          ...prev,
          [name]: !prev.q21_1_MT,
          q24_agentData: ndmmTreatmentCohortDetails.q21_1_MT
            ? []
            : ndmmTreatmentCohortDetails.q24_agentData,
        }));

        setHardErrList((prev) => ({
          ...prev,
          q24Empty: false,
        }));
        break;
      }
      default:
        break;
    }
  };
  const sidebar = document.getElementById("mySidebar");
    const main = document.getElementById("main");

    const toggleNav = () => {
    

    
      if (isSideMenuActive === true  ) { 
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
        setIsSideMenuActive(false)
      } else if( isSideMenuActive===false ){
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
        setIsSideMenuActive(true)
      }
  }

  useEffect(() => {
 const   sidebar = document.getElementById("mySidebar");
  const  main = document.getElementById("main");


    if (isSideMenuActive === true) {
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
      } else {
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
      }
  });

  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div >
          <SideBarMenu activePageId={SideMenuItems.NDMMTreatmentCohort} />
          <>
            <button className={isSideMenuActive ? "openbtn" : "openbtn active"}
              // onclick="toggleNav()" 
              onClick={() => toggleNav()}
            >
              <i
                id="arrowNavigation"
                class="fa fa-arrow-left"
                aria-hidden="true"
              ></i>
            </button>
          </>
          <div className="col-pad-left" id="main">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>1L NDMM TREATMENT COHORT</h4>
              </div>
              {showTopErrMsg && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div className="question-answer">
                    <div className="cohort-header">
                      <span>{ndmmTreatmentCohortDetails.ndmmDisplayName}</span>
                    </div>
                    {ndmmTreatmentCohortDetails.show_q21_q25 && (
                      <div>
                        {/* Q21 */}
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>Q21.</span>
                            <span className="quest-text-pad">
                              Does the patient’s chart differentiate between{" "}
                              <span
                                data-tooltip-id="encounterTip"
                                style={{ color: "blue" }}
                                onClick={() => {
                                  setShowHideHyperlinkModal(true);
                                }}
                              >
                                <u>
                                  induction, consolidation, and maintenance
                                  therapy phases,
                                </u>
                              </span>
                              {showHideHyperlinkModal && (
                                <InfoPopUp
                                  description={hyperlinkTextQ21}
                                  OkClick={() => {
                                    setShowHideHyperlinkModal(false);
                                  }}
                                />
                              )}{" "}
                              or can these phases be inferred by review of the
                              patient’s records (see the suggestive definition
                              in the link above when hovering over), as part of
                              their 1L NDMM treatment with{" "}
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                <b>
                                  {ndmmTreatmentCohortDetails.ndmmDisplayName}
                                </b>
                              </span>{" "}
                              initiated on{" "}
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                <b>
                                  {ndmmTreatmentCohortDetails.ndmmIndexDate}
                                </b>
                              </span>
                              ?
                            </span>
                          </div>
                          <div className=" double-dig-answer">
                            <div className="">
                              <input
                                type="radio"
                                id={`q21_yes`}
                                name={`q21_data`}
                                value={1}
                                checked={
                                  ndmmTreatmentCohortDetails.q21_data === 1
                                }
                                onChange={HandleFieldChange}
                              />
                              <label htmlFor={`q21_yes`}>
                                <span className="radio-text-padding">Yes</span>
                              </label>
                            </div>
                            <div className="">
                              <input
                                type="radio"
                                id={`q21_no`}
                                name={`q21_data`}
                                value={2}
                                checked={
                                  ndmmTreatmentCohortDetails.q21_data === 2
                                }
                                onChange={HandleFieldChange}
                              />
                              <label htmlFor={`q21_no`}>
                                <span className="radio-text-padding">No</span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={hardErrList.q21Empty}
                            message={NDMMTreatmentCohort_Msgs.q21}
                          />
                        </div>

                        {ndmmTreatmentCohortDetails.show_q21_1 && (
                          <div>
                            {/* Q21.1 */}
                            <div className="question-bot-sapce">
                              <div className="question question-weight">
                                <span>Q21.1.</span>
                                <span className="quest-text-pad">
                                  Which of the following phases were identified
                                  for the patient while on treatment with{" "}
                                  <span
                                    style={{
                                      color: PageDesignConstants.fontColor,
                                    }}
                                  >
                                    <b>
                                      {
                                        ndmmTreatmentCohortDetails.ndmmDisplayName
                                      }
                                    </b>
                                  </span>
                                  ?
                                </span>
                              </div>
                              <div className=" double-dig-answer">
                                <div className="">
                                  <input
                                    type="checkbox"
                                    id="q21_1_IT"
                                    name="q21_1_IT"
                                    checked={true}
                                  />
                                  <label htmlFor="q21_1_IT">
                                    <span className="radio-text-padding">
                                      Induction therapy{" "}
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <input
                                    type="checkbox"
                                    id="q21_1_CT"
                                    name="q21_1_CT"
                                    checked={
                                      ndmmTreatmentCohortDetails.q21_1_CT
                                    }
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="q21_1_CT">
                                    <span className="radio-text-padding">
                                      Consolidation therapy
                                    </span>
                                  </label>
                                </div>
                                <div className="">
                                  <input
                                    type="checkbox"
                                    id="q21_1_MT"
                                    name="q21_1_MT"
                                    checked={
                                      ndmmTreatmentCohortDetails.q21_1_MT
                                    }
                                    onChange={HandleFieldChange}
                                  />
                                  <label htmlFor="q21_1_MT">
                                    <span className="radio-text-padding">
                                      Maintenance therapy
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>

                            {/* Q22 */}
                            <div className="question-bot-sapce">
                              <div className="question question-weight">
                                <span>Q22.</span>
                                <span className="quest-text-pad">
                                  Describe the prescribed dosing information for
                                  each agent used as{" "}
                                  <i>
                                    <u>induction therapy</u>
                                  </i>{" "}
                                  as part of the patient’s 1L NDMM treatment
                                  with{" "}
                                  <span
                                    style={{
                                      color: PageDesignConstants.fontColor,
                                    }}
                                  >
                                    <b>
                                      {
                                        ndmmTreatmentCohortDetails.ndmmDisplayName
                                      }
                                    </b>
                                  </span>{" "}
                                  initiated on{" "}
                                  <span
                                    style={{
                                      color: PageDesignConstants.fontColor,
                                    }}
                                  >
                                    <b>
                                      {ndmmTreatmentCohortDetails.ndmmIndexDate}
                                    </b>
                                  </span>
                                  . <br />
                                  <b>
                                    <u>Please note</u>
                                  </b>
                                  <br />
                                  <ul>
                                    <li>
                                      The date of first administration for
                                      one of the agents must match with the
                                      start date of the 1L therapy on{" "}
                                      <span
                                        style={{
                                          color: PageDesignConstants.fontColor,
                                        }}
                                      >
                                        <b>
                                          {
                                            ndmmTreatmentCohortDetails.ndmmIndexDate
                                          }
                                        </b>
                                      </span>
                                      ;
                                    </li>
                                    <li>
                                      The date of first administration of each
                                      agent must be no later than the date of last
                                      administration of the same agent;
                                    </li>
                                    <li>
                                      The
                                      date of last administration must be no later
                                      than the date of the first stem cell
                                      transplant (SCT) received on this line of
                                      treatment (if the patient received
                                      transplant); and
                                    </li>
                                    <li>
                                      None of the dates of
                                      first administration or last administration
                                      should be later than the end of follow up on{" "}
                                      <span
                                        style={{
                                          color: PageDesignConstants.fontColor,
                                        }}
                                      >
                                        <b>
                                          {
                                            ndmmTreatmentCohortDetails.endOfFollowUp
                                          }
                                        </b>
                                      </span>
                                      .
                                    </li>
                                  </ul>
                                                                  <span>Please note that, for patients receiving one cycle of CyBorD, the date of initiation of 1L treatment begins with the first dose of the DR/DRd, DVR/DVRd, VR/VRd, or IsaVR/IsaVRd regimen.</span>
                                </span>
                              </div>
                              <div className=" double-dig-answer">
                                <NDMMAgentData
                                  phaseid={1}
                                  agentList={
                                    ndmmTreatmentCohortDetails.q22_agentList
                                  }
                                  agentData={
                                    ndmmTreatmentCohortDetails.q22_agentData
                                  }
                                  setAgentData={(newData) =>
                                    setNDMMTreatmentCohortDetails(
                                      (prevState) => ({
                                        ...prevState,
                                        q22_agentData: newData,
                                      })
                                    )
                                  }
                                  listToDelete={
                                    ndmmTreatmentCohortDetails.nDMMAgentBridgeToDeleteLists
                                  }
                                />
                              </div>
                            </div>

                            {/* Q23 */}
                            {ndmmTreatmentCohortDetails.q21_1_CT && (
                              <div className="question-bot-sapce">
                                <div className="question question-weight">
                                  <span>Q23.</span>
                                  <span className="quest-text-pad">
                                    Describe the prescribed dosing information
                                    for each agent used as{" "}
                                    <i>
                                      <u>consolidation therapy</u>
                                    </i>{" "}
                                    as part of the patient’s 1L NDMM treatment
                                    with{" "}
                                    <span
                                      style={{
                                        color: PageDesignConstants.fontColor,
                                      }}
                                    >
                                      <b>
                                        {
                                          ndmmTreatmentCohortDetails.ndmmDisplayName
                                        }
                                      </b>
                                    </span>{" "}
                                    initiated on{" "}
                                    <span
                                      style={{
                                        color: PageDesignConstants.fontColor,
                                      }}
                                    >
                                      <b>
                                        {
                                          ndmmTreatmentCohortDetails.ndmmIndexDate
                                        }
                                      </b>
                                                                      </span>
                                                                      .{" "}
                                                                      <br />
                                                                      <b>
                                                                          <u>Please note</u>
                                                                      </b>
                                                                      <br />

                                                                      <ul>
                                                                          <li>
                                                                              The earliest date of first
                                                                              administration for agents used during
                                                                              consolidation therapy must be on or after
                                                                              the latest date of last administration for
                                                                              agents used during induction therapy
                                                                              ;
                                                                          </li>
                                                                          <li>
                                                                              The earliest date of first administration
                                                                              must be on or after the date of the first
                                                                              stem cell transplant (SCT) received on this
                                                                              line of treatment (if the patient received
                                    transplant);
                                                                          </li>
                                                                          <li>
                                                                              The date of first
                                                                              administration of each agent must be no
                                                                              later than the date of last administration
                                                                              of the same agent; and
                                                                          </li>
                                                                          <li>
                                                                              None of the dates
                                                                              of first administration or last
                                                                              administration should be later than the end
                                                                              of follow up{" "}
                                                                              <span
                                                                                  style={{
                                                                                      color: PageDesignConstants.fontColor,
                                                                                  }}
                                                                              >
                                                                                  <b>
                                                                                      {
                                                                                          ndmmTreatmentCohortDetails.endOfFollowUp
                                                                                      }
                                                                                  </b>
                                                                              </span>
                                                                              .
                                                                          </li>
                                                                      </ul>

                                  </span>
                                </div>
                                <div className=" double-dig-answer">
                                  <NDMMAgentData
                                    phaseid={2}
                                    agentList={
                                      ndmmTreatmentCohortDetails.q23_agentList
                                    }
                                    agentData={
                                      ndmmTreatmentCohortDetails.q23_agentData
                                    }
                                    setAgentData={(newData) =>
                                      setNDMMTreatmentCohortDetails(
                                        (prevState) => ({
                                          ...prevState,
                                          q23_agentData: newData,
                                        })
                                      )
                                    }
                                    listToDelete={
                                      ndmmTreatmentCohortDetails.nDMMAgentBridgeToDeleteLists
                                    }
                                  />
                                  {ndmmTreatmentCohortDetails.q23_agentData.length === 0 && (
                                    <ErrorField
                                      show={hardErrList.q23Empty}
                                      message={NDMMTreatmentCohort_Msgs.q23}
                                    />)}
                                </div>
                              </div>
                            )}

                            {/* Q24 */}
                            {ndmmTreatmentCohortDetails.q21_1_MT && (
                              <div className="question-bot-sapce">
                                <div className="question question-weight">
                                  <span>Q24.</span>
                                  <span className="quest-text-pad">
                                    Describe the prescribed dosing information
                                    for each agent used as{" "}
                                    <i>
                                      <u>maintenance therapy</u>
                                    </i>{" "}
                                    as part of the patient’s 1L NDMM treatment
                                    with{" "}
                                    <span
                                      style={{
                                        color: PageDesignConstants.fontColor,
                                      }}
                                    >
                                      <b>
                                        {
                                          ndmmTreatmentCohortDetails.ndmmDisplayName
                                        }
                                      </b>
                                    </span>{" "}
                                    initiated on{" "}
                                    <span
                                      style={{
                                        color: PageDesignConstants.fontColor,
                                      }}
                                    >
                                      <b>
                                        {
                                          ndmmTreatmentCohortDetails.ndmmIndexDate
                                        }
                                      </b>
                                    </span>
                                                                      .{" "}

                                                                      <br />
                                                                      <b>
                                                                          <u>Please note</u>
                                                                      </b>
                                                                      <br />
                                                                      <ul>
                                                                          <li>
                                                                              The earliest date of first
                                                                              administration for agents used during
                                                                              maintenance therapy must be on or after the
                                                                              latest date of last administration for
                                                                              agents used during consolidation therapy and
                                                                              induction therapy;{" "}
                                                                             
                                                                          </li>
                                                                          <li>
                                                                              The earliest date of
                                                                              first administration must be on or after the
                                                                              date of the first stem cell transplant (SCT)
                                                                              received on this line of treatment (if the
                                    patient received transplant);
                                                                          </li>
                                                                          <li>
                                                                              The date
                                                                              of first administration of each agent must
                                                                              be no later than the date of last
                                                                              administration of the same agent; and 
                                                                          </li>
                                                                          <li>
                                                                              None of the dates of first administration or
                                                                              last administration should be later than the
                                                                              end of follow up{" "}
                                                                              <span
                                                                                  style={{
                                                                                      color: PageDesignConstants.fontColor,
                                                                                  }}
                                                                              >
                                                                                  <b>
                                                                                      {
                                                                                          ndmmTreatmentCohortDetails.endOfFollowUp
                                                                                      }
                                                                                  </b>
                                                                              </span>
                                                                              .
                                                                          </li>
                                                                      </ul>




                                    
                                  </span>
                                </div>
                                <div className=" double-dig-answer">
                                  <NDMMAgentData
                                    phaseid={3}
                                    agentList={
                                      ndmmTreatmentCohortDetails.q24_agentList
                                    }
                                    agentData={
                                      ndmmTreatmentCohortDetails.q24_agentData
                                    }
                                    setAgentData={(newData) =>
                                      setNDMMTreatmentCohortDetails(
                                        (prevState) => ({
                                          ...prevState,
                                          q24_agentData: newData,
                                        })
                                      )
                                    }
                                    listToDelete={
                                      ndmmTreatmentCohortDetails.nDMMAgentBridgeToDeleteLists
                                    }
                                  />
                                  {ndmmTreatmentCohortDetails.q24_agentData.length === 0 && (
                                    <ErrorField
                                      show={hardErrList.q24Empty}
                                      message={NDMMTreatmentCohort_Msgs.q24}
                                    />)}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    {/* Q25 */}
                    {(ndmmTreatmentCohortDetails.show_q25 ||
                      ndmmTreatmentCohortDetails.show_q25_tie) && (
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>Q25.</span>
                            <span className="quest-text-pad">
                              Describe the prescribed dosing information for each
                              agent used as part of the patient’s 1L NDMM
                              treatment with{" "}
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                <b>
                                  {ndmmTreatmentCohortDetails.ndmmDisplayName}
                                </b>
                              </span>{" "}
                              initiated on{" "}
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                <b>{ndmmTreatmentCohortDetails.ndmmIndexDate}</b>
                              </span>
                                                      .{" "}

                                                      <br />
                                                      <b>
                                                          <u>Please note</u>
                                                      </b>
                                                      <br />
                                                      <ul>
                                                          <li>
                                                              The date of first administration for one of the
                                                              agents must match with the start date of the 1L
                                                              therapy on{" "}
                                                              <span
                                                                  style={{
                                                                      color: PageDesignConstants.fontColor,
                                                                  }}
                                                              >
                                                                  <b>
                                                                      {
                                                                          ndmmTreatmentCohortDetails.ndmmIndexDate
                                                                      }
                                                                  </b>
                                                              </span>
                                                              ;
                                                          </li>
                                                          <li>
                                                              The
                                                              date of first administration of each agent must be
                                                              no later than the date of last administration of the
                              same agent; and;
                                                          </li>
                                                          
                                                          <li>
                                                              None of the dates of first
                                                              administration or last administration should be
                                                              later than the end of follow up on{" "}
                                                              <span
                                                                  style={{
                                                                      color: PageDesignConstants.fontColor,
                                                                  }}
                                                              >
                                                                  <b>
                                                                      {
                                                                          ndmmTreatmentCohortDetails.endOfFollowUp
                                                                      }
                                                                  </b>
                                                              </span>
                                                              .
                                                          </li>
                                                      </ul>
                                                      <span>Please note that, for patients receiving one cycle of CyBorD, the date of initiation of 1L treatment begins with the first dose of the DR/DRd, DVR/DVRd, VR/VRd, or IsaVR/IsaVRd regimen.</span>
                            </span>
                          </div>
                          <div className=" double-dig-answer">
                            <NDMMAgentData
                              phaseid={4}
                              agentList={ndmmTreatmentCohortDetails.q25_agentList}
                              agentData={ndmmTreatmentCohortDetails.q25_agentData}
                              setAgentData={(newData) =>
                                setNDMMTreatmentCohortDetails((prevState) => ({
                                  ...prevState,
                                  q25_agentData: newData,
                                }))
                              }
                              listToDelete={
                                ndmmTreatmentCohortDetails.nDMMAgentBridgeToDeleteLists
                              }
                            />
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NDMMTreatmentCohort;
