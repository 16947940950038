import React, { useContext, useEffect, useState } from "react";
import PatientDetails from "../../../Components/PatientInformation";
import SaveAndNavigate from "../../../Components/SaveAndNavigate";
import ErrorField from "../../../Components/ErrorField";
import SideBarMenu from "../../../Components/SideBarMenu";
import {
  HTTPResponse,
  PageDesignConstants,
  SideMenuItems,
  ToastMessageType,
  ToastMessages,
} from "../../../Helpers/Enums";
import {
  CommonError_Msgs,
  NDMMSCTCharecteristics_Msgs,
} from "../../../Helpers/HelperText";
import {
  DateInRange,
  //AllowOnlyNumbersOneToNine,
  GetLocalStorageData,
  GetLoggedInUserID,
  GetOrdinal,
  IsAdmin,
  formatDate,
  isNumberOrEmpty,
  isStringEmpty,
} from "../../../Helpers/Utilities";
import { AppContext } from "../../../Contexts/AppContextProvider";
import { AdditionalTreatmentService } from "../../../WebApiServices/AdditionalTreatment.service";
import DateControl from "../../../Components/DateControl";
import DatePickerComponent from "../../../Components/DatePickerComponent";
import dayjs from "dayjs";

const NDMMSCTCharacteristics = () => {
  const { ShowToast, ToggleLoader, HandleSessionTimeout, isSideMenuActive, setIsSideMenuActive } =
    useContext(AppContext);

  const [cohortTreatmentName, setCohortTreatmentName] = useState("");
  const InitialSCTReceivedRow = {
    ndmmSctReceivedBridgeId: 0,
    // Question 35.2 A
    SCTRecievedA: null,
    SCTRecievedADate: "",
    //err
    SCTRecievedAEmpty: false,
    SCTRecievedADateEmpty: false,
    // SCTRecievedADateValid: false,
    SCTRecievedAInvalidDate: false,
    // Question 35.2 B
    SCTRecievedB: null,
    //err
    SCTRecievedBEmpty: false,
    // Question 35.2 C
    SCTRecievedC: null,
    SCTRecievedCNum: null,
    //err
    SCTRecievedCEmpty: false,
    SCTRecievedCNumEmpty: false,
    // Question 35.2 D
    SCTRecievedD: null,
    SCTRecievedDNum: null,
    //err
    SCTRecievedDEmpty: false,
    SCTRecievedDNumEmpty: false,
    // Question 35.2 E
    SCTRecievedE: null,
    SCTRecievedENum: null,
    //err
    SCTRecievedEEmpty: false,
    SCTRecievedENumEmpty: false,
    // Question 35.2 F
    SCTRecievedF: null,
    SCTRecievedFTxt: null,
    //err
    SCTRecievedFEmpty: false,
    SCTRecievedFTxtEmpty: false,
  }
  const [ndmmSCTCharData, setNdmmSCTCharData] = useState({
    NdmmCharId: 0,
    CohortTreatmentId: 0,
    ndmmEndFollowupDate: "",
    InductionEndDate: "",
    consolidationAgentStartDate: "",
    maintainenceStartDate: "",
    endOfFollowupDate: "",

    // Question 34
    ComorbidMedAge: false,
    ComorbidMedComorbidity: false,
    ComorbidMedOrganFunction: false,
    ComorbidMedPatientPreference: false,
    ComorbidMedOther: "",
    ComorbidMedOtherTxt: "",
    ComorbidMedUnk: false,

    // Question 35
    SCTTreatment: null,

    // Question 35.1
    NotRecieveSCT: null,

    // Question 35.2
    SCTReceivedList: []
  });

  const [errorMsg, setErrorMsg] = useState({
    ComorbidMedEmpty: false,
    ComorbidTxtEmpty: false,
    SCTTreatmentEmpty: false,
    NotRecieveSCTEmpty: false,
  });

  const [showTopErrMsg, setShowTopErrMsg] = useState(false);
  const pid = GetLocalStorageData("patientId") ?? 0;
  const storedData = GetLocalStorageData("CohortTreatment");
  const endOfFollowupDate = GetLocalStorageData("EndOfFollowUp");

  let cohortTreatmentModelListt = [];
  if (storedData) {
    cohortTreatmentModelListt = JSON.parse(storedData);
  }

  const TTIsPresent = cohortTreatmentModelListt.some(
    (treatment) =>
      (treatment.cohortTreatmentID === 9 ||
        treatment.cohortTreatmentID === 10) &&
      treatment.isEligible === true
  );

  const CarTIsPresent = cohortTreatmentModelListt.some(
    (treatment) =>
      (treatment.cohortTreatmentID === 13 ||
        treatment.cohortTreatmentID === 14) &&
      treatment.isEligible === true
  );

  const navigatePaths = {
    prevPage: "/NonCohortDefiningTreatment",
    currPage: "/NDMMSCTCharacteristics",
    nextPage: TTIsPresent
      ? "/TTCharacteristics"
      : CarTIsPresent
        ? "/CarTCharacteristics"
        : "/IMWGReporting",
  };

  function isValidNumber(value) {
    // Return false if the value is null
    if (value === null) {
      return false;
    }

    // Convert value to a number
    const number = parseFloat(value);

    // Check if the value is a number and within the range 0 to 20
    return !isNaN(number) && number >= 0 && number <= 20;
  }

  function isValidDecimal(value) {
    // Convert value to a number
    const number = parseFloat(value);

    // Regular expression to match numbers with up to one decimal place
    const decimalRegex = /^\d{1,2}(\.\d)?$|^100(\.0)?$/;

    // Validate that the number is within the range and matches the decimal format
    return (
      !isNaN(number) && number >= 0 && number <= 100 && decimalRegex.test(value)
    );
  }

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "ComorbidMedAge":
      case "ComorbidMedComorbidity":
      case "ComorbidMedOrganFunction":
      case "ComorbidMedPatientPreference":
      case "ComorbidMedOther":
        setNdmmSCTCharData((prev) => ({
          ...prev,
          [name]: !prev[name],
          ...(name === "ComorbidMedOther" && { ComorbidMedOtherTxt: "" }),
          ComorbidMedUnk: false,
        }));

        setErrorMsg((prev) => ({
          ...prev,
          ComorbidMedEmpty: false,
          ComorbidTxtEmpty: false,
        }));
        break;

      case "ComorbidMedOtherTxt": {
        setNdmmSCTCharData((prev) => ({
          ...prev,
          [name]: value,
          ComorbidMedOther: true,
        }));

        setErrorMsg((prev) => ({
          ...prev,
          ComorbidMedEmpty: false,
          ComorbidTxtEmpty: value === "",
        }));

        break;
      }

      case "ComorbidMedUnk": {
        setNdmmSCTCharData((prev) => ({
          ...prev,
          [name]: Number(value),
          ComorbidMedAge: false,
          ComorbidMedComorbidity: false,
          ComorbidMedOrganFunction: false,
          ComorbidMedPatientPreference: false,
          ComorbidMedOther: false,
          ComorbidMedOtherTxt: "",
        }));

        setErrorMsg((prev) => ({
          ...prev,
          ComorbidMedEmpty: false,
        }));

        break;
      }

      case "SCTTreatment": {
        setNdmmSCTCharData((prev) => ({
          ...prev,
          [name]: Number(value),
          SCTTreatment: Number(value), // Changed from sctTreatmentValue to Number(value)
          NotRecieveSCT: Number(value) === 2 ? prev.NotRecieveSCT : null,
          SCTReceivedList: Number(value) === 1 ? [InitialSCTReceivedRow] : []
        }));

        setErrorMsg((prev) => ({
          ...prev,
          SCTTreatmentEmpty: false,
          NotRecieveSCTEmpty: false,
        }));

        break;
      }

      case "NotRecieveSCT": {
        setNdmmSCTCharData((prev) => ({
          ...prev,
          [name]: Number(value),
        }));

        setErrorMsg((prev) => ({
          ...prev,
          NotRecieveSCTEmpty: false,
        }));

        break;
      }
    }
  };
  function parseDate(dateStr) {
    const [month, day, year] = dateStr.split("/");
    return new Date(year, month - 1, day);
  }

  function validateTreatmentDates(
    cohortIndexDateStr,
    sctDateStr,
    inductionEndDateStr,
    consolidationStartDateStr = null,
    maintenanceStartDateStr = null,
    endOfFollowUpDateStr = null
  ) {
    debugger
    const cohortIndexDate = cohortIndexDateStr ? parseDate(cohortIndexDateStr) : null;
    const sctDate = sctDateStr ? parseDate(sctDateStr) : null;
    const inductionEndDate = inductionEndDateStr ? parseDate(inductionEndDateStr) : null;
    const consolidationStartDate = consolidationStartDateStr ? parseDate(consolidationStartDateStr) : null;
    const maintenanceStartDate = maintenanceStartDateStr ? parseDate(maintenanceStartDateStr) : null;
    const endOfFollowUpDate = endOfFollowUpDateStr ? parseDate(endOfFollowUpDateStr) : null;

    // Check date range only for non-null dates
    const dateInRange =
      (!cohortIndexDate || !sctDate || cohortIndexDate <= sctDate) &&
      (!sctDate || !endOfFollowUpDate || sctDate <= endOfFollowUpDate);

    if (dateInRange) {
      // Validate that inductionEndDate ≤ SCT date (if both are known)
      if (inductionEndDate && sctDate && inductionEndDate > sctDate) {
        return false;
      }

      // Validate that SCT date ≤ consolidationStartDate (if both are known)
      if (sctDate && consolidationStartDate && sctDate > consolidationStartDate) {
        return false;
      }

      // Validate that SCT date ≤ maintenanceStartDate (if both are known)
      if (sctDate && maintenanceStartDate && sctDate > maintenanceStartDate) {
        return false;
      }

      // Validate that SCT date ≤ endOfFollowUpDate (if both are known)
      if (sctDate && endOfFollowUpDate && sctDate > endOfFollowUpDate) {
        return false;
      }

      // If all applicable validations pass, return true
      return true;
    }

    return false;
  }



  const HandleDateChange = (ctrlId, date, id) => {
    let updatedArray = ndmmSCTCharData.SCTReceivedList;
    if (ctrlId === "SCTRecievedADate") {
      updatedArray[id].SCTRecievedA = 1;
      updatedArray[id].SCTRecievedADate = date;
      updatedArray[id].SCTRecievedANum = null;//""
      updatedArray[id].SCTRecievedAEmpty = false;
      updatedArray[id].SCTRecievedADateEmpty = false;
      // updatedArray[id].SCTRecievedADateValid = false;
      updatedArray[id].SCTRecievedAInvalidDate = false;
    }
    setNdmmSCTCharData(prev => ({
      ...prev,
      SCTReceivedList: updatedArray,
    }));
  };
  const HandleRepeaterFieldChange = (e) => {
    debugger
    const { name, id, value } = e.target;
    const value_int = Number(value);
    let updatedArray = [...ndmmSCTCharData.SCTReceivedList];

    switch (name) {
      case "SCTRecievedA" + id: {
        updatedArray[id].SCTRecievedA = value_int;
        updatedArray[id].SCTRecievedADate = "";
        updatedArray[id].SCTRecievedAEmpty = false;
        // updatedArray[id].SCTRecievedADateValid = false;
        updatedArray[id].SCTRecievedADateEmpty = false;
        updatedArray[id].SCTRecievedAInvalidDate = false;
        break;
      }
      case "SCTRecievedB" + id: {
        updatedArray[id].SCTRecievedB = value_int;
        updatedArray[id].SCTRecievedBEmpty = false;
        break;
      }
      case "SCTRecievedC" + id: {
        updatedArray[id].SCTRecievedC = value_int;
        updatedArray[id].SCTRecievedCNum = "";
        updatedArray[id].SCTRecievedCEmpty = false;
        updatedArray[id].SCTRecievedCNumEmpty = false;
        break;
      }
      case "SCTRecievedCNum" + id: {
        updatedArray[id].SCTRecievedC = 1;
        updatedArray[id].SCTRecievedCNum = value;
        updatedArray[id].SCTRecievedCEmpty = false;
        updatedArray[id].SCTRecievedCNumEmpty = false;
        break;
      }
      case "SCTRecievedD" + id: {
        updatedArray[id].SCTRecievedD = value_int;
        updatedArray[id].SCTRecievedDNum = "";
        updatedArray[id].SCTRecievedDEmpty = false;
        updatedArray[id].SCTRecievedDNumEmpty = false;
        break;
      }
      case "SCTRecievedDNum" + id: {
        updatedArray[id].SCTRecievedD = 1;
        updatedArray[id].SCTRecievedDNum = value;
        updatedArray[id].SCTRecievedDEmpty = false;
        updatedArray[id].SCTRecievedDNumEmpty = false;
        break;
      }
      case "SCTRecievedE" + id: {
        updatedArray[id].SCTRecievedE = value_int;
        updatedArray[id].SCTRecievedENum = "";
        updatedArray[id].SCTRecievedEEmpty = false;
        updatedArray[id].SCTRecievedENumEmpty = false;
        break;
      }
      case "SCTRecievedENum" + id: {
        updatedArray[id].SCTRecievedE = 1;
        updatedArray[id].SCTRecievedENum = value;
        updatedArray[id].SCTRecievedEEmpty = false;
        updatedArray[id].SCTRecievedENumEmpty = false;
        break;
      }
      case "SCTRecievedF" + id: {
        updatedArray[id].SCTRecievedF = value_int;
        updatedArray[id].SCTRecievedFTxt = "";
        updatedArray[id].SCTRecievedFEmpty = false;
        updatedArray[id].SCTRecievedFTxtEmpty = false;
        break;
      }
      case "SCTRecievedFTxt" + id: {
        updatedArray[id].SCTRecievedF = 6;
        updatedArray[id].SCTRecievedFTxt = value;
        updatedArray[id].SCTRecievedFEmpty = false;
        updatedArray[id].SCTRecievedFTxtEmpty = false;
        break;
      }
    }
    setNdmmSCTCharData(prev => ({
      ...prev,
      SCTReceivedList: updatedArray,
    }));
  }

  const ValidateForm = () => {
    let valid = true;
    let errors = {
      ComorbidMedEmpty: false,
      ComorbidTxtEmpty: false,
      SCTTreatmentEmpty: false,
      NotRecieveSCTEmpty: false,
    };

    if (
      cohortTreatmentModelListt[0].ndmm === true &&
      cohortTreatmentModelListt[0].isEligible === true &&
      (cohortTreatmentName === "DR (TIE)" ||
        cohortTreatmentName === "DRd (TIE)" ||
        cohortTreatmentName === "DVR (TIE)" ||
        cohortTreatmentName === "DVRd (TIE)" ||
        cohortTreatmentName === "VR (TIE)" ||
        cohortTreatmentName === "VRd (TIE)" ||
        cohortTreatmentName === "DKR (TIE)" ||
        cohortTreatmentName === "DKRd (TIE)" ||
        cohortTreatmentName === "IsaVR (TIE)" ||
        cohortTreatmentName === "IsaVRd (TIE)")
    ) {
      if (
        !ndmmSCTCharData.ComorbidMedAge &&
        !ndmmSCTCharData.ComorbidMedComorbidity &&
        !ndmmSCTCharData.ComorbidMedOrganFunction &&
        !ndmmSCTCharData.ComorbidMedPatientPreference &&
        !ndmmSCTCharData.ComorbidMedOther &&
        !ndmmSCTCharData.ComorbidMedUnk
      ) {
        errors.ComorbidMedEmpty = true;
        valid = false;
      }

      if (
        ndmmSCTCharData.ComorbidMedOther &&
        ndmmSCTCharData.ComorbidMedOtherTxt === ""
      ) {
        errors.ComorbidTxtEmpty = true;
        valid = false;
      }
    }

    //35.1
    if (
      cohortTreatmentModelListt[0].ndmm === true &&
      cohortTreatmentModelListt[0].isEligible === true &&
      (cohortTreatmentName === "DR (TE)" ||
        cohortTreatmentName === "DRd (TE)" ||
        cohortTreatmentName === "DVR (TE)" ||
        cohortTreatmentName === "DVRd (TE)" ||
        cohortTreatmentName === "VR (TE)" ||
        cohortTreatmentName === "VRd (TE)" ||
        cohortTreatmentName === "DKR (TE)" ||
        cohortTreatmentName === "DKRd (TE)" ||
        cohortTreatmentName === "IsaVR (TE)" ||
        cohortTreatmentName === "IsaVRd (TE)")
    ) {
      if (ndmmSCTCharData.SCTTreatment === null) {
        errors.SCTTreatmentEmpty = true;
        valid = false;
      }

      if (ndmmSCTCharData.SCTTreatment == 2) {
        if (
          ndmmSCTCharData.NotRecieveSCT === null ||
          ndmmSCTCharData.NotRecieveSCT === 0 ||
          ndmmSCTCharData.NotRecieveSCT === ""
        ) {
          errors.NotRecieveSCTEmpty = true;
          valid = false;
        }
      }
    }
    
    //35.2
    if (ndmmSCTCharData.SCTTreatment == 1) {
      const list = ndmmSCTCharData.SCTReceivedList;
      if (list && list.length > 0) {
        const updatedArray = list.map(a => {
          let SCTRecievedAEmpty = false;
          let SCTRecievedADateEmpty = false;
          // let SCTRecievedADateValid= false;
          let SCTRecievedAInvalidDate = false;
          let SCTRecievedBEmpty = false;
          let SCTRecievedCEmpty = false;
          let SCTRecievedCNumEmpty = false;
          let SCTRecievedDEmpty = false;
          let SCTRecievedDNumEmpty = false;
          let SCTRecievedEEmpty = false;
          let SCTRecievedENumEmpty = false;
          let SCTRecievedFEmpty = false;
          let SCTRecievedFTxtEmpty = false;
          if (
            a.SCTRecievedA === null &&
            a.SCTRecievedADate === ""
          ) {
            SCTRecievedAEmpty = true;
            valid = false;
          }
          if (
            a.SCTRecievedA == 1 &&
            (a.SCTRecievedADate === "" ||
              a.SCTRecievedADate === null)
          ) {
            SCTRecievedADateEmpty = true;
            valid = false;
          }
          // if (
          //   a.SCTRecievedA === 1 &&
          //   (a.SCTRecievedADate !== null ||
          //     a.SCTRecievedADate !== "") && 
          //     DateInRange(a.SCTRecievedADate, dayjs("01-01-1920"), dayjs())
          // ) {
          //   const dateValid = validateTreatmentDates(
          //     cohortTreatmentModelListt[0].cohortIndexDate,
          //     a.SCTRecievedADate,
          //     a.InductionEndDate,
          //     a.consolidationAgentStartDate,
          //     a.maintainenceStartDate,
          //     a.endOfFollowupDate
          //   );

          //   SCTRecievedADateValid = !dateValid;

          //   if (!dateValid) {
          //     valid = false;
          //   }
          // }
          if (a.SCTRecievedADate === "Invalid Date") {
            SCTRecievedAInvalidDate = true;
            valid = false
          }
          else if (a.SCTRecievedADate != "" && a.SCTRecievedADate !== "Invalid Date" && !DateInRange(a.SCTRecievedADate, dayjs("01-01-1920"), dayjs())) {
            SCTRecievedAInvalidDate = true;
            valid = false
          }
          //B
          if (
            a.SCTRecievedB === null ||
            a.SCTRecievedB === 0
          ) {
            SCTRecievedBEmpty = true;
            valid = false;
          }
          //C
          if (
            a.SCTRecievedC === null &&
            (a.SCTRecievedCNum === null ||
              a.SCTRecievedCNum === "")
          ) {
            SCTRecievedCEmpty = true;
            valid = false;
          }
          if (
            (a.SCTRecievedC === 1 ||
              a.SCTRecievedC === true) &&
            (a.SCTRecievedCNum === null ||
              a.SCTRecievedCNum === "")
          ) {
            SCTRecievedCNumEmpty = true;
          }
          if (
            (a.SCTRecievedC === 1 ||
              a.SCTRecievedC === true) &&
            (a.SCTRecievedCNum !== null ||
              a.SCTRecievedCNum !== "")
          ) {
            const validDec = isValidDecimal(a.SCTRecievedCNum);
            SCTRecievedCNumEmpty = !validDec ? true : false;
            if (!validDec) {
              valid = false;
            }
          }
          //D
          if (
            a.SCTRecievedD === null &&
            (a.SCTRecievedDNum === null ||
              a.SCTRecievedDNum === "")
          ) {
            SCTRecievedDEmpty = true;
            valid = false;
          }
          if (
            (a.SCTRecievedD === 1 ||
              a.SCTRecievedD === true) &&
            (a.SCTRecievedDNum === null ||
              a.SCTRecievedDNum === "")
          ) {
            SCTRecievedDEmpty = true;
          }
          if (
            (a.SCTRecievedD === 1 ||
              a.SCTRecievedD === true) &&
            (a.SCTRecievedDNum !== null ||
              a.SCTRecievedDNum !== "")
          ) {
            const validDec = isValidDecimal(a.SCTRecievedDNum);
            SCTRecievedDNumEmpty = !validDec ? true : false;
            if (!validDec) {
              valid = false;
            }
          }
          //E
          if (
            a.SCTRecievedE === null &&
            a.SCTRecievedENum === null
          ) {
            SCTRecievedEEmpty = true;
            valid = false;
          }
          if (
            a.SCTRecievedE == 1 &&
            (a.SCTRecievedENum === null ||
              a.SCTRecievedENum === "")
          ) {
            SCTRecievedENumEmpty = true;
            valid = false;
          }
          if (
            a.SCTRecievedE == 1 &&
            (a.SCTRecievedENum !== null ||
              a.SCTRecievedENum !== "")
          ) {
            const Evalue =
              a.SCTRecievedENum == null ||
                a.SCTRecievedENum == ""
                ? null
                : Number(a.SCTRecievedENum);

            const validValue = isValidNumber(Evalue);
            a.SCTRecievedE = 1;
            SCTRecievedENumEmpty = !validValue;
            if (!validValue) {
              valid = false;
            }
          }
          //F
          if (
            a.SCTRecievedF === null &&
            a.SCTRecievedFTxt === null
          ) {
            SCTRecievedFEmpty = true;
            valid = false;
          }

          if (a.SCTRecievedF === null) {
            SCTRecievedFEmpty = true;
            valid = false;
          } else if (
            a.SCTRecievedF === 6 &&
            !a.SCTRecievedFTxt
          ) {
            SCTRecievedFTxtEmpty = true;
            valid = false;
          }
          return {
            ...a,
            SCTRecievedAEmpty,
            SCTRecievedADateEmpty,
            // SCTRecievedADateValid,
            SCTRecievedAInvalidDate: false,
            SCTRecievedBEmpty,
            SCTRecievedCEmpty,
            SCTRecievedCNumEmpty,
            SCTRecievedDEmpty,
            SCTRecievedDNumEmpty,
            SCTRecievedEEmpty,
            SCTRecievedENumEmpty,
            SCTRecievedFEmpty,
            SCTRecievedFTxtEmpty,
          }
        })
        setNdmmSCTCharData(prev => ({
          ...prev,
          SCTReceivedList: updatedArray
        }))
      }
    }

    setErrorMsg(errors);

    return valid;
  };

  useEffect(() => {
    LoadData();
    GetCohortTreatmentName();
  }, []);

  const GetCohortTreatmentName = () => {
    if (cohortTreatmentModelListt.length > 0) {
      const treatment = cohortTreatmentModelListt[0];

      if (treatment.ndmm === true) {
        if (treatment.cohortTreatmentName === "DKRd (TE or TIE)") {
          if (treatment.cohortTreatmentSCT === 1) {
            setCohortTreatmentName("DKRd (TE)");
          } else if (treatment.cohortTreatmentSCT === 2) {
            setCohortTreatmentName("DKRd (TIE)");
          } else {
            setCohortTreatmentName(treatment.cohortTreatmentName);
          }
        } else {
          // Handle other NDMM treatments
          setCohortTreatmentName(treatment.cohortTreatmentName);
        }
      } else {
        // Handle non-NDMM treatments
        setCohortTreatmentName(treatment.cohortTreatmentName);
      }
    } else {
      setCohortTreatmentName(""); // or some default value
    }
  };

  async function LoadData() {
    try {
      if (pid > 0) {
        ToggleLoader(true);
        const response =
          await AdditionalTreatmentService.LoadNdmmsctcharacteristics(pid);

        ToggleLoader(false);
        if (response?.status === HTTPResponse.OK) {
          const ndmmSCTCharData = response.data;
          if (ndmmSCTCharData) {
            //35.2
            const { receivedSctList } = ndmmSCTCharData;
            let SCTReceivedList = [];
            if (receivedSctList && receivedSctList.length > 0) {
              SCTReceivedList = receivedSctList.map(a => {
                return {
                  ...InitialSCTReceivedRow,
                  //PK
                  ndmmSctReceivedBridgeId: a.ndmmSctReceivedBridgeId,
                  //35.2.A
                  SCTRecievedADate: a.sctTransplantDate,
                  SCTRecievedA: a.sctTransplantDateUnk
                    ? 99
                    : !isStringEmpty(a.sctTransplantDate)
                      ? 1
                      : null,
                  //35.2.B
                  SCTRecievedB: a.sctType,
                  //35.2.C
                  SCTRecievedCNum: a.noOfStemcellAtTransplant,
                  SCTRecievedC: a.noOfStemcellAtTransplantUnk
                    ? 99
                    : isNumberOrEmpty(a.noOfStemcellAtTransplant)
                      ? 1
                      : null,
                  //35.2.D
                  SCTRecievedDNum: a.noOfStemcellTransplanted,
                  SCTRecievedD: a.noOfStemcellTransplantedUnk
                    ? 99
                    : isNumberOrEmpty(a.noOfStemcellTransplanted)
                      ? 1
                      : null,
                  //35.2.E
                  SCTRecievedENum: a.noOfMobilizationattempts,
                  SCTRecievedE: a.noOfMobilizationattemptsUnk
                    ? 99
                    : isNumberOrEmpty(a.noOfMobilizationattempts)
                      ? 1
                      : null,
                  //35.2.F
                  SCTRecievedF: a.typeOfMobilization,
                  SCTRecievedFTxt: a.typeOfMobilizationOthTxt
                }
              })
            }
            //end
            setNdmmSCTCharData((prev) => ({
              ...prev,
              InductionEndDate: ndmmSCTCharData.inductionAgentEndDate,
              ndmmEndFollowupDate: ndmmSCTCharData.ndmmEndOfFollowupDate,
              consolidationAgentStartDate:
                ndmmSCTCharData.consolidationAgentStartDate,
              maintainenceStartDate: ndmmSCTCharData.maintainenceStartDate,
              endOfFollowupDate: ndmmSCTCharData.endOfFollowupDate,

              CohortTreatmentId: cohortTreatmentModelListt[0].cohortTreatmentID,
              NdmmCharId: ndmmSCTCharData.ndmmscttcharacteristicsid,
              ComorbidMedAge: ndmmSCTCharData.transplantineligibilityReasonAge,
              ComorbidMedComorbidity:
                ndmmSCTCharData.transplantineligibilityReasonComorbidity,
              ComorbidMedOrganFunction:
                ndmmSCTCharData.transplantineligibilityReasonOrganfunction,
              ComorbidMedPatientPreference:
                ndmmSCTCharData.transplantineligibilityReasonPatientpreference,
              ComorbidMedOther:
                ndmmSCTCharData.transplantineligibilityReasonOth,
              ComorbidMedOtherTxt:
                ndmmSCTCharData.transplantineligibilityReasonOthTxt,
              ComorbidMedUnk: ndmmSCTCharData.transplantineligibilityReasonUnk,

              //Question 35

              SCTTreatment:
                ndmmSCTCharData.receivedSct === null
                  ? null
                  : ndmmSCTCharData.receivedSct
                    ? 1
                    : 2,

              //Question 35.1
              NotRecieveSCT: ndmmSCTCharData.notreceivedSctReason,
              //35.2
              SCTReceivedList
            }));

            setErrorMsg((prev) => ({
              ...prev,
              ComorbidMedEmpty: false,
              ComorbidTxtEmpty: false,
              SCTTreatmentEmpty: false,
              NotRecieveSCTEmpty: false,
            }));
          }
        } else if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (er) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }

  const SavePage = async (validate) => {
    try {
      ToggleLoader(true);
      let isValid = validate ? ValidateForm() : true;
      if (isValid) {
        setShowTopErrMsg(false);

        const payload = {
          patientId: pid,
          userId: GetLoggedInUserID(),
          isValidated: validate,
          IsAdmin: IsAdmin(),
          Ndmmscttcharacteristicsid: ndmmSCTCharData.NdmmCharId,
          CohortTreatmentId: cohortTreatmentModelListt[0].cohortTreatmentID,

          TransplantineligibilityReasonAge: ndmmSCTCharData.ComorbidMedAge,
          TransplantineligibilityReasonComorbidity:
            ndmmSCTCharData.ComorbidMedComorbidity,
          TransplantineligibilityReasonOrganfunction:
            ndmmSCTCharData.ComorbidMedOrganFunction,
          TransplantineligibilityReasonPatientpreference:
            ndmmSCTCharData.ComorbidMedPatientPreference,
          TransplantineligibilityReasonOth: ndmmSCTCharData.ComorbidMedOther,
          TransplantineligibilityReasonOthTxt:
            ndmmSCTCharData.ComorbidMedOtherTxt,
          TransplantineligibilityReasonUnk: ndmmSCTCharData.ComorbidMedUnk,

          //Question 35
          ReceivedSct: ndmmSCTCharData.SCTTreatment === 1 ? true: ndmmSCTCharData.SCTTreatment === 2 ? false : null,

          //Question 35.1
          NotreceivedSctReason: ndmmSCTCharData.NotRecieveSCT,
          //35.2
          receivedSctList: []
        };
        let { SCTReceivedList } = ndmmSCTCharData;
        if (SCTReceivedList && SCTReceivedList.length > 0) {
          payload.receivedSctList = SCTReceivedList.map(a => ({
            NdmmSctReceivedBridgeId: a.ndmmSctReceivedBridgeId,
            //A
            SctTransplantDate: a.SCTRecievedA === 1 && a.SCTRecievedADate != "Invalid Date" ? a.SCTRecievedADate : null,
            SctTransplantDateUnk: a.SCTRecievedA === 99,
            //B
            SctType: a.SCTRecievedB,
            //C
            NoOfStemcellAtTransplant: a.SCTRecievedCNum,
            NoOfStemcellAtTransplantUnk: a.SCTRecievedC === 99,
            //D
            NoOfStemcellTransplanted: a.SCTRecievedDNum,
            NoOfStemcellTransplantedUnk: a.SCTRecievedD === 99,
            //E
            NoOfMobilizationattempts: a.SCTRecievedENum,
            NoOfMobilizationattemptsUnk: a.SCTRecievedE === 99,
            //F
            TypeOfMobilization: a.SCTRecievedF,
            TypeOfMobilizationOthTxt: a.SCTRecievedFTxt
          }))
        };

        if (pid > 0) {
          let response =
            await AdditionalTreatmentService.SaveNdmmCharacteristics(payload);

          if (response.status !== HTTPResponse.OK) {
            if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();
            } else throw response.error;
          } else {
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
            await LoadData();
          }

          ToggleLoader(false);
        }
        return true;
      } else {
        ToggleLoader(false);
        setShowTopErrMsg(true);
        return false;
      }
    } catch (error) {
      ToggleLoader(false);
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };

  const Q35NdmmDate =
    ndmmSCTCharData.ndmmEndFollowupDate === ""
      ? ndmmSCTCharData.endOfFollowupDate || "NDMM end of follow up"
      : ndmmSCTCharData.ndmmEndFollowupDate;



  const sidebar = document.getElementById("mySidebar");
  const main = document.getElementById("main");

  const toggleNav = () => {


      if (isSideMenuActive === true  ) {
          sidebar.style.width = "0";
          main.style.marginLeft = "0";
          setIsSideMenuActive(false)
      } else if( isSideMenuActive===false ){
          sidebar.style.width = "350px";
          main.style.marginLeft = "350px";
          setIsSideMenuActive(true)
      }
  }

//   useEffect(() => {
//  const   sidebar = document.getElementById("mySidebar");
//   const  main = document.getElementById("main");


//     if (isSideMenuActive === true) {
//         sidebar.style.width = "350px";
//         main.style.marginLeft = "350px";
//       } else {
//         sidebar.style.width = "0";
//         main.style.marginLeft = "0";
//       }
//   });

  //Add/delete row for 35.2
  const DeleteRow = (id) => {
    const newArray = [...ndmmSCTCharData.SCTReceivedList];
    newArray.splice(id, 1);
    setNdmmSCTCharData(prev => ({
      ...prev,
      SCTReceivedList: newArray,
    }));
  }
  const AddRow = () => {
    setNdmmSCTCharData(prev => ({
      ...prev,
      SCTReceivedList: [
        ...prev.SCTReceivedList,
        InitialSCTReceivedRow
      ],
    }));
  }
  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section" id="non-cohort-page">
        <div>
          <SideBarMenu activePageId={SideMenuItems.NDMMSCTCharacteristics} />
          <>
          <button className={ isSideMenuActive? "openbtn": "openbtn active" }
              // onclick="toggleNav()" 
              onClick={() => toggleNav()}
            >
              <i
                id="arrowNavigation"
                class="fa fa-arrow-left"
                aria-hidden="true"
              ></i>
            </button>
          </>
          <div className="col-pad-left" id="main">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>1L NDMM SCT Characteristics</h4>
              </div>
              {showTopErrMsg && (
                <div className="clean-error">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
              <div className="survey-question-section">
                <div className="survey-question-content">
                  {/* Question 34 */}
                  <div className="question-answer">
                    {cohortTreatmentModelListt.length > 0 &&
                      cohortTreatmentModelListt[0].isEligible === true &&
                      cohortTreatmentModelListt[0].ndmm === true &&
                      (cohortTreatmentName === "DR (TIE)" ||
                        cohortTreatmentName === "DRd (TIE)" ||
                        cohortTreatmentName === "DVR (TIE)" ||
                        cohortTreatmentName === "DVRd (TIE)" ||
                        cohortTreatmentName === "VR (TIE)" ||
                        cohortTreatmentName === "VRd (TIE)" ||
                        cohortTreatmentName === "DKR (TIE)" ||
                        cohortTreatmentName === "DKRd (TIE)" ||
                        cohortTreatmentName === "IsaVR (TIE)" ||
                        cohortTreatmentName === "IsaVRd (TIE)") && (
                        <>
                          <div
                            className="question-bot-sapce"
                            style={{ marginBottom: "25px" }}
                          >
                            <div className="question question-weight">
                              <span>Q34.</span>
                              <span className="quest-text-pad">
                                State the reason(s) for transplant ineligibility
                                while on treatment with{" "}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                  }}
                                >
                                  {cohortTreatmentName}
                                </span>{" "}
                                . Select all that apply.
                              </span>
                            </div>

                            <div
                              className="checked-box-padding"
                              style={{ paddingLeft: "35px", paddingTop: "5px" }}
                            >
                              {/* CHECKBOX */}
                              <div className="">
                                <div>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="ComorbidMedAge"
                                      checked={ndmmSCTCharData.ComorbidMedAge}
                                      onChange={handleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Age
                                    </span>
                                  </label>
                                </div>
                              </div>
                              {/* CHECKBOX */}
                              <div className="">
                                <div>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="ComorbidMedComorbidity"
                                      checked={
                                        ndmmSCTCharData.ComorbidMedComorbidity
                                      }
                                      onChange={handleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Comorbidity
                                    </span>
                                  </label>
                                </div>
                              </div>
                              {/* CHECKBOX */}
                              <div className="">
                                <div>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="ComorbidMedOrganFunction"
                                      checked={
                                        ndmmSCTCharData.ComorbidMedOrganFunction
                                      }
                                      onChange={handleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Organ Function
                                    </span>
                                  </label>
                                </div>
                              </div>
                              {/* CHECKBOX */}
                              <div className="">
                                <div>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="ComorbidMedPatientPreference"
                                      checked={
                                        ndmmSCTCharData.ComorbidMedPatientPreference
                                      }
                                      onChange={handleFieldChange}
                                    />
                                    <span className="radio-text-padding">
                                      Patient Preference
                                    </span>
                                  </label>
                                </div>
                              </div>
                              {/* CHECKBOX */}
                              <div className="">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="ComorbidMedOther"
                                      checked={ndmmSCTCharData.ComorbidMedOther}
                                      onChange={handleFieldChange}
                                    />

                                    <span style={{ paddingLeft: "15px" }}>
                                      Other, please state
                                    </span>
                                  </label>
                                </div>

                                <label>
                                  <textarea
                                    style={{
                                      width: "264px",
                                      display: "block",
                                      marginTop: "5px",
                                    }}
                                    className="input-dash"
                                    type="text"
                                    name="ComorbidMedOtherTxt"
                                    value={ndmmSCTCharData.ComorbidMedOtherTxt}
                                    maxLength={300}
                                    onChange={handleFieldChange}
                                  />
                                </label>
                              </div>
                              <div className="">
                                <div
                                  className="option-without-input"
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <label>
                                    <input
                                      type="radio"
                                      name="ComorbidMedUnk"
                                      value={99}
                                      checked={ndmmSCTCharData.ComorbidMedUnk}
                                      onChange={handleFieldChange}
                                    />

                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <ErrorField
                              show={errorMsg.ComorbidMedEmpty}
                              message={NDMMSCTCharecteristics_Msgs.Q34Empty}
                            />

                            <ErrorField
                              show={errorMsg.ComorbidTxtEmpty}
                              message={NDMMSCTCharecteristics_Msgs.Q34Text}
                            />
                          </div>
                        </>
                      )}
                  </div>
                  {/* QUESTION 35 */}
                  {cohortTreatmentModelListt[0].ndmm == true &&
                    cohortTreatmentModelListt[0].isEligible == true &&
                    (cohortTreatmentName === "DR (TE)" ||
                      cohortTreatmentName === "DRd (TE)" ||
                      cohortTreatmentName === "DVR (TE)" ||
                      cohortTreatmentName === "DVRd (TE)" ||
                      cohortTreatmentName === "VR (TE)" ||
                      cohortTreatmentName === "VRd (TE)" ||
                      cohortTreatmentName === "DKR (TE)" ||
                      cohortTreatmentName === "DKRd (TE)" ||
                      cohortTreatmentName === "IsaVR (TE)" ||
                      cohortTreatmentName === "IsaVRd (TE)") && (
                      <div className="question-answer">
                        <div className="question-bot-space">
                          <div className="question question-weight">
                            <span>Q35.</span>
                            <span className="quest-text-pad">
                              Did the patient receive at least one stem cell
                              transplant (SCT) during treatment with &nbsp;
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                {cohortTreatmentName}
                                {/* <sup className="supscript">TM</sup> */}
                              </span>
                              &nbsp; from &nbsp;
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                {cohortTreatmentModelListt[0].cohortIndexDate ||
                                  "NDMM index date "}
                              </span>
                              &nbsp; to &nbsp;
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                {Q35NdmmDate}
                              </span>
                              &nbsp;?
                            </span>
                          </div>
                          <div className="" style={{ paddingLeft: "35px" }}>
                            <div
                              className="option-without-input"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <label
                                className="long-text-option"
                                htmlFor="SCTTreatmentYes"
                              >
                                <input
                                  type="radio"
                                  id="SCTTreatmentYes"
                                  name="SCTTreatment"
                                  value={1}
                                  checked={ndmmSCTCharData.SCTTreatment == 1}
                                  onChange={handleFieldChange}
                                />
                                <span className="radio-text-padding">Yes</span>
                              </label>
                            </div>
                            <div
                              className="option-without-input"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <label
                                className="long-text-option"
                                htmlFor="SCTTreatmentNo"
                              >
                                <input
                                  type="radio"
                                  id="SCTTreatmentNo"
                                  name="SCTTreatment"
                                  value={2}
                                  checked={ndmmSCTCharData.SCTTreatment == 2}
                                  onChange={handleFieldChange}
                                />
                                <span className="radio-text-padding">No</span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={errorMsg.SCTTreatmentEmpty}
                            message={NDMMSCTCharecteristics_Msgs.Q35Empty}
                          />
                        </div>

                        {/* Question 35.1 */}
                        {ndmmSCTCharData.SCTTreatment == 2 && (
                          <div className="question-bot-space">
                            <div className="sub-question">
                              <span className="sub-question-quest">Q35.1.</span>
                              <span
                                className="quest-text-pad"
                                style={{ fontWeight: "500" }}
                              >
                                Reason for not receiving SCT while on treatment
                                with{" "}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  {cohortTreatmentName || "NDMM"}
                                </span>
                              </span>
                              <div className="">
                                <div
                                  className="option-without-input"
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    id="NotRecieveSCTDeclined"
                                    name="NotRecieveSCT"
                                    value={1}
                                    checked={ndmmSCTCharData.NotRecieveSCT == 1}
                                    onChange={handleFieldChange}
                                  />
                                  <label htmlFor="NotRecieveSCTDeclined">
                                    <span className="radio-text-padding">
                                      Declined
                                    </span>
                                  </label>
                                </div>
                                <div
                                  className="option-without-input"
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    id="NotRecieveSCTDeffered"
                                    name="NotRecieveSCT"
                                    value={2}
                                    checked={ndmmSCTCharData.NotRecieveSCT == 2}
                                    onChange={handleFieldChange}
                                  />
                                  <label htmlFor="NotRecieveSCTDeffered">
                                    <span className="radio-text-padding">
                                      Deferred
                                    </span>
                                  </label>
                                </div>
                                <div
                                  className="option-without-input"
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <input
                                    type="radio"
                                    id="NotRecieveSCTOther"
                                    name="NotRecieveSCT"
                                    value={99}
                                    checked={
                                      ndmmSCTCharData.NotRecieveSCT == 99
                                    }
                                    onChange={handleFieldChange}
                                  />
                                  <label htmlFor="NotRecieveSCTOther">
                                    <span className="radio-text-padding">
                                      Other/Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={errorMsg.NotRecieveSCTEmpty}
                                message={NDMMSCTCharecteristics_Msgs.Q35Empty}
                              />
                            </div>
                          </div>
                        )}

                        {ndmmSCTCharData.SCTTreatment == 1 && (
                          <>
                            <div className="question-bot-sapce">
                              <div className="question question-weight">
                                <span>Q35.2.</span>
                                <span className="quest-text-pad">
                                  Provide information for&nbsp;
                                  <u>all the SCTs received</u>&nbsp; during
                                  treatment with &nbsp;
                                  <span
                                    style={{
                                      color: PageDesignConstants.fontColor,
                                    }}
                                  >
                                    {
                                      cohortTreatmentModelListt[0]
                                        .cohortTreatmentName
                                    }
                                    {/* <sup className="supscript">TM</sup> */}
                                  </span>
                                  &nbsp; from &nbsp;
                                  <span
                                    style={{
                                      color: PageDesignConstants.fontColor,
                                    }}
                                  >
                                    {cohortTreatmentModelListt[0]
                                      .cohortIndexDate || "NDMM index date"}
                                  </span>
                                  &nbsp; to &nbsp;
                                  <span
                                    style={{
                                      color: PageDesignConstants.fontColor,
                                    }}
                                  >
                                    {Q35NdmmDate}
                                  </span>
                                  . If more than one SCT was received, please add additional rows for each transplant.
                                </span>
                              </div>
                              <div className="dependent-section">
                                {
                                  ndmmSCTCharData.SCTReceivedList.map((row, idx) => (
                                    <div className="loop-section-ul" key={idx}>
                                      <div className="loop-head">
                                        <span className="question-weight">{GetOrdinal(idx + 1)} Episode</span>
                                        {idx != 0 && <div className="delete-btn-div">
                                          <img
                                            width="16px"
                                            src="../Assets/images/Icon-material-delete.png"
                                            alt=""
                                            onClick={() => DeleteRow(idx)}
                                          />
                                        </div>}
                                      </div>
                                      <div className="question-bot-sapce sub-question">

                                      <div className="" style={{ overflowX: 'auto' }}>
                                          <table
                                            style={{
                                              border: "1px solid black",
                                              borderCollapse: "collapse",
                                              width: "100%",
                                            }}
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Date of SCT transplant <br />
                                                  <span
                                                    style={{
                                                      color:
                                                        PageDesignConstants.fontColor,
                                                    }}
                                                  ></span>
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Type of SCT <br />
                                                  <span
                                                    style={{
                                                      color:
                                                        PageDesignConstants.fontColor,
                                                    }}
                                                  ></span>
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Stem cell yield at transplant <br />
                                                  <span
                                                    style={{
                                                      color:
                                                        PageDesignConstants.fontColor,
                                                    }}
                                                  ></span>
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Stem cells transplanted <br />
                                                  <span
                                                    style={{
                                                      color:
                                                        PageDesignConstants.fontColor,
                                                    }}
                                                  ></span>
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Mobilization attempts at transplant{" "}
                                                  <br />
                                                  <span
                                                    style={{
                                                      color:
                                                        PageDesignConstants.fontColor,
                                                    }}
                                                  ></span>
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Types of mobilization regimens <br />
                                                  <span
                                                    style={{
                                                      color:
                                                        PageDesignConstants.fontColor,
                                                    }}
                                                  ></span>
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <span
                                                  // className="sub-question-quest"
                                                  >
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                      }}
                                                    >
                                                      <input
                                                        type="radio"
                                                        id={idx}
                                                        name={"SCTRecievedA" + idx}
                                                        value={1}
                                                        checked={row.SCTRecievedA === 1}
                                                        onChange={HandleRepeaterFieldChange}
                                                      />
                                                      <span
                                                        className="date-field-padding"
                                                      >
                                                        {/* <DateControl
                                                        ctrlId="SCTRecievedADate"
                                                        HandleDateChange={
                                                          HandleDateChange
                                                        }
                                                        date={
                                                          ndmmSCTCharData.SCTRecievedADate
                                                        }
                                                      /> */}

                                                        <DatePickerComponent
                                                          ctrlId="SCTRecievedADate"//+ idx
                                                          // selectedDate={selectedDate}
                                                          // onDateChange={(newDate) => setSelectedDate(newDate)}
                                                          HandleDateChange={HandleDateChange}
                                                          //optionId={1}
                                                          minDate={dayjs("01-01-1920")}
                                                          maxDate={dayjs()}
                                                          locale="en" // for dd/mm/yyyy use "en-gb"
                                                          date={
                                                            row.SCTRecievedADate
                                                          }
                                                          optionId={idx}
                                                        />
                                                      </span>
                                                    </div>
                                                  </span>
                                                  <div
                                                    className="option-without-input"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="long-text-option">
                                                      <input
                                                        type="radio"
                                                        id={idx}
                                                        name={"SCTRecievedA" + idx}
                                                        value={99}
                                                        checked={row.SCTRecievedA === 99}
                                                        onChange={HandleRepeaterFieldChange}
                                                      />
                                                      <span className="radio-text-padding">
                                                        Date of transplant unknown
                                                      </span>
                                                    </label>
                                                  </div>

                                                  <ErrorField
                                                    show={row.SCTRecievedAEmpty}
                                                    message={
                                                      NDMMSCTCharecteristics_Msgs.Q35Empty
                                                    }
                                                  />
                                                  <ErrorField
                                                    show={row.SCTRecievedAInvalidDate}
                                                    message={
                                                      CommonError_Msgs.InvalidDate
                                                    }
                                                  />
                                                  <ErrorField
                                                    show={row.SCTRecievedADateEmpty}
                                                    message={
                                                      NDMMSCTCharecteristics_Msgs.Q35a
                                                    }
                                                  />
                                                  {/* <ErrorField
                                                  show={row.SCTRecievedADateValid}
                                                  message={
                                                    NDMMSCTCharecteristics_Msgs.Q35aValid
                                                  }
                                                /> */}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div className="">
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={"SCTRecievedB" + idx}
                                                          value={1}
                                                          checked={row.SCTRecievedB === 1}
                                                          onChange={HandleRepeaterFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                          Autologous
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={"SCTRecievedB" + idx}
                                                          value={2}
                                                          checked={row.SCTRecievedB === 2}
                                                          onChange={HandleRepeaterFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                          Allogeneic
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={"SCTRecievedB" + idx}
                                                          value={99}
                                                          checked={row.SCTRecievedB === 99}
                                                          onChange={HandleRepeaterFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                          Unknown
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <ErrorField
                                                    style={{ padding: "0px" }}
                                                    show={row.SCTRecievedBEmpty}
                                                    message={
                                                      NDMMSCTCharecteristics_Msgs.Q35Empty
                                                    }
                                                  />
                                                </td>

                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div
                                                    className=""
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="long-text-option"
                                                      style={{ marginTop: "6px" }}
                                                    >
                                                      <input
                                                        type="radio"
                                                        id={idx}
                                                        name={"SCTRecievedC" + idx}
                                                        value={1}
                                                        checked={row.SCTRecievedC === 1}
                                                        onChange={HandleRepeaterFieldChange}
                                                      />
                                                      <span className="radio-text-padding">
                                                        <input
                                                          className="input-dash"
                                                          id={idx}
                                                          name={"SCTRecievedCNum" + idx}
                                                          type="text"
                                                          value={
                                                            row.SCTRecievedCNum
                                                          }
                                                          onKeyDown={(e) => {
                                                            // Allow backspace, arrow keys, and delete
                                                            if (
                                                              [
                                                                "Backspace",
                                                                "ArrowLeft",
                                                                "ArrowRight",
                                                                "Delete",
                                                                "Tab",
                                                              ].includes(e.key)
                                                            ) {
                                                              return;
                                                            }

                                                            // Prevent non-numeric characters, except '.'
                                                            if (!/[0-9.]/.test(e.key)) {
                                                              e.preventDefault();
                                                              return;
                                                            }

                                                            const value = e.target.value;
                                                            const selectionStart =
                                                              e.target.selectionStart;
                                                            const newValue =
                                                              value.slice(
                                                                0,
                                                                selectionStart
                                                              ) +
                                                              e.key +
                                                              value.slice(
                                                                e.target.selectionEnd
                                                              );

                                                            // Prevent multiple '.'
                                                            if (
                                                              e.key === "." &&
                                                              value.includes(".")
                                                            ) {
                                                              e.preventDefault();
                                                              return;
                                                            }

                                                            // Prevent '.' as the first character
                                                            if (
                                                              e.key === "." &&
                                                              value === ""
                                                            ) {
                                                              e.preventDefault();
                                                              return;
                                                            }

                                                            // Allow only one digit after decimal point
                                                            if (
                                                              newValue.includes(".") &&
                                                              newValue.split(".")[1]
                                                                .length > 1
                                                            ) {
                                                              e.preventDefault();
                                                              return;
                                                            }
                                                          }}
                                                          onChange={HandleRepeaterFieldChange}
                                                        />
                                                        <br />{" "}X 10<sup>6</sup> &nbsp;stem
                                                        cells/kg
                                                      </span>
                                                    </label>
                                                  </div>

                                                  <div className="">
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={"SCTRecievedC" + idx}
                                                          value={99}
                                                          checked={row.SCTRecievedC === 99}
                                                          onChange={HandleRepeaterFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                          Unknown
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <ErrorField
                                                    show={row.SCTRecievedCEmpty}
                                                    message={
                                                      NDMMSCTCharecteristics_Msgs.Q35Empty
                                                    }
                                                  />
                                                  <ErrorField
                                                    show={row.SCTRecievedCNumEmpty}
                                                    message={
                                                      NDMMSCTCharecteristics_Msgs.Q35c
                                                    }
                                                  />
                                                </td>

                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div
                                                    className=""
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="long-text-option"
                                                      style={{ marginTop: "6px" }}
                                                    >
                                                      <input
                                                        type="radio"
                                                        id={idx}
                                                        name={"SCTRecievedD" + idx}
                                                        value={1}
                                                        checked={row.SCTRecievedD === 1}
                                                        onChange={HandleRepeaterFieldChange}
                                                      />
                                                      <span className="radio-text-padding">
                                                        <input
                                                          className="input-dash"
                                                          id={idx}
                                                          name={"SCTRecievedDNum" + idx}
                                                          type="text"
                                                          value={
                                                            row.SCTRecievedDNum
                                                          }
                                                          onKeyDown={(e) => {
                                                            // Allow backspace, arrow keys, and delete
                                                            if (
                                                              [
                                                                "Backspace",
                                                                "ArrowLeft",
                                                                "ArrowRight",
                                                                "Delete",
                                                                "Tab",
                                                              ].includes(e.key)
                                                            ) {
                                                              return;
                                                            }

                                                            // Prevent non-numeric characters, except '.'
                                                            if (!/[0-9.]/.test(e.key)) {
                                                              e.preventDefault();
                                                              return;
                                                            }

                                                            const value = e.target.value;
                                                            const selectionStart =
                                                              e.target.selectionStart;
                                                            const newValue =
                                                              value.slice(
                                                                0,
                                                                selectionStart
                                                              ) +
                                                              e.key +
                                                              value.slice(
                                                                e.target.selectionEnd
                                                              );

                                                            // Prevent multiple '.'
                                                            if (
                                                              e.key === "." &&
                                                              value.includes(".")
                                                            ) {
                                                              e.preventDefault();
                                                              return;
                                                            }

                                                            // Prevent '.' as the first character
                                                            if (
                                                              e.key === "." &&
                                                              value === ""
                                                            ) {
                                                              e.preventDefault();
                                                              return;
                                                            }

                                                            // Allow only one digit after decimal point
                                                            if (
                                                              newValue.includes(".") &&
                                                              newValue.split(".")[1]
                                                                .length > 1
                                                            ) {
                                                              e.preventDefault();
                                                              return;
                                                            }
                                                          }}
                                                          onChange={HandleRepeaterFieldChange}
                                                        />
                                                        <br />{" "}X 10<sup>6</sup> &nbsp;stem
                                                        cells/kg
                                                      </span>
                                                    </label>
                                                  </div>

                                                  <div className="">
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={"SCTRecievedD" + idx}
                                                          value={99}
                                                          checked={row.SCTRecievedD === 99}
                                                          onChange={HandleRepeaterFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                          Unknown
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <ErrorField
                                                    show={row.SCTRecievedDEmpty}
                                                    message={
                                                      NDMMSCTCharecteristics_Msgs.Q35Empty
                                                    }
                                                  />
                                                  <ErrorField
                                                    show={row.SCTRecievedDNumEmpty}
                                                    message={
                                                      NDMMSCTCharecteristics_Msgs.Q35d
                                                    }
                                                  />
                                                </td>

                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div
                                                    className=""
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="long-text-option"
                                                      style={{ marginTop: "6px" }}
                                                    >
                                                      <input
                                                        type="radio"
                                                        id={idx}
                                                        name={"SCTRecievedE" + idx}
                                                        value={1}
                                                        checked={row.SCTRecievedE === 1}
                                                        onChange={HandleRepeaterFieldChange}
                                                      />
                                                      <span className="radio-text-padding">
                                                        <input
                                                          className="input-dash"
                                                          id={idx}
                                                          name={"SCTRecievedENum" + idx}
                                                          type="text"
                                                          value={
                                                            row.SCTRecievedENum
                                                          }
                                                          onKeyDown={(e) => {
                                                            // Allow backspace, arrow keys, delete, and tab
                                                            if (
                                                              [
                                                                "Backspace",
                                                                "ArrowLeft",
                                                                "ArrowRight",
                                                                "Delete",
                                                                "Tab",
                                                              ].includes(e.key)
                                                            ) {
                                                              return;
                                                            }

                                                            // Prevent non-numeric characters
                                                            if (!/[0-9]/.test(e.key)) {
                                                              e.preventDefault();
                                                              return;
                                                            }

                                                            const value = e.target.value;
                                                            const selectionStart =
                                                              e.target.selectionStart;
                                                            const newValue =
                                                              value.slice(
                                                                0,
                                                                selectionStart
                                                              ) +
                                                              e.key +
                                                              value.slice(
                                                                e.target.selectionEnd
                                                              );

                                                            // Prevent leading zeros only if more than one digit is present
                                                            if (
                                                              newValue.length > 1 &&
                                                              newValue[0] === "0"
                                                            ) {
                                                              e.preventDefault();
                                                              return;
                                                            }
                                                          }}
                                                          onChange={HandleRepeaterFieldChange}
                                                        />
                                                        attempts
                                                      </span>
                                                    </label>
                                                  </div>

                                                  <div className="">
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={"SCTRecievedE" + idx}
                                                          value={99}
                                                          checked={row.SCTRecievedE === 99}
                                                          onChange={HandleRepeaterFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                          Unknown
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <ErrorField
                                                    show={row.SCTRecievedEEmpty}
                                                    message={
                                                      NDMMSCTCharecteristics_Msgs.Q35Empty
                                                    }
                                                  />
                                                  <ErrorField
                                                    show={row.SCTRecievedENumEmpty}
                                                    message={
                                                      NDMMSCTCharecteristics_Msgs.Q35e
                                                    }
                                                  />
                                                </td>

                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  <div className="">
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={"SCTRecievedF" + idx}
                                                          value={1}
                                                          checked={row.SCTRecievedF === 1}
                                                          onChange={HandleRepeaterFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                          G-CSF only
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <div className="">
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={"SCTRecievedF" + idx}
                                                          value={2}
                                                          checked={row.SCTRecievedF === 2}
                                                          onChange={HandleRepeaterFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                          G-CSF + upfront plerixafor
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <div className="">
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={"SCTRecievedF" + idx}
                                                          value={3}
                                                          checked={row.SCTRecievedF === 3}
                                                          onChange={HandleRepeaterFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                          G-CSF + plerixafor used when
                                                          deemed necessary
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <div className="">
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={"SCTRecievedF" + idx}
                                                          value={4}
                                                          checked={row.SCTRecievedF === 4}
                                                          onChange={HandleRepeaterFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                          Blood CD34+ count after G-CSF
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <div className="">
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={"SCTRecievedF" + idx}
                                                          value={5}
                                                          checked={row.SCTRecievedF === 5}
                                                          onChange={HandleRepeaterFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                          Chemomobilization
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label className="long-text-option">
                                                      <input
                                                        type="radio"
                                                        id={idx}
                                                        name={"SCTRecievedF" + idx}
                                                        value={6}
                                                        checked={row.SCTRecievedF === 6}
                                                        onChange={HandleRepeaterFieldChange}
                                                      />
                                                      <span
                                                        className="radio-text-padding"
                                                      >
                                                        Other, please state{" "}
                                                        <textarea
                                                          style={{
                                                            width: "264px",
                                                            display: "flex",
                                                            marginTop: "5px",
                                                          }}
                                                          className="input-dash"
                                                          id={idx}
                                                          name={"SCTRecievedFTxt" + idx}
                                                          type="text"
                                                          value={
                                                            row.SCTRecievedFTxt
                                                          }
                                                          maxLength={300}
                                                          onChange={HandleRepeaterFieldChange}
                                                        />
                                                      </span>
                                                    </label>
                                                  </div>
                                                  <div className="">
                                                    <div
                                                      className="option-without-input"
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "flex-start",
                                                      }}
                                                    >
                                                      <label className="long-text-option">
                                                        <input
                                                          type="radio"
                                                          id={idx}
                                                          name={"SCTRecievedF" + idx}
                                                          value={99}
                                                          checked={row.SCTRecievedF === 99}
                                                          onChange={HandleRepeaterFieldChange}
                                                        />
                                                        <span className="radio-text-padding">
                                                          Unknown
                                                        </span>
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <ErrorField
                                                    show={row.SCTRecievedFEmpty}
                                                    message={
                                                      NDMMSCTCharecteristics_Msgs.Q35Empty
                                                    }
                                                  />
                                                  <ErrorField
                                                    show={row.SCTRecievedFTxtEmpty}
                                                    message={
                                                      NDMMSCTCharecteristics_Msgs.Q35f
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                {ndmmSCTCharData.SCTReceivedList.length < 5 &&
                                  <div className="sub-question-bot-sapce-ul mt-2">
                                    <div className="answer-list-text" onClick={AddRow}>
                                      <a>
                                        <img width="18px" src="../Assets/images/plus.png" alt="" />
                                      </a>
                                      <a className="radio-text-padding">
                                        Click here to add another row
                                      </a>
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NDMMSCTCharacteristics;
