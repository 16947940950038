import { IsAdmin } from "../Helpers/Utilities";
import WebServiceWrapper from "./WebServiceWrapper";

export const EligibilityCriteriaService = {
  SaveGeneralInformation,
  SaveNDMMCriteria,
  SaveRRMMCriteria,
  SaveExclusionCriteria,
  LoadGeneralInformation,
  LoadNDMMCriteria,
  LoadRRMMCriteria,
    LoadExclusionCriteria,
    LoadAdditionalCohortTreatment,
    SaveAdditionalCohortTreatment
};

function SaveGeneralInformation(payload) {

  return WebServiceWrapper.Post("/eligibilityCriteria/SaveGeneralInformation", payload);
}
function SaveNDMMCriteria(payload) {

  return WebServiceWrapper.Post("/eligibilityCriteria/SaveNDMMCriteria", payload);
}
function SaveRRMMCriteria(payload) {

  return WebServiceWrapper.Post("/eligibilityCriteria/SaveRRMMCriteria", payload);
}
function SaveExclusionCriteria(payload) {
  
  return WebServiceWrapper.Post("/eligibilityCriteria/SaveExclusionCriteria", payload);
}
function LoadGeneralInformation(pid) {
  return WebServiceWrapper.Get(`/eligibilityCriteria/LoadGeneralInformation?pid=${pid}&isAdmin=${IsAdmin()}`);
}
function LoadNDMMCriteria(pid) {
  return WebServiceWrapper.Get(`/eligibilityCriteria/LoadNDMMCriteria?pid=${pid}&isAdmin=${IsAdmin()}`);
}
function LoadRRMMCriteria(pid) {
  return WebServiceWrapper.Get(`/eligibilityCriteria/LoadRRMMCriteria?pid=${pid}&isAdmin=${IsAdmin()}`);
}
function LoadExclusionCriteria(pid) {
    return WebServiceWrapper.Get(`/eligibilityCriteria/LoadExclusionCriteria?pid=${pid}&isAdmin=${IsAdmin()}`);
}
function LoadAdditionalCohortTreatment(pid) {
    return WebServiceWrapper.Get(`/eligibilityCriteria/LoadAdditionalCohortTreatment?pid=${pid}&isAdmin=${IsAdmin()}`);
}
function SaveAdditionalCohortTreatment(payload) {
   
    return WebServiceWrapper.Post("/eligibilityCriteria/SaveAdditionalCohortTreatment", payload);
}
