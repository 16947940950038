import { useContext, useEffect, useState } from "react";
import ErrorField from "../../Components/ErrorField";
import PatientDetails from "../../Components/PatientInformation";
import {
  Center_Msgs,
  CenterInfo_Msgs,
  CommonError_Msgs,
} from "../../Helpers/HelperText";
import {
  CenterMenuItems,
  HTTPResponse,
  PageDesignConstants,
  Role,
  ToastMessages,
  ToastMessageType,
} from "../../Helpers/Enums";
import { Dropdown } from "react-bootstrap";
import CenterMenu from "../../Components/CenterMenu";
import { AppContext } from "../../Contexts/AppContextProvider";
import { CenterService } from "../../WebApiServices/Center.service";
import {
  AddLocalStorageData,
  AllowOnlyIntegers,
  GetLocalStorageData,
  GetLoggedInUserID,
  isStringEmpty,
  ValueInRange,
} from "../../Helpers/Utilities";
import { useNavigate } from "react-router-dom";
import { PatientService } from "../../WebApiServices/Patient.service";

const ChartSummary = () => {
  const [CohortTreatment, setCohortTreatment] = useState([]);

  const [roundId, setroundId] = useState();

  const {
    ShowToast,
    ToggleLoader,
    ShowActionPopUp,
    HandleSessionTimeout,
    isSideMenuActive,
    setIsSideMenuActive,
  } = useContext(AppContext);
  
  const pid = GetLocalStorageData("patientId") ?? 0;

  let patientData = GetLocalStorageData("patient");
  patientData = patientData && JSON.parse(patientData);
  const uniqueEncryptedPatientId = patientData?.uniqueEncryptedPatientId ?? 0;
  const rid = GetLocalStorageData("roundId") ?? patientData?.roundId ?? 0;
  const centerId = patientData?.ptCenterId ?? 0;
  const abstractorId = patientData?.abstractorId ?? 0;
  const surveyDate = patientData?.surveyDate ?? "";

  const centerRoundId = patientData?.centerRoundId ?? false;
  const [centers, setCenters] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState(""); // Holds the name of the selected center
  const [selectedCenterId, setSelectedCenterId] = useState(null);
  const [selectedRoundId, setSelectedRoundId] = useState(null);
  const [selectedRound, setSelectedRound] = useState("");

  const userId = GetLoggedInUserID("userId") ?? 0;

    async function LoadRoundData(centerId) {
      try {
        const response = await CenterService.GetAllRounds(centerId); //,pid
        if (response?.status === HTTPResponse.OK) {
          const { data } = response;
          setRounds(data)
        }
      } catch (er) {
        ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
      }
    }

    async function LoadRoundAssociateddData() {
      try {
          const response = await CenterService.GetRoundData(rid, centerRoundId, pid);//,pid
          if (response?.status === HTTPResponse.OK) {
              const { data } = response;
              setCohortTreatment(data.centerTreatmentRoundList);
              AddLocalStorageData("ChartSelection", JSON.stringify(data.centerTreatmentRoundList))
              setroundId(rid);
      
          }
      } catch (er) {
          ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
      }
  }



  useEffect(() => {
   
    async function FetchData() {
      ToggleLoader(true);
      await LoadData();
      await LoadRoundAssociateddData()
      ToggleLoader(false);
    }
    FetchData();
  }, []);

  async function LoadData() {
    try {
      const response = await CenterService.GetAllCenters(); //,pid
      if (response?.status === HTTPResponse.OK) {
        const { data } = response;
        console.log("center response",response);
        const userRole = data[0]?.roleId;
        setCenters(data);
      }
    } catch (er) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }

  const handleCenterSelection = (center) => {
    setSelectedCenter(center.name); // Store the center name
    setSelectedCenterId(center.centerId); // Store the centerId
  
    // Fetch rounds for the selected center
    LoadRoundData(center.centerId);
  };

  const handleRoundSelection = (round) => {
    setSelectedRound(round.roundName); // Store the selected round name
    setSelectedRoundId(round.roundId); // Store the selected roundId
  };


  const HandleReset= ()=>{
    setSelectedCenter("");
    setSelectedRound("")
  }
  return (
    <>
      <section className="survey-patient-details-section ">
        <PatientDetails />
      </section>

      <section className="main-survey-section custom-chart">
        <div className="container">
          <div className="survey-section-content">
            <div className="answer-pad-left double-dig-answer">



              <div className="dropdown-fbox" >
                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    className="custom-dropdown-button"
                    style={{ width: "170px", height: "30px" }}
                  >
                    {selectedCenter ? selectedCenter : "Select Center"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      maxHeight: "150px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      zIndex: 9
                    }}
                  >
                    {centers.map((item) => (
                      <Dropdown.Item
                        as="button"
                        key={item.centerId}
                        value={item.name}
                        className={`custom-dropdown-item ${
                          item.name === selectedCenter
                            ? "custom-dropdown-item-selected"
                            : ""
                        }`} // Highlight the selected item
                        //onClick={() => setSelectedCenter(item.name)} 
                        onClick= {() => handleCenterSelection(item)} 
                      >
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
            




                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    className="custom-dropdown-button"
                    style={{ width: "170px", height: "30px" }}
                  >
                   {selectedRound ? selectedRound : "Select Round"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      maxHeight: "150px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      zIndex: 9
                    }}
                  >
                    {rounds.item1?.map((item) => (
                      <Dropdown.Item
                        as="button"
                        key={item.centerRoundId}
                        value={item.roundName}
                        className={`custom-dropdown-item ${
                          item.name === selectedCenter
                            ? "custom-dropdown-item-selected"
                            : ""
                        }`} // Highlight the selected item
                        onClick={() => handleRoundSelection(item)} 
                      >
                          {item.roundName}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <button
                style={{
                  height: "30px",
                  backgroundColor: "#f0f0f0",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  cursor: "pointer",
                  padding: "0 10px",
                }}
                onClick={() => HandleReset()} // Reset the selected round
              >
                Reset
              </button>
              </div>

              <div class="table-page">
                <table>
                  <thead>
                    <tr>
                      <th rowspan="2">Treatment Regimens</th>
                      <th colspan="5">
                        <span style={{ fontSize: "20px" }}>
                          {" "}
                          Round {roundId}
                        </span>
                      </th>
                    </tr>
                    <th>Target number of new charts</th>
                    <th>Charts satisfying eligibility criteria</th>
                    <th>Abstracted new charts</th>
                    <th>Number of charts abstracted in previous rounds</th>
                    <th>Chart updates</th>
                  </thead>
                  <tbody>
                    {CohortTreatment.map((row) => (
                      <tr>
                        <td>
                          {row.cohortTreatmentName}
                          {row.cohortTreatmentNameSuperscript == null ? (
                            ""
                          ) : (
                            <>
                              <sup className="supscript">
                                {row.cohortTreatmentNameSuperscript.slice(0, 2)}
                              </sup>
                              {row.cohortTreatmentNameSuperscript.slice(2)}
                            </>
                          )}
                        </td>
                        <td class="blue-text">{row.newCharts ?? 0}</td>
                        <td class="blue-text">
                          {row.eligibilityChartUpdates ?? 0}
                        </td>
                        <td class="blue-text">
                          {row.abstractedNewCharts ?? 0}
                        </td>
                        <td class="blue-text">
                          {row.abstractedPreviousCharts ?? 0}
                        </td>
                        <td class="blue-text">{row.ChartUpdates ?? 0}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChartSummary;
