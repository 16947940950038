import React from "react";

const Footer = ({isLoginPage}) => {
  
    return (<section className={`footer-section ${isLoginPage ? "login-footer" : ""}`}>
         <p>Copyright © 2024</p>
     </section>)
};

export default Footer;
