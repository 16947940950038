import React, { useContext } from "react";
import { AppContext } from "../Contexts/AppContextProvider";

const Toast = () => {
  const { appState } = useContext(AppContext);
  return (
    appState.showToast && (
      <div
        style={{
          background: appState.toastType ? "rgb(255, 0, 0)" : "rgb(25,135,84)",
          position: "fixed", //"absolute"
          bottom: 20,
          padding: 10,
          textAlign: "center",
          left: "50%",
          transform: "translateX(-50%)", // Center horizontally
          zIndex: Number.MAX_SAFE_INTEGER, // Set a high z-index value to ensure it's on top
          borderRadius: "0.3rem",
          color:"white"
        
        }}
      >
        {appState.toastMessage}
      </div>
    )
  );
};

export default Toast;
