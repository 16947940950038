import React, { useContext } from "react";
import { AppContext } from "../Contexts/AppContextProvider";

const ActionPopUp = ({ onCancel, OnConfirmed }) => {
  const { appState } = useContext(AppContext);
  const { title, description, confirmButtonLabel, disableCancelBtn } =
    appState.actionPopUp;
  return (
    <div className="deleteActionModal">
      <div
        className="modal"
        style={{ display: "block", zIndex: Number.MAX_SAFE_INTEGER }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">{title ?? "Alert"}</h4>
            </div>
            <div className="modal-body">
              <p>
                <b>{description ?? "Are you sure?"}</b>
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={OnConfirmed}
              >
                {confirmButtonLabel ?? "Yes"}
              </button>
              {!disableCancelBtn && (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onCancel}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionPopUp;
