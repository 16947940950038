import { IsAdmin } from "../Helpers/Utilities";
import WebServiceWrapper from "./WebServiceWrapper";

export const BispecificsCartTreatmentCohortService = {
    GetBispecificCartTreatmentCohortData,
    SaveBispecificCartTreatmentCohortData,
};


function GetBispecificCartTreatmentCohortData(patientId) {
    return WebServiceWrapper.Get(`/bispecificsCartTreatmentCohort/GetBispecificCartTreatmentCohortData?patientId=${patientId}&isAdmin=${IsAdmin()}`);
}

function SaveBispecificCartTreatmentCohortData(payload) {
    return WebServiceWrapper.Post("/bispecificsCartTreatmentCohort/SaveBispecificCartTreatmentCohortData", payload);
}