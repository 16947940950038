import React from "react";

const Error = () => {
  return (
    <main style={{ backgroundColor: "#ffffff", minHeight: "85vh" }}>
      <section className="error-page">
        <div className="container">
          <div className="content-alignment">
            <div>
              <img
                width="300px"
                src="assets/images/404-error.jpg"
                alt="Not Found"
              />
            </div>
            <div className="content-pad">
              <h3 className="text-bold">Page Not Found</h3>
            </div>
            <div className="para-pad">
              <p>We're sorry, the page you requested could not be found</p>
            </div>
            <a href="/">
              <button className="btn btn-outline-light btn-lg create-course">
                Go to Home Page
              </button>
            </a>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Error;
