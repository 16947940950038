import React, { useContext, useEffect, useState } from 'react';
import { SideMenuItems, SideMenuPageList, HTTPResponse, ToastMessageType, RRMMCohortTreatment } from '../Helpers/Enums';
import { useNavigate } from 'react-router';
import { AddLocalStorageData, GetLocalStorageData, GetLoggedInUserID, isStringEmpty } from '../Helpers/Utilities';
import { PatientService } from "../WebApiServices/Patient.service";
import { AppContext } from '../Contexts/AppContextProvider';


const SideBarMenu = (props) => {

  const [incompletePageIds, setIncompletePageIds] = useState([]);
  const activePageId = props.activePageId;
  const patientID = GetLocalStorageData("patientId") ?? 0;
  const navigate = useNavigate();
  const { ShowToast, ToggleLoader, HandleSessionTimeout,SetPatientConfigData } = useContext(AppContext);
  //const { isSubmitted, formIncomplete } = useContext(AppContext);
  let patientData = GetLocalStorageData("patient");
  patientData = patientData && JSON.parse(patientData);

  const submit = patientData?.submit ?? 0;
  const locked = patientData?.locked ?? false;
  // const pid = patientData?.patientId ?? 0;
  // const userId = GetLocalStorageData("userId") ?? 0;

  const [pageList, setPageList] = useState(SideMenuPageList.filter(a =>
    !a.ndmm_rrmm_dependent && !a.ndmm_dependent && !a.rrmm_dependent
    && a.id !== SideMenuItems.AdditionalTreatmentCharacteristics
  ));

  const loadConfig = GetLocalStorageData("loadConfig") ?? false;
  const [eligible, setEligible] = useState(false);
  const [loading, setLoading] = useState(true)
  const [eligibilityCriteriaLocked, setEligibilityCriteriaLocked] = useState(false);



  useEffect(() => {
    const FetchData = async () => {

      try {
        AddLocalStorageData("loadConfig", false)


        //patient config data & eligible status
        const response2 = await PatientService.GetPatientConfigData(patientID);

        SetPatientConfigData(response2);


        if (response2?.status === HTTPResponse.OK) {
          if (response2 != null) {
            const { data } = response2;
            if (data != null) {
              const { isEligible, eligibilityCriteriaLocked, terminatedPageId } = data;


              setEligible(isEligible ?? false);
              setEligibilityCriteriaLocked(eligibilityCriteriaLocked ?? false);
              // if (!isEligible) {
              //   let pageList = [...SideMenuPageList];
              //   pageList = pageList.filter(a => !a.ndmm_rrmm_dependent && !a.ndmm_dependent && !a.rrmm_dependent
              //     && a.id !== SideMenuItems.AdditionalTreatmentCharacteristics);
              //   setPageList(pageList);
              //   return;
              // }

              let allPages = [...SideMenuPageList];

              const storedData = GetLocalStorageData("CohortTreatment");
              if (storedData) {
                let cohortTreatmentModelList = JSON.parse(storedData);

                if (cohortTreatmentModelList && cohortTreatmentModelList.length > 0) {
                  // Filter eligible treatments
                  cohortTreatmentModelList = cohortTreatmentModelList.filter(c => c.isEligible);

                  const hasNdmm = cohortTreatmentModelList.some(a => a.ndmm);
                  const hasRrmm = cohortTreatmentModelList.some(a => !a.ndmm);

                  // Check for specific treatments
                  const isTacOrTal = cohortTreatmentModelList.some(a => !a.ndmm && (
                    a.cohortTreatmentID === RRMMCohortTreatment.TALVEY ||
                    a.cohortTreatmentID === RRMMCohortTreatment.TECVAYLI)
                  );
                  const isAbeOrCar = cohortTreatmentModelList.some(a => !a.ndmm && (
                    a.cohortTreatmentID === RRMMCohortTreatment.ABECMA ||
                    a.cohortTreatmentID === RRMMCohortTreatment.CARVYKTI)
                  );

                  if (!hasNdmm && !hasRrmm) {
                    // No NDMM and no RRMM
                    allPages = allPages.filter(a =>
                      !a.ndmm_rrmm_dependent &&
                      !a.ndmm_dependent &&
                      !a.rrmm_dependent &&
                      a.id !== SideMenuItems.AdditionalTreatmentCharacteristics ||
                      a.id === SideMenuItems.ExclusionCriteria
                    );
                  } else if (!hasNdmm) {
                    // No NDMM, RRMM is present
                    allPages = allPages.filter(a =>
                      !a.ndmm_dependent
                    );
                  } else if (!hasRrmm) {
                    // No RRMM, NDMM is present
                    allPages = allPages.filter(a =>
                      !a.rrmm_dependent
                    );
                  }

                  if (!isTacOrTal) {
                    allPages = allPages.filter(a => a.id !== SideMenuItems.TTCharacteristics);
                  }
                  if (!isAbeOrCar) {
                    allPages = allPages.filter(a => a.id !== SideMenuItems.CartCharacteristics);
                  }
                  if (!hasNdmm && !isTacOrTal && !isAbeOrCar) {
                    allPages = allPages.filter(a => a.id !== SideMenuItems.AdditionalTreatmentCharacteristics);
                  }
                } else {
                  // No cohorts 
                  allPages = allPages.filter(a =>
                    !a.ndmm_rrmm_dependent &&
                    !a.ndmm_dependent &&
                    !a.rrmm_dependent &&
                    a.id !== SideMenuItems.AdditionalTreatmentCharacteristics
                  );
                }
              } else {
                // No stored data 
                allPages = allPages.filter(a =>
                  !a.ndmm_rrmm_dependent &&
                  !a.ndmm_dependent &&
                  !a.rrmm_dependent &&
                  a.id !== SideMenuItems.AdditionalTreatmentCharacteristics
                );
              }

              const temp = allPages.map((page) => ({
                ...page,
                disabled: page.id > 1 &&
                  (//Sequential locking for Eligibility Criteria Section
                    //next page/s close if terminated on prev page
                    (page.id === SideMenuItems.ChartSelection && patientID === 0) ||
                    (page.id === SideMenuItems.GeneralInformation && data.chartselection !== 1) ||


                    (page.id === SideMenuItems.NDMMCriteria &&
                      (data.generalinformation !== 1
                        || (!isEligible && terminatedPageId === SideMenuItems.GeneralInformation)
                      )
                    ) ||
                    (page.id === SideMenuItems.RRMMCriteria &&
                      (data.ndmm !== 1
                        || (!isEligible &&
                          (terminatedPageId === SideMenuItems.GeneralInformation ||
                            terminatedPageId === SideMenuItems.NDMMCriteria
                          )
                        )
                      )
                    ) ||
                    (page.id === SideMenuItems.ExclusionCriteria &&
                      (data.rrmm !== 1
                        || (!isEligible &&
                          (terminatedPageId === SideMenuItems.GeneralInformation ||
                            terminatedPageId === SideMenuItems.NDMMCriteria ||
                            terminatedPageId === SideMenuItems.RRMMCriteria
                          )
                        )
                      )
                    ) ||
                    (page.id === SideMenuItems.TransitionToChartAbstraction &&
                      (data.additionalexclusioncriteria !== 1
                        || (!isEligible &&
                          (terminatedPageId === SideMenuItems.GeneralInformation ||
                            terminatedPageId === SideMenuItems.NDMMCriteria ||
                            terminatedPageId === SideMenuItems.RRMMCriteria ||
                            terminatedPageId === SideMenuItems.ExclusionCriteria
                          )
                        )
                      )
                    )
                  ),
              }));

              setPageList(temp);


              if (data?.submit === 1 || (data?.demographics == 3 || data?.diseasehistory == 3 || data?.cohortcharacteristics == 3 || data?.ndmmtreatmentcohort == 3 || data?.noncohortcharacteristics == 3 || data?.ndmmsctcharacteristics == 3 || data?.treatmentresponseprogression == 3
                    || data?.bispecifictreatmentcohort == 3 || data?.teclistamabcharacteristics == 3 || data?.cartcharacteristics == 3 || data?.adverseevents==3)) {
                const incompletePageIdsArr = []

                if (!data.demographics
                  || data.demographics !== 1) {
                  incompletePageIdsArr.push(SideMenuItems.Demographics);
                }
                if (!data.diseasehistory || data.diseasehistory !== 1) {
                  incompletePageIdsArr.push(SideMenuItems.DiseaseHistory);
                }

                if (!data.comorbidities
                  || data.comorbidities !== 1) {
                  incompletePageIdsArr.push(SideMenuItems.Comorbidities);
                }


                if (!data.ndmmtreatmentcohort || data.ndmmtreatmentcohort !== 1) {
                  incompletePageIdsArr.push(SideMenuItems.NDMMTreatmentCohort);
                }


                if (!data.bispecifictreatmentcohort
                  || data.bispecifictreatmentcohort !== 1) {
                  incompletePageIdsArr.push(SideMenuItems.BispecificsCartTreatmentCohort);
                }

                if (!data.ndmmsctcharacteristics || data.ndmmsctcharacteristics !== 1) {
                  incompletePageIdsArr.push(SideMenuItems.NDMMSCTCharacteristics);
                }

                if (!data.teclistamabcharacteristics || data.teclistamabcharacteristics
                  !== 1) {
                  incompletePageIdsArr.push(SideMenuItems.TTCharacteristics);
                }

                if (!data.cartcharacteristics || data.cartcharacteristics !== 1) {
                  incompletePageIdsArr.push(SideMenuItems.CartCharacteristics);
                }


                if (!data.noncohortcharacteristics || data.noncohortcharacteristics !== 1) {
                  incompletePageIdsArr.push(SideMenuItems.NonCohortDefiningTreatment);
                }

                if (!data.adverseevents || data.adverseevents !== 1) {
                  incompletePageIdsArr.push(SideMenuItems.AdverseEvents);
                }

                if (!data.cohortcharacteristics || data.cohortcharacteristics !== 1) {
                  incompletePageIdsArr.push(SideMenuItems.CohortDefiningTreatment);
                }


                if (!data.treatmentresponseprogression || data.treatmentresponseprogression !== 1) {
                  incompletePageIdsArr.push(SideMenuItems.TreatmentResponseProgression);
                }

                setIncompletePageIds(incompletePageIdsArr)
              }
            }
          }
        }
        else if (response2?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      } catch (e) {
      }
      finally {
        setLoading(false);
      }
    }

    FetchData();
  }, [loadConfig])


  //SYJ - Handle Navigation with Highlight on Validate/Submit Click.
  /* <summary>
  date: 04-11-2023
  Name: AP
  description: Edited the function. Removed highlighting of incomplete forms 
      inside a useEffect since it wasn't showing up on first click.
  <summary>*/
  const HandleNavigate = async (pageUrl, pageId) => {

    try {

      if (pageId === SideMenuItems.ValidateSubmit) {
        const pid = GetLocalStorageData("patientId") ?? 0;
        const payload = {
          pid: pid,
          userId: GetLoggedInUserID(),
        };
        ToggleLoader(true)
        const response = await PatientService.SetPatientSubmitValue(payload);

        if (response?.status === HTTPResponse.OK) {
          // Set Patient Submit Value in LS
          const ptArr = { ...patientData };
          ptArr.submit = response?.data;

          AddLocalStorageData("patient", JSON.stringify(ptArr));
        }
      }
      navigate(pageUrl)
      if (patientID > 0) {
        //ToggleLoader(true);
        const response = await PatientService.IsEligiblePatient(patientID);
        const isEligible = response?.data ?? false;


        // const ptArr = { ...patientData, eligibilityCriteriaLocked: eligibilityCriteriaLocked }
        // AddLocalStorageData("patient", JSON.stringify(ptArr))
        navigate(pageUrl);
        if (isEligible) {
          // navigate(pageUrl);

          //if (pageUrl.trim().includes("Validate")) {
          //  ToggleLoader(true)
          //await SetPatientSubmitValue();
          // }
        }
        else {
          if (pageUrl.trim().includes("PatientScreening") || pageUrl.trim().includes("EligibilityCriteria")) {
            navigate(pageUrl);
          }
        }
        //ToggleLoader(false)
      }
    } catch {

    }
    finally{
      ToggleLoader(false)}
  }

  return (
    <div className="sidebar col-pad-right" id='mySidebar'>
      <div className="tab">
        <div className="activity-link-tab">
          {!loading && (
            pageList?.map((item) => (
              <button key={item.id}
                onClick={() => { HandleNavigate(item.path, item.id) }}
                className={`tablinks ${activePageId === item.id && "active"} 
                ${!item.notClickable && "clickable"}`}
                style={{ color: incompletePageIds.includes(item.id) ? "red " : "white" }}
                // style={{ color: item.incomplete ? "red " : "white" }}
                //disabled={item.disabled}  
                disabled={item.notClickable ||
                  (
                    item.disabled ||
                    //New Patient lock all except 1st page
                    (patientID === 0 && item.id > SideMenuItems.PatientScreening)
                    ||
                    //patient is eligible(metINclusionCriteria)
                    (!eligible && item.id >= SideMenuItems.Demographics && item.id <= SideMenuItems.ValidateSubmit)
                    ||
                    //lock Eligibility Criteria section if eligibilityCriteriaLocked is true
                    // (eligibilityCriteriaLocked && item.id >= SideMenuItems.GeneralInformation && item.id <= SideMenuItems.TransitionToChartAbstraction)
                    // ||
                    //lock after Eligibility Criteria section if eligibilityCriteriaLocked is not true
                    (!eligibilityCriteriaLocked && item.id >= SideMenuItems.Demographics && item.id <= SideMenuItems.ValidateSubmit)
                  )
                }
              >
                <span className={`${item.hasParent && "child"}`}>
                  {
                    //Chk if its a clickable tab
                    !item.notClickable && (
                      item.disabled ||
                      //New Patient lock all except 1st page
                      (patientID === 0 && item.id > SideMenuItems.PatientScreening)
                      ||
                      //patient is eligible(metINclusionCriteria)
                      (!eligible && item.id >= SideMenuItems.Demographics && item.id <= SideMenuItems.ValidateSubmit)
                      ||
                      //lock Eligibility Criteria section if eligibilityCriteriaLocked is true
                      (eligibilityCriteriaLocked && item.id >= SideMenuItems.GeneralInformation && item.id <= SideMenuItems.TransitionToChartAbstraction)
                      ||
                      //lock after Eligibility Criteria section if eligibilityCriteriaLocked is not true
                      (!eligibilityCriteriaLocked && item.id >= SideMenuItems.Demographics && item.id <= SideMenuItems.ValidateSubmit)
                    )
                    && <img className="lock-icon" src="../Assets/images/locked.png" alt="" />
                  }

                  {item.id !== SideMenuItems.ValidateSubmit
                    ? <span>{item.title}</span>
                    : (<span>
                      {locked ? "SUBMITTED"
                        // : submit === 1 && incompletePageIds.length === 0
                        //   ? "Submit Patient" : item.title
                        : item.title
                      }
                    </span>)
                  }
                </span>
              </button>
            ))
          )}
        </div>
      </div>
    </div>
  )
}

export default SideBarMenu