import { IsAdmin } from "../Helpers/Utilities";
import WebServiceWrapper from "./WebServiceWrapper";

export const NDMMTreatmentCohortService = {
    GetTableData, 
    GetNDMMCohortTreatmentData,
    SaveNDMMCohortTreatmentData,
};

function GetTableData(patientId) {
  return WebServiceWrapper.Get(`/ndmmTreatmentCohort/GetTableData?patientId=${patientId}`);
}

function GetNDMMCohortTreatmentData(patientId) {
    return WebServiceWrapper.Get(`/ndmmTreatmentCohort/GetNDMMCohortTreatmentData?patientId=${patientId}&isAdmin=${IsAdmin()}`);
}

function SaveNDMMCohortTreatmentData(payload) {
  return WebServiceWrapper.Post("/ndmmTreatmentCohort/SaveNDMMCohortTreatmentData", payload);
}