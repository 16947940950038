import React, { useContext, useEffect, useState } from "react";
import PatientDetails from "../../../Components/PatientInformation";
import SaveAndNavigate from "../../../Components/SaveAndNavigate";
import ErrorField from "../../../Components/ErrorField";
import SideBarMenu from "../../../Components/SideBarMenu";
import {
  HTTPResponse,
  PageDesignConstants,
  SideMenuItems,
  ToastMessageType,
  ToastMessages,
} from "../../../Helpers/Enums";
import { CarTCharMsgs, CommonError_Msgs } from "../../../Helpers/HelperText";
import DateControl from "../../../Components/DateControl";
import {
  DateInRange,
  GetLocalStorageData,
  GetLoggedInUserID,
  IsAdmin,
  isStringEmpty,
} from "../../../Helpers/Utilities";
import { AppContext } from "../../../Contexts/AppContextProvider";
import { AdditionalTreatmentService } from "../../../WebApiServices/AdditionalTreatment.service";
import DatePickerComponent from "../../../Components/DatePickerComponent";
import dayjs from "dayjs";


const CarTCharacteristics = () => {
  const pid = GetLocalStorageData("patientId") ?? 0;
  const storedData = GetLocalStorageData("CohortTreatment");

  let cohortTreatmentModelListt = [];
  if (storedData) {
    cohortTreatmentModelListt = JSON.parse(storedData);
  }
  const TTIsPresent = cohortTreatmentModelListt.some(
    (treatment) =>
      (treatment.cohortTreatmentID === 9 ||
        treatment.cohortTreatmentID === 10) &&
      treatment.isEligible === true
  );

  const NDMMIsPresent = cohortTreatmentModelListt.some(
    (treatment) => treatment.ndmm === true && treatment.isEligible === true
  );

  const navigatePaths = {
    prevPage: TTIsPresent
      ? "/TTCharacteristics"
      : NDMMIsPresent
      ? "/NDMMSCTCharacteristics"
      : "/NonCohortDefiningTreatment",
    currPage: "/CarTCharacteristics",
    nextPage: "/IMWGReporting",
  };

  const filteredList = cohortTreatmentModelListt
    .filter(
      (item) =>
        !item.ndmm &&
        item.isEligible &&
        (item.cohortTreatmentID === 13 || item.cohortTreatmentID === 14)
    )
    .sort((a, b) => {
      if (a.cohortTreatmentID === 13) return -1;
      if (b.cohortTreatmentID === 14) return 1;
      return 0;
    });

  const {
    ShowToast,
    ToggleLoader,
    HandleSessionTimeout,
    isSideMenuActive,
    setIsSideMenuActive,
  } = useContext(AppContext);

  const [showTopErrMsg, setShowTopErrMsg] = useState(false);

  const [bispecificDate, setBispecificDate] = useState("");

  const [carTCharData, setCarTCharData] = useState({
    CartcharacteristicsId: 0,
    CohortTreatmentId: null,
    q41_Apheresis_Date: "",
    endOfFollowupDate: "",
    carvyktiFollowupDate: "",
    abecmaFollowupDate: "",
  });

  const [carTCharRepeater, setCarTCharRepeater] = useState({
    //41
    q41_Apheresis_Date: "",
    q41_Apheresis_Date_Empty: false,
    q41_Apheresis_Date_NotValid: false,
    q41_Apheresis_InvalidDate:false,

    // 42
    carTCharacteristicsTypeList: {
      q42_Therapy_Receive: null,
      q42_Therpy_Initiation_Date: "",
      q42_Therpy_Initiation_Radio: null,
      // q42_CohortTreatment_Unk: null,
      q42_Therapy_Initiation_DateInvalid:false,

      q42_Therapy_Receive_Empty: false,

      q42_Therpy_Initiation_Date_Empty: false,
      q42_Therpy_Initiation_Radio_Empty: false,
      carTtherapyName: "",
      carTTherapyId: 0,

      q42_R2_Date_Not_Valid: false,
      q42_CohortTreatment_Unk: null,
      q42_Therapy_Initiation_NotValid: false,
      therapyReceivedEmpty: false,
    },

    //checkbox
    carTCharacteristicsReceivedList: {
      carTTherapyReceivedId: 0,
      carTtherapyReceivedName: "",
      carTtherapyId: 0,
      isChecked: false,
      carTOtherTxt: "",

      isCheckedEmpty: false,
      carTOtherTxtEmpty: false,
    },

    // Q43
    q43B_No_Procedure_Rcvd: false,
    q43B_Procedure_Date: "",
    q43C_ProcedureType: null,
    q43C_ProcedureType_Txt: "",
    q43B_No_Procedure_RcvdEmpty: false,
    q43B_Procedure_DateEmpty: false,
    q43C_ProcedureTypeEmpty: false,
    q43C_ProcedureType_TxtEmpty: false,
    q43_Procedure_InvalidDate : false,

    q43_Date_Valid: false,
  });

  // const [errorMsg, setErrorMsg] = useState({
  //   q41_Apheresis_Date_Empty: false,
  //   q41_Apheresis_Date_NotValid: false,
  // });

  const HandleRepeaterFieldChange = (e) => {
    const { name, id, value, checked } = e.target;
    const value_int = Number(value);

    // Extract loopIndex and innerLoopIndex from the id
    const parts = id.split("_");
    const loopIndex = Number(parts[0]);
    const innerLoopIndex = Number(parts[1]);
    const carTTherapyId = Number(parts[2]);

    // Create a copy of the current state
    let updatedArray = [...carTCharRepeater];

    switch (name) {
      case `q42_Therapy_Receive${loopIndex}${innerLoopIndex}`: {
        // Create a deep copy of the inner object to avoid direct mutation
        const updatedTypeList = [
          ...updatedArray[loopIndex].carTCharacteristicsTypeList,
        ];

        // Update only the specific item in the list
        updatedTypeList[innerLoopIndex] = {
          ...updatedTypeList[innerLoopIndex],
          q42_Therapy_Receive: value_int,
          q42_Therpy_Initiation_Date: "",
          q42_Therpy_Initiation_Radio: null,
          q42_Therapy_Initiation_DateInvalid:false,
          q42_Therapy_Initiation_NotValid: false,
          q42_R2_Date_Not_Valid: false,
          q42_Therpy_Initiation_Date_Empty: false,
          q42_Therpy_Initiation_Radio_Empty: false,
          q42_Therapy_Receive_Empty: false,
          therapyReceivedEmpty: false,
        };

        // Only uncheck checkboxes for the current treatment
        const updatedReceivedList = updatedArray[
          loopIndex
        ].carTCharacteristicsReceivedList.map((item) =>
          item.carTtherapyId === innerLoopIndex + 1
            ? {
                ...item,
                isChecked: false,
                isCheckedEmpty: false,
                carTOtherTxtEmpty: false,
                carTOtherTxt: "",
              }
            : item
        );

        updatedArray[loopIndex] = {
          ...updatedArray[loopIndex],
          carTCharacteristicsTypeList: updatedTypeList,
          carTCharacteristicsReceivedList: updatedReceivedList,
        };

        setCarTCharRepeater(updatedArray);
        break;
      }

      case `q42_Therpy_Initiation_Radio${loopIndex}${innerLoopIndex}`: {
        // Create a deep copy of the inner object to avoid direct mutation
        const updatedTypeList = [
          ...updatedArray[loopIndex].carTCharacteristicsTypeList,
        ];
        updatedTypeList[innerLoopIndex] = {
          ...updatedTypeList[innerLoopIndex],
          q42_Therpy_Initiation_Date: "",
          q42_Therpy_Initiation_Radio: value_int,
          q42_Therpy_Initiation_Radio_Empty: false,
          q42_Therapy_Initiation_DateInvalid:false,

          q42_R2_Date_Not_Valid: false,
          q42_Therpy_Initiation_Date_Empty: false,

          q42_Therapy_Initiation_NotValid: false,
          // carTOtherTxtEmpty: false,
          //therapyReceivedEmpty: false,
        };
        updatedArray[loopIndex] = {
          ...updatedArray[loopIndex],
          carTCharacteristicsTypeList: updatedTypeList,
        };
        setCarTCharRepeater(updatedArray);
        break;
      }

      case `q42_CohortTreatment_Unk${loopIndex}${innerLoopIndex}`: {
        // Create a deep copy of the inner object to avoid direct mutation
        const updatedTypeList = [
          ...updatedArray[loopIndex].carTCharacteristicsTypeList,
        ];
        updatedTypeList[innerLoopIndex] = {
          ...updatedTypeList[innerLoopIndex],
          q42_CohortTreatment_Unk: value_int,
          therapyReceivedEmpty: false,
        };

        // Uncheck all checkboxes and clear text inputs
        const updatedReceivedList =
          updatedArray[loopIndex].carTCharacteristicsReceivedList;

        const updatedList = updatedReceivedList.map((item) => {
          if (
            item.carTtherapyId === updatedTypeList[innerLoopIndex].carTTherapyId
          ) {
            return {
              ...item,
              isChecked: false,
              carTOtherTxt: "",
              carTOtherTxtEmpty: false,
            };
          }
          return item;
        });

        updatedArray[loopIndex] = {
          ...updatedArray[loopIndex],
          carTCharacteristicsTypeList: updatedTypeList,
          carTCharacteristicsReceivedList: updatedList,
        };

        setCarTCharRepeater(updatedArray);
        break;
      }

      case `q43B_No_Procedure_Rcvd${loopIndex}`: {
        updatedArray[loopIndex] = {
          ...updatedArray[loopIndex],
          q43B_No_Procedure_Rcvd: value_int,
          q43B_No_Procedure_RcvdEmpty: false,
          q43_Date_Valid: false,
          q43B_Procedure_DateEmpty: false,
          q43B_Procedure_Date: "",
          q43C_ProcedureType:
            value_int === 1 ? null : updatedArray[loopIndex].q43C_ProcedureType,
          q43C_ProcedureType_Empty:
            value_int === 1
              ? false
              : updatedArray[loopIndex].q43C_ProcedureType_Empty,
          q43C_ProcedureType_Txt:
            value_int === 1
              ? ""
              : updatedArray[loopIndex].q43C_ProcedureType_Txt,
          q43C_ProcedureType_TxtEmpty:
            value_int === 1
              ? false
              : updatedArray[loopIndex].q43C_ProcedureType_TxtEmpty,
        };

        setCarTCharRepeater(updatedArray);
        break;
      }

      case `q43C_ProcedureType${loopIndex}`: {
        updatedArray[loopIndex] = {
          ...updatedArray[loopIndex],
          q43C_ProcedureType: value_int,
          q43C_ProcedureTypeEmpty: false,
          q43C_ProcedureType_Txt: "",
          q43C_ProcedureType_TxtEmpty: false,
        };
        setCarTCharRepeater(updatedArray);
        break;
      }

      case `q43C_ProcedureType_Txt${loopIndex}`: {
        updatedArray[loopIndex] = {
          ...updatedArray[loopIndex],
          q43C_ProcedureType_Txt: value,
          q43C_ProcedureType: 7,
          q43C_ProcedureTypeEmpty: false,
          q43C_ProcedureType_TxtEmpty: false,
        };
        setCarTCharRepeater(updatedArray);
        break;
      }

      case `checkbox${loopIndex}${innerLoopIndex}`: {
        const updatedTypeList = [
          ...updatedArray[loopIndex].carTCharacteristicsTypeList,
        ];
        const updatedReceivedList = [
          ...updatedArray[loopIndex].carTCharacteristicsReceivedList,
        ];

        const requiredIndex = updatedReceivedList.findIndex(
          (x) => x.carTTherapyReceivedId == innerLoopIndex
        );

        updatedReceivedList
          .filter((x) => x.carTtherapyId == carTTherapyId)
          .forEach((item) => {
            item.carTOtherTxtEmpty = false;
          });

        if (requiredIndex > -1) {
          updatedReceivedList[requiredIndex] = {
            ...updatedReceivedList[requiredIndex],
            isChecked: checked,
            isCheckedEmpty: false,
            carTOtherTxtEmpty: false,
            carTOtherTxt:
              requiredIndex === 26 || requiredIndex === 32
                ? ""
                : updatedReceivedList[requiredIndex].carTOtherTxt,
          };
        }

        // If any checkbox is checked, unselect the "Unknown" radio button
        // if (checked) {
        //   updatedTypeList.forEach((item ) => {
        //     //map inside and then hide orelse both rows UNK unselects
        //     item.q42_CohortTreatment_Unk = null;
        //     item.therapyReceivedEmpty = false;
        //   });
        // }

        if (checked) {
          updatedTypeList
            .filter((item) => item.carTTherapyId === carTTherapyId) // Replace 1 with the desired carTTherapyId value
            .forEach((item) => {
              item.q42_CohortTreatment_Unk = null;
              item.therapyReceivedEmpty = false;
            });
        }

        updatedArray[loopIndex] = {
          ...updatedArray[loopIndex],
          carTCharacteristicsTypeList: updatedTypeList,
          carTCharacteristicsReceivedList: updatedReceivedList,
        };

        setCarTCharRepeater(updatedArray);
        break;
      }

      case `Checkboxtext${loopIndex}${innerLoopIndex}`: {
        const updatedTypeList = [
          ...updatedArray[loopIndex].carTCharacteristicsTypeList,
        ];
        const updatedReceivedList = [
          ...updatedArray[loopIndex].carTCharacteristicsReceivedList,
        ];

        const requiredIndex = updatedReceivedList.findIndex(
          (x) => x.carTTherapyReceivedId == innerLoopIndex
        );

        if (requiredIndex > -1) {
          updatedReceivedList[requiredIndex] = {
            ...updatedReceivedList[requiredIndex],
            carTOtherTxt: value,
            isChecked: true,
            carTOtherTxtEmpty: false,
          };
        }

        // If text is entered, unselect the "Unknown" radio button
        // if (value) {
        //   updatedTypeList.forEach((item) => {
        //     item.q42_CohortTreatment_Unk = null;
        //     item.therapyReceivedEmpty = false;
        //   });
        // }

        if (value) {
          updatedTypeList
            .filter((item) => item.carTTherapyId === carTTherapyId) // Replace 1 with the desired carTTherapyId value
            .forEach((item) => {
              item.q42_CohortTreatment_Unk = null;
              item.therapyReceivedEmpty = false;
            });
        }

        updatedArray[loopIndex] = {
          ...updatedArray[loopIndex],
          carTCharacteristicsTypeList: updatedTypeList,
          carTCharacteristicsReceivedList: updatedReceivedList,
        };

        setCarTCharRepeater(updatedArray);
        break;
      }

      default:
        break;
    }
  };

  function parseDate(dateStr) {
    const [month, day, year] = dateStr.split("/").map(Number);
    return new Date(year, month - 1, day);
  }
  function compareLastPatientEncounterDate(
    cohortIndexDateStr,
    apheresisDateStr
  ) {
    const cohortIndexDate = cohortIndexDateStr
      ? parseDate(cohortIndexDateStr)
      : null;
    const lastEncounterDate = apheresisDateStr
      ? parseDate(apheresisDateStr)
      : null;

    // Ensure both dates are valid before comparison
    if (cohortIndexDate && lastEncounterDate) {
      return lastEncounterDate <= cohortIndexDate;
    }

    // If any of the dates are invalid, return false
    return false;
  }

  const checkTherapyDate = (apheresisDateStr, therapyDateStr, indexDateStr) => {
    // Helper function to parse dates from MM/DD/YYYY format
    const parseDate = (dateStr) => {
      if (!dateStr) return null; // Handle null or empty date strings
      const [month, day, year] = dateStr.split("/");
      return new Date(year, month - 1, day);
    };

    // Parse the dates
    const dateOfApheresis = parseDate(apheresisDateStr);
    const therapyDate = parseDate(therapyDateStr);
    const bispecificsCarTIndexDate = parseDate(indexDateStr);

    // Handle null dates
    if (!dateOfApheresis || !therapyDate || !bispecificsCarTIndexDate) {
      return false; // Validation fails if any date is null
    }

    // Validate the date range
    const isValid =
      therapyDate >= dateOfApheresis && therapyDate <= bispecificsCarTIndexDate;

    return isValid;
  };

  //Q42 Date Handling
  const HandleRepeatDateChange = (name, date) => {
 
    const regex = /q42_Therpy_Initiation_Date(\d+)_(\d+)/;
    const match = name.match(regex);

    const loopIndex = parseInt(match[1], 10);
    const innerLoopIndex = parseInt(match[2], 10);

    let updatedArray = [...carTCharRepeater];

    // const dateIsvalid = checkTherapyDate(
    //   updatedArray[loopIndex].q41_Apheresis_Date,
    //   date,
    //   filteredList[loopIndex].cohortIndexDate
    // );

    // Create a deep copy of the inner object to avoid direct mutation
    const updatedTypeList = [
      ...updatedArray[loopIndex].carTCharacteristicsTypeList,
    ];
    updatedTypeList[innerLoopIndex] = {
      ...updatedTypeList[innerLoopIndex],
      q42_Therpy_Initiation_Date: date,
      q42_Therpy_Initiation_Radio: 1,
      q42_Therapy_Receive_Empty: false,
      q42_Therapy_Initiation_NotValid:false,
      q42_R2_Date_Not_Valid: false,
      q42_Therpy_Initiation_Date_Empty: false,
      q42_Therpy_Initiation_Radio_Empty: false,
      q42_Therapy_Initiation_DateInvalid:false,
     // q42_Therapy_Initiation_NotValid: dateIsvalid ? false : true,
    };
    updatedArray[loopIndex] = {
      ...updatedArray[loopIndex],
      carTCharacteristicsTypeList: updatedTypeList,
    };

    // Update the state
    setCarTCharRepeater(updatedArray);
  };

  // const HandleDateChange = (ctrlId, date) => {
  //   if (ctrlId === "q41_Apheresis_Date" && date !== "") {
  //     let apheresisDateValid = compareLastPatientEncounterDate(
  //       bispecificDate,
  //       date
  //     );

  //     setCarTCharData((prevData) => ({
  //       ...prevData,
  //       q41_Apheresis_Date: date,
  //     }));
  //     setErrorMsg((prev) => ({
  //       ...prev,

  //       q41_Apheresis_Date_Empty: false,
  //       q41_Apheresis_Date_NotValid: !apheresisDateValid ? true : false,
  //     }));
  //   }
  // };

  function validateQ43Date(date, ndmmEndofFollowupDate, cohortIndexDate) {
    const formatDate = (dateStr) => {
      if (!dateStr) return new Date();
      const [month, day, year] = dateStr.split("/").map(Number);
      return new Date(year, month - 1, day);
    };

    const startDate = formatDate(cohortIndexDate);
    const endDate = formatDate(ndmmEndofFollowupDate);
    const dateToCompare = formatDate(date);

    if (!cohortIndexDate && !ndmmEndofFollowupDate) {
      return true;
    }

    return dateToCompare >= startDate && dateToCompare <= endDate;
  }

  function validateQ41Date(date, cohortIndexDate) {
    const formatDate = (dateStr) => {
      if (!dateStr) return new Date();
      const [month, day, year] = dateStr.split("/").map(Number);
      return new Date(year, month - 1, day);
    };

    const startDate = formatDate(cohortIndexDate);
 
    const dateToCompare = formatDate(date);

    if (!cohortIndexDate) {
      return true;
    }

    return dateToCompare <= startDate ;
  }



  const HandleRepeaterDateChange = (name, date) => {
    const lastIndex = name.lastIndexOf("-");
    const id = parseInt(name.slice(lastIndex + 1), 10);

    let updatedArray = [...carTCharRepeater];
    let ndmmEndoffollowupDate = "";

    if (id === 0) {
      ndmmEndoffollowupDate = carTCharData.carvyktiFollowupDate;
    } else if (id === 1) {
      ndmmEndoffollowupDate = carTCharData.abecmaFollowupDate;
    }

    if (ndmmEndoffollowupDate == "" || ndmmEndoffollowupDate == null) {
      ndmmEndoffollowupDate = carTCharData.endOfFollowupDate;
    }



    switch (true) {
      case name.startsWith("q43B_Procedure_Date"):
        // First, update the state with the selected date
        updatedArray[id].q43B_No_Procedure_Rcvd = 2;
        updatedArray[id].q43B_Procedure_Date =
          updatedArray[id].q43B_No_Procedure_Rcvd === 2 ? date : "";
        updatedArray[id].q43B_No_Procedure_Rcvd = 2;
        updatedArray[id].q43B_No_Procedure_RcvdEmpty = false;
        updatedArray[id].q43B_Procedure_DateEmpty = false;
        updatedArray[id].q43_Date_Valid = false;
        updatedArray[id].q43_Procedure_InvalidDate = false;

        // Now, validate the date after updating the state
        // const dateIsValid = validateQ43Date(
        //   updatedArray[id].q43B_Procedure_Date,
        //   ndmmEndoffollowupDate,
        //   filteredList[id].cohortIndexDate
        // );

        // Update the validation status in the state
       // updatedArray[id].q43_Date_Valid = !dateIsValid;

        break;

      case name.startsWith("q41_Apheresis_Date"):
       
        updatedArray[id].q41_Apheresis_Date = date;

        updatedArray[id].q41_Apheresis_Date_Empty = false;
        updatedArray[id].q41_Apheresis_Date_NotValid = false;
        updatedArray[id].q41_Apheresis_InvalidDate = false;

      //  const dateQ41IsValid =  validateQ41Date( updatedArray[id].q41_Apheresis_Date,   filteredList[id].cohortIndexDate)
      //  updatedArray[id].q41_Apheresis_Date_NotValid = !dateQ41IsValid;

        break;

      default:
        break;
    }

    // Finally, set the state again after validation
    setCarTCharRepeater(updatedArray);
  };

  const ValidateForm = () => {
    
    let valid = true;
    let errors = {
      q41_Apheresis_Date_Empty: false,
      q41_Apheresis_Date_NotValid: false,
      q42_Therapy_Receive_Empty: false,
      q42_Therapy_Initiation_NotValid:false,
      q42_Therpy_Initiation_Radio_Empty: false,
      q42_Therpy_Initiation_Date_Empty: false,
      q43B_No_Procedure_Rcvd_Empty: false,
      q43C_ProcedureType_Empty: false,
      q43B_Procedure_Date_Empty: false,
      q43C_ProcedureType_Txt_Empty: false,
      isCheckedEmpty: false,
      carTOtherTxtEmpty: false,
      q43_Date_Valid: false,
      q42_R2_Date_Not_Valid: false,
      q41_Apheresis_InvalidDate:false,
      q43_Procedure_InvalidDate:false,
      q42_Therapy_Initiation_DateInvalid:false,
    };

    // Validate q41_Apheresis_Date
    // if (!carTCharData.q41_Apheresis_Date) {
    //   errors.q41_Apheresis_Date_Empty = true;
    //   valid = false;
    // } else if (errorMsg.q41_Apheresis_Date_NotValid) {
    //   errors.q41_Apheresis_Date_NotValid = true;
    //   valid = false;
    // }

    // Validate carTCharRepeater fields
    if (carTCharRepeater) {
      carTCharRepeater.forEach((repeaterItem) => {
        // Validate q42 fields
        repeaterItem.carTCharacteristicsTypeList.forEach((item) => {
          const isCheckboxSectionVisible = item.q42_Therapy_Receive === 1;
          if (isCheckboxSectionVisible) {
            // Check if any checkbox is checked or if "Unknown" is selected
            const isAnyChecked =
              repeaterItem.carTCharacteristicsReceivedList.some(
                (item1) =>
                  item1.isChecked === true &&
                  item1.carTtherapyId == item.carTTherapyId
              );
            const isUnknownSelected = item.q42_CohortTreatment_Unk === 99;

            if (!isAnyChecked && !isUnknownSelected) {
              errors.isCheckedEmpty = true;
              repeaterItem.carTCharacteristicsReceivedList
                .filter((item1) => item1.carTtherapyId == item.carTTherapyId)
                .forEach((items) => {
                  items.isCheckedEmpty = true;
                  item.therapyReceivedEmpty = true;
                });
              valid = false;
            }
          }

          repeaterItem.carTCharacteristicsReceivedList
            .filter((item1) => item1.carTtherapyId == item.carTTherapyId)
            .forEach((item) => {
              if (
                item.isChecked &&
                (item.carTTherapyReceivedId === 27 ||
                  item.carTTherapyReceivedId === 33) &&
                !item.carTOtherTxt
              ) {
                errors.carTOtherTxtEmpty = true;
                item.carTOtherTxtEmpty = true;
                valid = false;
              }
            });

          // Validate q42 Therapy Receive
          if (!item.q42_Therapy_Receive) {
            errors.q42_Therapy_Receive_Empty = true;
            item.q42_Therapy_Receive_Empty = true;
            valid = false;
          }

          if (item.q42_Therapy_Receive === 1) {
            debugger
            // Validate q42 Therapy Initiation Radio
            if (!item.q42_Therpy_Initiation_Radio) {
              errors.q42_Therpy_Initiation_Radio_Empty = true;
              item.q42_Therpy_Initiation_Radio_Empty = true;
              // errors.q42_Therapy_Initiation_NotValid = true;
              // item.q42_Therpy_Initiation_Date_Empty = true;

              valid = false;
            }

            // Validate q42 Therapy Initiation Date
            debugger
            if (
              (!item.q42_Therpy_Initiation_Date ||
                item.q42_Therpy_Initiation_Date == null ||
                item.q42_Therpy_Initiation_Date == "") &&
              item.q42_Therpy_Initiation_Radio === 1
            ) {
              errors.q42_Therpy_Initiation_Date_Empty = true;
              item.q42_Therpy_Initiation_Date_Empty = true;
              valid = false;
            } else if (item.q42_Therapy_Initiation_NotValid) {
              // errors.q42_Therapy_Initiation_NotValid = true;
              // item.q42_Therpy_Initiation_Date_Empty = true;
              valid = false;
            }


            if (
              item.q42_Therpy_Initiation_Date !== null &&
              item.q42_Therpy_Initiation_Date !== "" &&
              item.q42_Therpy_Initiation_Radio === 1 &&  item.q42_Therpy_Initiation_Date != "Invalid Date" 
              && DateInRange( item.q42_Therpy_Initiation_Date , dayjs("01-01-1920"), dayjs())
            ) 
             {

              const dateIsvalid = checkTherapyDate(
                repeaterItem.q41_Apheresis_Date,
                item.q42_Therpy_Initiation_Date,
                repeaterItem.cohortIndexDate
              );
              errors.q42_Therapy_Initiation_NotValid = !dateIsvalid;
              item.q42_Therapy_Initiation_NotValid = !dateIsvalid;

              if(dateIsvalid == false){
                valid = false;
              }
              
            } 

            if (item.q42_Therpy_Initiation_Date === "Invalid Date")
            {
              errors.q42_Therapy_Initiation_DateInvalid = true;
              item.q42_Therapy_Initiation_DateInvalid = true;
              valid = false;
            }
        
            else if(item.q42_Therpy_Initiation_Date !== "Invalid Date" && item.q42_Therpy_Initiation_Date !== "" && !DateInRange(item.q42_Therpy_Initiation_Date, dayjs("01-01-1920"), dayjs())){
              errors.q42_Therapy_Initiation_DateInvalid = true;
              item.q42_Therapy_Initiation_DateInvalid = true;
              valid = false;
            } 
          }
        });

        // Validate q41
        if (
          repeaterItem.q41_Apheresis_Date == "" ||
          repeaterItem.q41_Apheresis_Date == null
        ) {
          errors.q41_Apheresis_Date_Empty = true;
          repeaterItem.q41_Apheresis_Date_Empty = true;
          valid = false;
        }

        if (
          (repeaterItem.q41_Apheresis_Date !== "" ||
            repeaterItem.q41_Apheresis_Date !== null) &&  repeaterItem.q41_Apheresis_Date != "Invalid Date" 
            && DateInRange( repeaterItem.q41_Apheresis_Date , dayjs("01-01-1920"), dayjs())
           //  && repeaterItem.q41_Apheresis_Date_NotValid == true
          
        ) {

          const dateIsValid = validateQ41Date(repeaterItem.q41_Apheresis_Date , repeaterItem.cohortIndexDate);
          errors.q41_Apheresis_Date_NotValid = true;
          repeaterItem.q41_Apheresis_Date_NotValid = !dateIsValid;
          if(dateIsValid == false){
            valid =  false ;
            }
        }

        if (repeaterItem.q41_Apheresis_Date === "Invalid Date")
        {
          errors.q41_Apheresis_InvalidDate = true;
          repeaterItem.q41_Apheresis_InvalidDate = true;
          valid = false;
        }
    
        else if(repeaterItem.q41_Apheresis_Date !== "Invalid Date" && repeaterItem.q41_Apheresis_Date !== "" && !DateInRange(repeaterItem.q41_Apheresis_Date, dayjs("01-01-1920"), dayjs())){
          errors.q41_Apheresis_InvalidDate = true;
          repeaterItem.q41_Apheresis_InvalidDate = true;
          valid = false;
        } 

        // Validate q43 fields
        if (!repeaterItem.q43B_No_Procedure_Rcvd) {
          errors.q43B_No_Procedure_Rcvd_Empty = true;
          repeaterItem.q43B_No_Procedure_RcvdEmpty = true;
          valid = false;
        }

        if (
          repeaterItem.q43B_No_Procedure_Rcvd === 2 &&
          (repeaterItem.q43B_Procedure_Date == null ||
            repeaterItem.q43B_Procedure_Date == "")
        ) {
          errors.q43B_Procedure_Date_Empty = true;
          //  errors.q43_Date_Valid = true;
          repeaterItem.q43B_Procedure_DateEmpty = true;
          valid = false;
        }

        
        if (
          repeaterItem.q43B_No_Procedure_Rcvd === 2 &&
          (repeaterItem.q43B_Procedure_Date !== null ||
            repeaterItem.q43B_Procedure_Date !== "") &&  repeaterItem.q43B_Procedure_Date != "Invalid Date" 
            && DateInRange( repeaterItem.q43B_Procedure_Date , dayjs("01-01-1920"), dayjs())
          //  && repeaterItem.q43_Date_Valid
        ) {
          let ndmmEndoffollowupDate = "";

          if (repeaterItem.cohortTreatmentId === 13) {
            ndmmEndoffollowupDate = carTCharData.carvyktiFollowupDate;
          } else if (repeaterItem.cohortTreatmentId  === 14) {
            ndmmEndoffollowupDate = carTCharData.abecmaFollowupDate;
          }
      
          if (ndmmEndoffollowupDate == "" || ndmmEndoffollowupDate == null) {
            ndmmEndoffollowupDate = carTCharData.endOfFollowupDate;
          }
          const dateIsValid = validateQ43Date(
            repeaterItem.q43B_Procedure_Date,
            ndmmEndoffollowupDate,
            repeaterItem.cohortIndexDate
          );
          errors.q43_Date_Valid = true;
          repeaterItem.q43_Date_Valid = !dateIsValid;
          if(dateIsValid == false){
          valid =  false ;
          }
         
        }

        if (repeaterItem.q43B_Procedure_Date === "Invalid Date")
        {
          errors.q43_Procedure_InvalidDate = true;
          repeaterItem.q43_Procedure_InvalidDate = true;
          valid = false;
        }
    
        else if(repeaterItem.q43B_Procedure_Date !== "Invalid Date" && repeaterItem.q43B_Procedure_Date !== "" && !DateInRange(repeaterItem.q43B_Procedure_Date, dayjs("01-01-1920"), dayjs())){
          errors.q43_Procedure_InvalidDate = true;
          repeaterItem.q43_Procedure_InvalidDate = true;
          valid = false;
        } 

        if (
          [2, 99].includes(repeaterItem.q43B_No_Procedure_Rcvd) &&
          !repeaterItem.q43C_ProcedureType
        ) {
          errors.q43C_ProcedureType_Empty = true;
          repeaterItem.q43C_ProcedureTypeEmpty = true;
          valid = false;
        }
        if (
          repeaterItem.q43C_ProcedureType === 7 &&
          (repeaterItem.q43C_ProcedureType_Txt === "" ||
            repeaterItem.q43C_ProcedureType_Txt === null)
        ) {
          errors.q43C_ProcedureType_Txt_Empty = true;
          repeaterItem.q43C_ProcedureType_TxtEmpty = true;
          valid = false;
        }



      //Q42 Date Validation. R2 > R1
      debugger
        if (!repeaterItem.q42_Therapy_Initiation_NotValid &&  repeaterItem.carTCharacteristicsTypeList[0]
          ?.q42_Therpy_Initiation_Date !== "Invalid Date"  &&  repeaterItem.carTCharacteristicsTypeList[1]
          ?.q42_Therpy_Initiation_Date !== "Invalid Daate") {
          const r1Date =
            repeaterItem.carTCharacteristicsTypeList[0]
              ?.q42_Therpy_Initiation_Date;
          const r2Date =
            repeaterItem.carTCharacteristicsTypeList[1]
              ?.q42_Therpy_Initiation_Date;

          if (r1Date && r2Date) {
            const date1 = new Date(r1Date);
            const date2 = new Date(r2Date);

            // Check if both dates are valid Date objects
            if (!isNaN(date1) && !isNaN(date2)) {
              if (
                date1 > date2 &&
                repeaterItem.carTCharacteristicsTypeList[1]
                  .q42_R2_Date_Not_Valid == false
              ) {
                errors.q42_R2_Date_Not_Valid = true;
                repeaterItem.carTCharacteristicsTypeList[1].q42_R2_Date_Not_Valid = true;
                valid = false;
              } else {
                repeaterItem.carTCharacteristicsTypeList[1].q42_R2_Date_Not_Valid = false;
              }
            } else {
              // Handle cases where the date strings are not valid
            }
          }
        }
      });
    }


    setCarTCharRepeater([...carTCharRepeater]);

    return valid;
  };

  useEffect(() => {
    LoadData();
  }, []);

  async function LoadData() {
    try {
      if (pid > 0) {
        ToggleLoader(true);
        const response =
          await AdditionalTreatmentService.LoadCarTCharacteristics(pid);
        ToggleLoader(false);

       // console.log("LOADDATA", response.data);

        const cohortTreatmentModelList = response.data.cohortTreatmentModelList;

        const bispecificDates = cohortTreatmentModelList
          .map((entry) => new Date(entry.cohortIndexDate))
          .reduce((max, date) => (date > max ? date : max), new Date(0));

        const formattedDate = `${
          bispecificDates.getMonth() + 1
        }/${bispecificDates.getDate()}/${bispecificDates.getFullYear()}`;

        setBispecificDate(formattedDate);

        if (response?.status === HTTPResponse.OK) {
          const carTCharData = response.data;

          if (carTCharData) {
            setCarTCharData((prev) => ({
              ...prev,
              CartcharacteristicsId: carTCharData.cartcharacteristicsid,
              CohortTreatmentId: carTCharData.cohorttreatmentid,
              // q41_Apheresis_Date: carTCharData.apheresisDate,

              endOfFollowupDate: carTCharData.endOfFollowUpDate,

              carvyktiFollowupDate: carTCharData.carvyktiFollowupDate,
              abecmaFollowupDate: carTCharData.abecmaFollowupDate,
            }));
            if (
              carTCharData.cohortTreatmentModelList &&
              carTCharData.cohortTreatmentModelList.length !== 0
            ) {
                const repeaterData = carTCharData.cohortTreatmentModelList.map(
                (item) => {
                  
                  const carTCharacteristicsTypeList =
                    item.carTCharacteristicsTypeList.map((typeItem) => ({
                      carTTherapyId: typeItem.carTtherapyId,
                      carTtherapyName: typeItem.carTtherapyName,
                      q42_Therapy_Receive: typeItem.therapyReceived,
                      q42_Therpy_Initiation_Date:
                        typeItem.therapyInitiationDate || "",
                      q42_Therpy_Initiation_Radio:
                        typeItem.therapyInitiationDateUnk
                          ? 99
                          : !isStringEmpty(typeItem.therapyInitiationDate)
                          ? 1
                          : null,
                      q42_CohortTreatment_Unk: typeItem.cohortTreatmentUnk
                        ? 99
                        : null,

                      q42_Therpy_Initiation_Date_Empty: false,
                      q42_Therpy_Initiation_Radio_Empty: false,
                      q42_Therapy_Receive_Empty: false,

                      q42_R2_Date_Not_Valid: false,
                      q42_Therapy_Initiation_NotValid: false,
                      therapyReceivedEmpty: false,
                      q42_Therapy_Initiation_DateInvalid:false,
                      //q43B_No_Procedure_RcvdEmpty: false,
                    }));

                  const carTCharacteristicsReceivedList =
                    item.carTCharacteristicsReceivedList.map((typeItem) => ({
                      carTtherapyId: typeItem.carTtherapyId,
                      carTtherapyReceivedName: typeItem.carTtherapyReceivedName,
                      carTTherapyReceivedId: typeItem.carTTherapyReceivedId,
                      isChecked: typeItem.isChecked,
                      carTOtherTxt: typeItem.carTOtherTxt,
                      carTOtherTxtEmpty: false,
                      isCheckedEmpty: false,
                    }));

                  return {
                    cohortTreatmentId: item.cohortTreatmentID,
                    cohortTreatmentName: item.cohortTreatmentName,
                    cohortIndexDate: item.cohortIndexDate,
                    isEligible: item.isEligible,

                    //41
                    q41_Apheresis_Date: item.apheresisDate,
                    q41_Apheresis_Date_Empty: false,
                    q41_Apheresis_Date_NotValid: false,
                    q41_Apheresis_InvalidDate:false,

                    // 43
                    q43B_No_Procedure_Rcvd: item.procedureNotreceived
                      ? 1
                      : !isStringEmpty(item.procedureDate)
                      ? 2
                      : item.procedureDateUnk
                      ? 99
                      : null,
                    q43B_No_Procedure_RcvdEmpty: false,
                    q43B_Procedure_Date: item.procedureDate,
                    q43C_ProcedureType: item.procedureType,
                    q43C_ProcedureType_Txt: item.procedureTypeOth,
                    q43C_ProcedureTypeEmpty: false,
                    q43C_ProcedureType_TxtEmpty: false,
                    q43B_Procedure_DateEmpty: false,
                    q43_Date_Valid: false,
                    q43_Procedure_InvalidDate:false,

                    carTCharacteristicsTypeList: carTCharacteristicsTypeList,
                    carTCharacteristicsReceivedList:
                      carTCharacteristicsReceivedList,
                  };
                }
              );

              setCarTCharRepeater(repeaterData);
            }
          }
        } else if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }

  const SavePage = async (validate) => {
    try {
      ToggleLoader(true);
      let isValid = validate ? ValidateForm() : true;

      if (isValid) {
        setShowTopErrMsg(false);
        const CarTCharacteristicsModel = {
          patientid: pid,
          userId: GetLoggedInUserID(),
          isValidated: validate,
          IsAdmin: IsAdmin(),
          cartcharacteristicsid: carTCharData.CartcharacteristicsId,

          cohortTreatmentModelList: carTCharRepeater.map((item) => ({
            patientId: pid,
            userId: GetLoggedInUserID(),
            centerId: 0,
            cohortTreatmentID: item.cohortTreatmentId,
            cohortTreatmentName: item.cohortTreatmentName,
            ndmm: false,
            cohortIndexDate: item.cohortIndexDate,
            cohortIndexDateUnk: false,
            cohortTreatmentNameSuperscript: "",
            isEligible: item.isEligible,
            lineOfTherapy: null,
            cohorttreatmentUnk: null,
            procedureNotreceived: item.q43B_No_Procedure_Rcvd === 1,
            // procedureDate:
            //   item.q43B_No_Procedure_Rcvd === 2
            //     ? item.q43B_Procedure_Date
            //     : null,

                procedureDate: item.q43B_No_Procedure_Rcvd === 2 && item.q43B_Procedure_Date != "Invalid Date" && item.q43B_Procedure_Date
                ? item.q43B_Procedure_Date :
                "",

            procedureDateUnk: item.q43B_No_Procedure_Rcvd === 99,
            procedureType: item.q43C_ProcedureType,
            procedureTypeOth: item.q43C_ProcedureType_Txt,

            //41
            //apheresisDate: item.q41_Apheresis_Date,

            apheresisDate: item.q41_Apheresis_Date != "Invalid Date" && item.q41_Apheresis_Date
            ? item.q41_Apheresis_Date :
            "",

            carTCharacteristicsTypeList: item.carTCharacteristicsTypeList.map(
              (type) => ({
                CarTtherapyId: type.carTTherapyId,
                CarTtherapyName: type.carTtherapyName,
                TherapyReceived: type.q42_Therapy_Receive,


                // TherapyInitiationDate:
                //   type.q42_Therpy_Initiation_Radio === 1
                //     ? type.q42_Therpy_Initiation_Date
                //     : null,

                TherapyInitiationDate: type.q42_Therpy_Initiation_Radio === 1 &&  type.q42_Therpy_Initiation_Date != "Invalid Date" &&  type.q42_Therpy_Initiation_Date
                ?  type.q42_Therpy_Initiation_Date :
                "",

                TherapyInitiationDateUnk:
                  type.q42_Therpy_Initiation_Radio === 99,

                CohortTreatmentUnk:
                  type.q42_CohortTreatment_Unk == 99 ? true : false,
              })
            ),

            carTCharacteristicsReceivedList:
              item.carTCharacteristicsReceivedList.map((received) => ({
                CarTtherapyId: received.carTtherapyId,
                CarTtherapyReceivedName: received.carTtherapyReceivedName,
                IsChecked: received.isChecked,
                CarTTherapyReceivedId: received.carTTherapyReceivedId,
                CarTOtherTxt: received.carTOtherTxt,
              })),
          })),
        };

        if (pid > 0) {
          const response =
            await AdditionalTreatmentService.SaveCarTCharacteristics(
              CarTCharacteristicsModel
            );

          if (response.status === HTTPResponse.OK) {
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
            await LoadData();
          } else if (response.status === HTTPResponse.Unauthorized) {
            HandleSessionTimeout();
          } else {
            throw new Error("Failed to save data");
          }
        }

        return true;
      } else {
        setShowTopErrMsg(true);
        return false;
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
      return false;
    } finally {
      ToggleLoader(false);
    }
  };

    const displayCohortIndexDates = () => {
        const date1 = filteredList[0]?.cohortIndexDate;
        const date2 = filteredList[1]?.cohortIndexDate;

        if (date1 && date2) {
            return `${date1} and ${date2}`;
        } else if (date1) {
            return date1;
        } else if (date2) {
            return date2;
        } else {
            return ""; // Return an empty string or a default message if neither date is present
        }
    };

  const displayApheresisDate = () => {
    let carvyktiApheresisDate = "";
    let abecmaApheresisDate = "";

        if (carTCharRepeater.length > 0) {
      carvyktiApheresisDate = carTCharRepeater.find(
        (item) => item.cohortTreatmentId === 13
            )?.q41_Apheresis_Date || "";
      abecmaApheresisDate = carTCharRepeater.find(
        (item) => item.cohortTreatmentId === 14
      )?.q41_Apheresis_Date || "";
        }
  
    if (carvyktiApheresisDate && abecmaApheresisDate) {
      return `${carvyktiApheresisDate} and ${abecmaApheresisDate}`;
    } else if (carvyktiApheresisDate) {
      return carvyktiApheresisDate;
    } else if (abecmaApheresisDate) {
      return abecmaApheresisDate;
    } else {
        return "Date of apheresis";
    }
    };

    const displayNdmmEndOfDates = (cohortId) => {
        let date = "";
        if (cohortId === 13) {
            date = carTCharData.carvyktiFollowupDate || carTCharData.endOfFollowupDate || "Bispecifics/CAR-T end of follow up for Cilta-Cel";
        } else if (cohortId === 14) {
            date = carTCharData.abecmaFollowupDate || carTCharData.endOfFollowupDate || "Bispecifics/CAR-T end of follow up for Ide-Cel";
        }
        return date;
        //let date1 = null;
        //let date2 = null;

        //// Check `filteredList` and assign dates based on `cohorttreatmentId`
        //filteredList.forEach((item) => {
        //  if (item.cohorttreatmentId === 13) {
        //    date1 = carTCharData.carvyktiFollowupDate;
        //  } else if (item.cohorttreatmentId === 14) {
        //    date2 = carTCharData.abecmaFollowupDate;
        //  }
        //});

        //// Return dates based on which ones are set
        //if (date1 && date2) {
        //  return `${date1} and ${date2}`;
        //} else if (date1) {
        //  return date1;
        //} else if (date2) {
        //  return date2;
        //} else if (
        //  (!date1 || date1 === null || date1 === "") &&
        //  (!date2 || date2 === null || date2 === "") &&
        //  carTCharData.endOfFollowupDate && carTCharData.endOfFollowupDate !== ""
        //) {
        //  return carTCharData.endOfFollowupDate;
        //} else {
        //  return "[Bispecifics/CAR-T end of follow up for Cilta-Cel and/or Ide-Cel]";
        //}
    };
  

  const displayCohortIndexNames = () => {
    const date1 = filteredList[0]?.cohortTreatmentName;
    const date2 = filteredList[1]?.cohortTreatmentName;

    if (date1 && date2) {
      return `${date1} and ${date2}`;
    } else if (date1) {
      return date1;
    } else if (date2) {
      return date2;
    } else {
      return ""; // Return an empty string or a default message if neither date is present
    }
  };

  const sidebar = document.getElementById("mySidebar");
  const main = document.getElementById("main");

  const toggleNav = () => {
    if (isSideMenuActive === true) {
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
      setIsSideMenuActive(false);
    } else if (isSideMenuActive === false) {
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
      setIsSideMenuActive(true);
    }
  };

  useEffect(() => {
    const sidebar = document.getElementById("mySidebar");
    const main = document.getElementById("main");

    if (isSideMenuActive === true) {
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
    } else {
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
    }
  });

  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div>
          <SideBarMenu activePageId={SideMenuItems.CartCharacteristics} />
          <>
            <button
              className={isSideMenuActive ? "openbtn" : "openbtn active"}
              // onclick="toggleNav()"
              onClick={() => toggleNav()}
            >
              <i
                id="arrowNavigation"
                class="fa fa-arrow-left"
                aria-hidden="true"
              ></i>
            </button>
          </>
          <div className="col-pad-left" id="main">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>CAR-T Characteristics</h4>
              </div>
              {showTopErrMsg && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
              {/* Question 41 */}
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div className="question-answer">

                    <div className="sub-question" style={{ padding: "0px" }}>
                      <div
                        className="table-page"
                        style={{ paddingTop: "15px" }}
                      >
                        <div className="question question-weight">
                          <span style={{ fontWeight: "500" }}>Q41.</span>
                          <span
                            className="quest-text-pad"
                            style={{ fontWeight: "500" }}
                          >
                            Date of apheresis
                          </span>
                        </div>
                        <table border="1">
                          <thead>
                            <tr>
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                Treatment <br />
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                  }}
                                ></span>
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                Date of apheresis
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                  }}
                                ></span>
                              </th>
                            </tr>
                          </thead>

                          {carTCharRepeater && carTCharRepeater.length > 0
                            ? carTCharRepeater.map((data, idx) => (
                                <tbody key={idx}>
                                  <tr>
                                    <td rowSpan={2}>
                                      {data.cohortTreatmentName}
                                    </td>
                                    <td
                                      className="customNoContent"
                                      style={{
                                        border: "1px solid black",
                                        padding: "8px",
                                      }}
                                    >
                                      <div
                                        className="option-without-input"
                                        style={{
                                          display: "flex",
                                          alignItems: "flex-start",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <span
                                          className="radio-text-padding"
                                          style={{ marginLeft: "-5px" }}
                                        >
                                          {/* <DateControl
                                            ctrlId={`q41_Apheresis_Date-${idx}`}
                                            HandleDateChange={
                                              HandleRepeaterDateChange
                                            }
                                            date={data.q41_Apheresis_Date}
                                          /> */}

                                        <DatePickerComponent
                                            ctrlId={`q41_Apheresis_Date-${idx}`}
                                            HandleDateChange={HandleRepeaterDateChange}
                                            minDate={dayjs("01-01-1920")} 
                                            maxDate={dayjs()} 
                                            locale="en" 
                                            date={data.q41_Apheresis_Date}
                                          />
                                        </span>
                                      </div>

                                      <ErrorField
                                        show={data.q41_Apheresis_Date_Empty}
                                        message={CarTCharMsgs.q41_Date_Empty}
                                      />

                                      <ErrorField
                                        show={data.q41_Apheresis_InvalidDate}
                                        message={CommonError_Msgs.InvalidDate}
                                      />

                                      <ErrorField
                                        show={data.q41_Apheresis_Date_NotValid}
                                        message={CarTCharMsgs.q41_Date_NotValid}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              ))
                            : null}
                        </table>
                      </div>
                    </div>

                    <br />
                    <br />

                    {/* QUESTION 42 */}

                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span style={{ fontWeight: "500" }}>Q42.</span>
                        <span
                          className="quest-text-pad"
                          style={{ fontWeight: "500" }}
                        >
                          Provide additional information on therapies received
                          from the date of apheresis to the date of CAR-T
                          infusion with [
                        <span
                            style={{
                                color: PageDesignConstants.fontColor,
                            }}
                        >
                            {displayCohortIndexNames()}
                        </span>
                        ] (i.e., from [
                        <span
                            style={{
                                color: PageDesignConstants.fontColor,
                            }}
                        >
                            {" "}
                            {/* //Write seperate FUNCTION     */}
                            {displayApheresisDate()}
                        </span>
                        ] to{" "}
                        <span
                            style={{
                                color: PageDesignConstants.fontColor,
                            }}
                        >
                            {" "}
                            [{displayCohortIndexDates()}]){" "}
                          </span>
                          &nbsp;
                          <span
                            style={{
                              color: PageDesignConstants.fontColor,
                            }}
                          >
                            {""}
                          </span>
                        </span>
                      </div>

                      <div className="table-page">
                        <table border="1">
                          <thead>
                            <tr>
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                Treatment <br />
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                  }}
                                ></span>
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                Type of therapy <br />
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                  }}
                                ></span>
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                Was therapy received? <br />
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                  }}
                                ></span>
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                Date of therapy initiation <br />
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                  }}
                                ></span>
                              </th>
                              <th
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                  textAlign: "center",
                                }}
                              >
                                Type of therapy received <br />
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                  }}
                                ></span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {carTCharRepeater && carTCharRepeater.length > 0 ? (
                              carTCharRepeater.map((data, idx) => (
                                <>
                                  <tr key={idx}>
                                    <td rowSpan={2}>
                                      {data.cohortTreatmentName}
                                    </td>
                                    {data.carTCharacteristicsTypeList
                                      .slice(0, 1)
                                      .map((item, index) => (
                                        <>
                                          <td>
                                            <span key={index}>
                                              {item.carTtherapyName}
                                            </span>
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                marginTop: "5px",
                                                marginBottom: "5px",
                                              }}
                                            >
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    textAlign: "start",
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <input
                                                    type="radio"
                                                    id={`${idx}_${index}`}
                                                    name={`q42_Therapy_Receive${idx}${index}`}
                                                    value={1}
                                                    checked={
                                                      item.q42_Therapy_Receive ===
                                                      1
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span className="radio-text-padding">
                                                    Yes
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    textAlign: "start",
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <input
                                                    type="radio"
                                                    id={`${idx}_${index}`}
                                                    name={`q42_Therapy_Receive${idx}${index}`}
                                                    value={2}
                                                    checked={
                                                      item.q42_Therapy_Receive ===
                                                      2
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span className="radio-text-padding">
                                                    No
                                                  </span>
                                                </label>
                                              </div>

                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label
                                                  style={{
                                                    textAlign: "start",
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <input
                                                    type="radio"
                                                    id={`${idx}_${index}`}
                                                    name={`q42_Therapy_Receive${idx}${index}`}
                                                    value={99}
                                                    checked={
                                                      item.q42_Therapy_Receive ===
                                                      99
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span className="radio-text-padding">
                                                    Unknown
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <ErrorField
                                              show={
                                                item.q42_Therapy_Receive_Empty
                                              }
                                              message={
                                                CarTCharMsgs.carTEmpty_Msg
                                              }
                                            />
                                          </td>

                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                            }}
                                          >
                                            {item.q42_Therapy_Receive === 1 && (
                                              <>
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <input
                                                    type="radio"
                                                    id={`${idx}_${index}`}
                                                    name={`q42_Therpy_Initiation_Radio${idx}${index}`}
                                                    value={1}
                                                    checked={
                                                      item.q42_Therpy_Initiation_Radio ===
                                                      1
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      marginLeft: "-5px",
                                                    }}
                                                  >
                                                    {/* <DateControl
                                                      ctrlId={`q42_Therpy_Initiation_Date${idx}_${index}`}
                                                      HandleDateChange={
                                                        HandleRepeatDateChange
                                                      }
                                                      date={
                                                        item.q42_Therpy_Initiation_Date
                                                      }
                                                    /> */}
                                            <DatePickerComponent
                                            ctrlId={`q42_Therpy_Initiation_Date${idx}_${index}`}
                                            HandleDateChange={HandleRepeatDateChange}
                                            minDate={dayjs("01-01-1920")} 
                                            maxDate={dayjs()} 
                                            locale="en" 
                                            date={
                                              item.q42_Therpy_Initiation_Date
                                            }
                                          />
                                                  </span>
                                                </div>
                                                <div
                                                  className="option-without-input"
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                  }}
                                                >
                                                  <label
                                                    className="option-fbox"
                                                    style={{
                                                      textAlign: "left",
                                                      //marginLeft: "8px",
                                                    }}
                                                  >
                                                    <input
                                                      type="radio"
                                                      id={`${idx}_${index}`}
                                                      name={`q42_Therpy_Initiation_Radio${idx}${index}`}
                                                      value={99}
                                                      checked={
                                                        item.q42_Therpy_Initiation_Radio ===
                                                        99
                                                      }
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />

                                                    <span className="radio-text-padding">
                                                      Date of initiation unknown
                                                    </span>
                                                  </label>
                                                </div>
                                              </>
                                            )}
                                            <ErrorField
                                              show={
                                                item.q42_Therpy_Initiation_Radio_Empty
                                              }
                                              message={
                                                CarTCharMsgs.carTEmpty_Msg
                                              }
                                            />

                                            <ErrorField
                                              show={
                                                item.q42_Therapy_Initiation_DateInvalid
                                              }
                                              message={
                                               CommonError_Msgs.InvalidDate
                                              }
                                            />  

                                            <ErrorField
                                              show={
                                                item.q42_Therapy_Initiation_NotValid
                                              }
                                              message={
                                                CarTCharMsgs.q42_Date_Not_Valid_Bridge
                                              }
                                            />
                                            <ErrorField
                                              show={
                                                item.q42_Therpy_Initiation_Date_Empty
                                              }
                                              message={
                                                CarTCharMsgs.q42_Date_Not_Entered_Bridge
                                              }
                                            />
                                          </td>
                                        </>
                                      ))}

                                    <td
                                      style={{
                                        textAlign: "left",
                                        paddingInline: "8px",
                                      }}
                                    >
                                      {data.carTCharacteristicsTypeList[0]
                                        .q42_Therapy_Receive === 1 && (
                                        <>
                                          <div>
                                            {data.carTCharacteristicsReceivedList
                                              .filter(
                                                (item) =>
                                                  item.carTtherapyId === 1
                                              )
                                              .map((item, index) => (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                    marginBottom: "4px",
                                                  }}
                                                  key={index}
                                                >
                                                  <input
                                                    style={{
                                                      marginTop: "6px",
                                                    }}
                                                    type="checkbox"
                                                    id={`${idx}_${item.carTTherapyReceivedId}_${item.carTtherapyId}`}
                                                    name={`checkbox${idx}${item.carTTherapyReceivedId}`}
                                                    checked={item.isChecked}
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <div
                                                    style={{
                                                      marginLeft: "8px",
                                                    }}
                                                  >
                                                    <label
                                                      htmlFor={`${idx}_${item.carTTherapyReceivedId}_${item.carTtherapyId}`}
                                                    >
                                                      {
                                                        item.carTtherapyReceivedName
                                                      }
                                                    </label>

                                                    {/* Conditionally render an input text field for a specific checkbox based on ID */}
                                                    {item.carTTherapyReceivedId ===
                                                      27 && (
                                                      <textarea
                                                        type="text"
                                                        // className="input-dashdash"
                                                        id={`${idx}_${item.carTTherapyReceivedId}_${item.carTtherapyId}`}
                                                        name={`Checkboxtext${idx}${item.carTTherapyReceivedId}`}
                                                        value={
                                                          item.carTOtherTxt ||
                                                          ""
                                                        }
                                                        maxLength={300}
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                        style={{
                                                          display: "flex",
                                                          marginTop: "4px",
                                                        }}
                                                      />
                                                    )}
                                                    <ErrorField
                                                      show={
                                                        item.carTOtherTxtEmpty
                                                      }
                                                      message="Please enter text for Other option. "
                                                    />
                                                  </div>
                                                </div>
                                              ))}
                                          </div>

                                          {/* Place the "Unknown" radio button after the checkboxes */}
                                          {/* {carTCharRepeater.carTCharacteristicsTypeList &&
                                          carTCharRepeater
                                            .carTCharacteristicsTypeList
                                            .length > 0 ? ( */}
                                          <div style={{ marginTop: "8px" }}>
                                            <label
                                            // style={{ marginLeft: "8px" }}
                                            >
                                              <input
                                                type="radio"
                                                id={`${idx}_0`}
                                                name={`q42_CohortTreatment_Unk${idx}0`}
                                                value={99}
                                                checked={
                                                  data
                                                    .carTCharacteristicsTypeList[0]
                                                    .q42_CohortTreatment_Unk ===
                                                  99
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <span
                                                style={{ marginLeft: "8px" }}
                                              >
                                                {" "}
                                                Unknown
                                              </span>
                                            </label>
                                          </div>
                                          {/* ) : (
                                            <div style={{ marginTop: "8px" }}>
                                              <label
                                              // style={{ marginLeft: "8px" }}
                                              >
                                                <input
                                                  type="radio"
                                                  id={`${idx}_0`}
                                                  style={{ marginRight: "8px" }}
                                                  name={`q42_CohortTreatment_Unk${idx}0`}
                                                  value={99}
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                Unknown
                                              </label>
                                            </div>
                                          )} */}

                                          {/* Error message displayed below "Unknown" */}
                                          {data.carTCharacteristicsTypeList[0]
                                            .q42_Therapy_Receive === 1 && (
                                            <ErrorField
                                              show={
                                                data
                                                  .carTCharacteristicsTypeList[0]
                                                  .therapyReceivedEmpty
                                              }
                                              message="Please choose at least one of the provided options or “Unknown” "
                                            />
                                          )}
                                        </>
                                      )}
                                    </td>
                                  </tr>

                                  <tr>
                                    {data.carTCharacteristicsTypeList
                                      .slice(1, 2)
                                      .map((item, index) => (
                                        <>
                                          <td>
                                            <span key={index + 1}>
                                              {item.carTtherapyName}
                                            </span>
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                marginTop: "5px",
                                                marginBottom: "5px",
                                              }}
                                            >
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label>
                                                  <input
                                                    type="radio"
                                                    id={`${idx}_${index + 1}`}
                                                    name={`q42_Therapy_Receive${idx}${
                                                      index + 1
                                                    }`}
                                                    value={1}
                                                    checked={
                                                      item.q42_Therapy_Receive ===
                                                      1
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span className="radio-text-padding">
                                                    Yes
                                                  </span>
                                                </label>
                                              </div>
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label>
                                                  <input
                                                    type="radio"
                                                    id={`${idx}_${index + 1}`}
                                                    name={`q42_Therapy_Receive${idx}${
                                                      index + 1
                                                    }`}
                                                    value={2}
                                                    checked={
                                                      item.q42_Therapy_Receive ===
                                                      2
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span className="radio-text-padding">
                                                    No
                                                  </span>
                                                </label>
                                              </div>
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label>
                                                  <input
                                                    type="radio"
                                                    id={`${idx}_${index + 1}`}
                                                    name={`q42_Therapy_Receive${idx}${
                                                      index + 1
                                                    }`}
                                                    value={99}
                                                    checked={
                                                      item.q42_Therapy_Receive ===
                                                      99
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span className="radio-text-padding">
                                                    Unknown
                                                  </span>
                                                </label>
                                              </div>
                                              <ErrorField
                                                show={
                                                  item.q42_Therapy_Receive_Empty
                                                }
                                                message={
                                                  CarTCharMsgs.carTEmpty_Msg
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td
                                            style={{
                                              border: "1px solid black",
                                              padding: "8px",
                                            }}
                                          >
                                            {item.q42_Therapy_Receive === 1 && (
                                              <>
                                                <div
                                                  style={{
                                                    marginTop: "5px",
                                                    marginBottom: "5px",
                                                  }}
                                                >
                                                  <div
                                                    className="option-without-input"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <input
                                                      type="radio"
                                                      id={`${idx}_${index + 1}`}
                                                      name={`q42_Therpy_Initiation_Radio${idx}${
                                                        index + 1
                                                      }`}
                                                      value={1}
                                                      checked={
                                                        item.q42_Therpy_Initiation_Radio ===
                                                        1
                                                      }
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                    <span
                                                      className="radio-text-padding"
                                                      style={{
                                                        marginLeft: "-5px",
                                                      }}
                                                    >
                                                      {/* <DateControl
                                                        ctrlId={`q42_Therpy_Initiation_Date${idx}_${
                                                          index + 1
                                                        }`}
                                                        HandleDateChange={
                                                          HandleRepeatDateChange
                                                        }
                                                        date={
                                                          item.q42_Therpy_Initiation_Date
                                                        }
                                                      /> */}
                                                      <DatePickerComponent
                                                         ctrlId={`q42_Therpy_Initiation_Date${idx}_${
                                                          index + 1
                                                        }`}
                                                        HandleDateChange={HandleRepeatDateChange}
                                                        minDate={dayjs("01-01-1920")} 
                                                        maxDate={dayjs()} 
                                                        locale="en" 
                                                        date={
                                                          item.q42_Therpy_Initiation_Date
                                                        }
                                                      />
                                                    </span>
                                                  </div>
                                                  <div
                                                    className="option-without-input"
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "flex-start",
                                                    }}
                                                  >
                                                    <label
                                                      className="option-fbox"
                                                      style={{
                                                        textAlign: "left",
                                                        // marginLeft: "8px",
                                                      }}
                                                    >
                                                      <input
                                                        type="radio"
                                                        id={`${idx}_${
                                                          index + 1
                                                        }`}
                                                        name={`q42_Therpy_Initiation_Radio${idx}${
                                                          index + 1
                                                        }`}
                                                        value={99}
                                                        checked={
                                                          item.q42_Therpy_Initiation_Radio ===
                                                          99
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                      />

                                                      <span className="radio-text-padding">
                                                        Date of initiation
                                                        unknown
                                                      </span>
                                                    </label>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                            <ErrorField
                                              show={item.q42_R2_Date_Not_Valid}
                                              message={
                                                CarTCharMsgs.q42_Date_Not_Valid_Lympo
                                              }
                                            />

                                            <ErrorField
                                              show={
                                                item.q42_Therapy_Initiation_DateInvalid
                                              }
                                              message={
                                               CommonError_Msgs.InvalidDate
                                              }
                                            />  

                                            <ErrorField
                                              show={
                                                item.q42_Therapy_Initiation_NotValid
                                              }
                                              message={
                                                CarTCharMsgs.q42_Date_Not_Valid_Lympo
                                              }
                                            />

                                            <ErrorField
                                              show={
                                                item.q42_Therpy_Initiation_Radio_Empty
                                              }
                                              message={
                                                CarTCharMsgs.carTEmpty_Msg
                                              }
                                            />
                                            <ErrorField
                                              show={
                                                item.q42_Therpy_Initiation_Date_Empty
                                              }
                                              message={
                                                CarTCharMsgs.q42_Date_Not_Entered_Lympo
                                              }
                                            />
                                          </td>
                                        </>
                                      ))}

                                    <td
                                      style={{
                                        textAlign: "left",
                                        paddingInline: "8px",
                                      }}
                                    >
                                      {data.carTCharacteristicsTypeList[1]
                                        .q42_Therapy_Receive === 1 && (
                                        <>
                                          <div>
                                            {data.carTCharacteristicsReceivedList
                                              .filter(
                                                (item) =>
                                                  item.carTtherapyId === 2
                                              )
                                              .map((item, index) => (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                    marginBottom: "4px",
                                                  }}
                                                  key={index}
                                                >
                                                  <input
                                                    type="checkbox"
                                                    style={{ marginTop: "6px" }}
                                                    // id={`${idx}_${item.carTTherapyReceivedId}`}
                                                    id={`${idx}_${item.carTTherapyReceivedId}_${item.carTtherapyId}`}
                                                    name={`checkbox${idx}${item.carTTherapyReceivedId}`}
                                                    checked={item.isChecked}
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <div>
                                                    <label
                                                      htmlFor={`${idx}_${item.carTTherapyReceivedId}_${item.carTtherapyId}`}
                                                      style={{
                                                        marginLeft: "8px",
                                                      }}
                                                    >
                                                      {
                                                        item.carTtherapyReceivedName
                                                      }
                                                    </label>

                                                    {/* Conditionally render an input text field for a specific checkbox based on ID */}
                                                    {item.carTTherapyReceivedId ===
                                                      33 && (
                                                      <textarea
                                                        type="text"
                                                        // className="input-dashdash"
                                                        id={`${idx}_${item.carTTherapyReceivedId}_${item.carTtherapyId}`}
                                                        name={`Checkboxtext${idx}${item.carTTherapyReceivedId}`}
                                                        value={
                                                          item.carTOtherTxt ||
                                                          ""
                                                        }
                                                        onChange={
                                                          HandleRepeaterFieldChange
                                                        }
                                                        maxLength={300}
                                                        style={{
                                                          marginLeft: "8px",
                                                          marginTop: "4px",
                                                          display: "flex",
                                                        }}
                                                      />
                                                    )}
                                                    <ErrorField
                                                      show={
                                                        item.carTOtherTxtEmpty
                                                      }
                                                      message="Please enter text for Other option."
                                                    />
                                                  </div>
                                                </div>
                                              ))}
                                          </div>

                                          {/* Place the "Unknown" radio button after the checkboxes */}
                                          {/* Place the "Unknown" radio button after the checkboxes */}
                                          {/* {carTCharRepeater.carTCharacteristicsTypeList &&
                                          carTCharRepeater
                                            .carTCharacteristicsTypeList
                                            .length > 0 ? ( */}
                                          <div style={{ marginTop: "8px" }}>
                                            <label>
                                              <input
                                                type="radio"
                                                id={`${idx}_1`}
                                                name={`q42_CohortTreatment_Unk${idx}1`}
                                                value={99}
                                                checked={
                                                  data
                                                    .carTCharacteristicsTypeList[1]
                                                    .q42_CohortTreatment_Unk ===
                                                  99
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <span
                                                style={{ marginLeft: "8px" }}
                                              >
                                                Unknown
                                              </span>
                                            </label>
                                          </div>
                                          {/* // ) : (
                                          //   <div style={{ marginTop: "8px" }}>
                                          //     <label
                                          //     // style={{ marginLeft: "8px" }}
                                          //     >
                                          //       <input
                                          //         type="radio"
                                          //         style={{ marginRight: "8px" }}
                                          //         id={`${idx}_1`}
                                          //         name={`q42_CohortTreatment_Unk${idx}1`}
                                          //         value={99}
                                          //         onChange={
                                          //           HandleRepeaterFieldChange
                                          //         }
                                          //       />
                                          //       Unknown
                                          //     </label>
                                          //   </div>
                                          // )
                                              //  } */}

                                          {/* Error message displayed below "Unknown" */}
                                          {data.carTCharacteristicsTypeList[1]
                                            .q42_Therapy_Receive === 1 && (
                                            <ErrorField
                                              show={
                                                data
                                                  .carTCharacteristicsTypeList[1]
                                                  .therapyReceivedEmpty
                                              }
                                              message="Please choose at least one of the provided options or “Unknown”  "
                                            />
                                          )}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5">
                                  No treatment data available.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      {/* Question 43 */}
                      <div className="sub-question" style={{ padding: "0px" }}>
                        <div
                          className="table-page"
                          style={{ paddingTop: "15px" }}
                        >
                          <div className="question question-weight">
                            <span style={{ fontWeight: "500" }}>Q43.</span>
                            <span
                              className="quest-text-pad"
                              style={{ fontWeight: "500" }}
                            >
                              Provide additional information on therapies
                              received between the date of apheresis and the date of
                              CAR-T infusion with{" "}
                            {filteredList[0]?.cohortTreatmentName && (
                                <>
                                    <span
                                        style={{
                                            color: PageDesignConstants.fontColor,
                                        }}
                                    >
                                        {filteredList[0]?.cohortTreatmentName}
                                    </span>
                                    {" "}(from{" "}
                                    <span
                                        style={{
                                            color: PageDesignConstants.fontColor,
                                        }}
                                    >
                                        [{filteredList[0]?.cohortIndexDate ?? ""}]
                                    </span>
                                    {" "}to{" "}
                                    <span
                                        style={{
                                            color: PageDesignConstants.fontColor,
                                        }}
                                    >
                                        [{displayNdmmEndOfDates(filteredList[0]?.cohortTreatmentID)}]
                                    </span>
                                    )
                                </>)}
                                { filteredList[0]?.cohortTreatmentName && filteredList[1]?.cohortTreatmentName && 
                                    <span>{" "}and{" "}</span>    
                                }
                                {filteredList[1]?.cohortTreatmentName && (
                                <>
                                    <span
                                        style={{
                                            color: PageDesignConstants.fontColor,
                                        }}
                                    >
                                        {filteredList[1]?.cohortTreatmentName}
                                    </span>
                                    {" "}(from{" "}
                                    <span
                                        style={{
                                            color: PageDesignConstants.fontColor,
                                        }}
                                    >
                                        [{filteredList[1]?.cohortIndexDate ?? ""}]
                                    </span>
                                    {" "}to{" "}
                                    <span
                                        style={{
                                            color: PageDesignConstants.fontColor,
                                        }}
                                    >
                                        [{displayNdmmEndOfDates(filteredList[1]?.cohortTreatmentID)}]
                                    </span>
                                    )
                                </>)}
                            </span>
                          </div>
                          <table border="1">
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid black",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  Treatment <br />
                                  <span
                                    style={{
                                      color: PageDesignConstants.fontColor,
                                    }}
                                  ></span>
                                </th>
                                <th
                                  style={{
                                    border: "1px solid black",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  Date of procedure
                                  <span
                                    style={{
                                      color: PageDesignConstants.fontColor,
                                    }}
                                  ></span>
                                </th>
                                <th
                                  style={{
                                    border: "1px solid black",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  Type of procedure <br />
                                  <span
                                    style={{
                                      color: PageDesignConstants.fontColor,
                                    }}
                                  ></span>
                                </th>
                              </tr>
                            </thead>

                            {carTCharRepeater && carTCharRepeater.length > 0
                              ? carTCharRepeater.map((data, idx) => (
                                  <tbody key={idx}>
                                    <tr>
                                      <td rowSpan={2}>
                                        {data.cohortTreatmentName}
                                      </td>
                                      <td
                                        className="customNoContent"
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        <span>
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <label>
                                              <input
                                                type="radio"
                                                id={`${idx}`}
                                                name={`q43B_No_Procedure_Rcvd${idx}`}
                                                value={1}
                                                checked={
                                                  data.q43B_No_Procedure_Rcvd ===
                                                  1
                                                }
                                                onChange={
                                                  HandleRepeaterFieldChange
                                                }
                                              />
                                              <span className="radio-text-padding">
                                                No procedures were received
                                              </span>
                                            </label>
                                          </div>
                                          <div
                                            className="option-without-input"
                                            style={{
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          >
                                            <input
                                              type="radio"
                                              id={`${idx}`}
                                              name={`q43B_No_Procedure_Rcvd${idx}`}
                                              value={2}
                                              checked={
                                                data.q43B_No_Procedure_Rcvd ===
                                                2
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <span
                                              className="radio-text-padding"
                                              style={{ marginLeft: "-5px" }}
                                            >
                                              {/* <DateControl
                                                ctrlId={`q43B_Procedure_Date-${idx}`}
                                                HandleDateChange={
                                                  HandleRepeaterDateChange
                                                }
                                                date={data.q43B_Procedure_Date}
                                              /> */}
                                          <DatePickerComponent
                                           ctrlId={`q43B_Procedure_Date-${idx}`}
                                            HandleDateChange={HandleRepeaterDateChange}
                                            minDate={dayjs("01-01-1920")} 
                                            maxDate={dayjs()} 
                                            locale="en" 
                                            date={data.q43B_Procedure_Date}
                                          />
                                            </span>
                                          </div>
                                        </span>
                                        <div
                                          className="option-without-input"
                                          style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                        >
                                          <label>
                                            <input
                                              type="radio"
                                              id={`${idx}`}
                                              name={`q43B_No_Procedure_Rcvd${idx}`}
                                              value={99}
                                              checked={
                                                data.q43B_No_Procedure_Rcvd ===
                                                99
                                              }
                                              onChange={
                                                HandleRepeaterFieldChange
                                              }
                                            />
                                            <span className="radio-text-padding">
                                              Date of procedure unknown
                                            </span>
                                          </label>
                                        </div>
                                        <ErrorField
                                          show={
                                            data.q43B_No_Procedure_RcvdEmpty
                                          }
                                          message={CarTCharMsgs.carTEmpty_Msg}
                                        />
                                        <ErrorField
                                          show={data.q43B_Procedure_DateEmpty}
                                          message={CarTCharMsgs.q43_Date_Empty}
                                        />

                                      <ErrorField
                                        show={data.q43_Procedure_InvalidDate}
                                        message={CommonError_Msgs.InvalidDate}
                                      />

                                        <ErrorField
                                          show={data.q43_Date_Valid}
                                          message={
                                            CarTCharMsgs.q43_Date_Not_Valid
                                          }
                                        />
                                      </td>
                                      <td
                                        style={{
                                          border: "1px solid black",
                                          padding: "8px",
                                        }}
                                      >
                                        {(carTCharRepeater[idx]
                                          .q43B_No_Procedure_Rcvd == 2 ||
                                          carTCharRepeater[idx]
                                            .q43B_No_Procedure_Rcvd == 99) && (
                                          <div className="customNoContent">
                                            <div>
                                              <div
                                                className="option-without-input"
                                                style={{ display: "flex" }}
                                              >
                                                <label>
                                                  <input
                                                    type="radio"
                                                    id={`${idx}`}
                                                    name={`q43C_ProcedureType${idx}`}
                                                    value={1}
                                                    checked={
                                                      data.q43C_ProcedureType ===
                                                      1
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "15px",
                                                      marginLeft: "-5px",
                                                    }}
                                                  >
                                                    Radiography
                                                  </span>
                                                </label>
                                              </div>
                                              <div
                                                className="option-without-input"
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <label>
                                                  <input
                                                    type="radio"
                                                    id={`${idx}`}
                                                    name={`q43C_ProcedureType${idx}`}
                                                    value={2}
                                                    checked={
                                                      data.q43C_ProcedureType ===
                                                      2
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />

                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "15px",
                                                      marginLeft: "-5px",
                                                    }}
                                                  >
                                                    Electroencephalogram
                                                  </span>
                                                </label>
                                              </div>
                                              <div
                                                className="option-without-input"
                                                style={{ display: "flex" }}
                                              >
                                                <label>
                                                  <input
                                                    type="radio"
                                                    id={`${idx}`}
                                                    name={`q43C_ProcedureType${idx}`}
                                                    value={3}
                                                    checked={
                                                      data.q43C_ProcedureType ===
                                                      3
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "15px",
                                                      marginLeft: "-5px",
                                                    }}
                                                  >
                                                    Electrocardiogram
                                                  </span>
                                                </label>
                                              </div>
                                              <div
                                                className="option-without-input"
                                                style={{ display: "flex" }}
                                              >
                                                <label>
                                                  <input
                                                    type="radio"
                                                    id={`${idx}`}
                                                    name={`q43C_ProcedureType${idx}`}
                                                    value={4}
                                                    checked={
                                                      data.q43C_ProcedureType ===
                                                      4
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "15px",
                                                      marginLeft: "-5px",
                                                    }}
                                                  >
                                                    Lumbar puncture
                                                  </span>
                                                </label>
                                              </div>
                                              <div
                                                className="option-without-input"
                                                style={{ display: "flex" }}
                                              >
                                                <label>
                                                  <input
                                                    type="radio"
                                                    id={`${idx}`}
                                                    name={`q43C_ProcedureType${idx}`}
                                                    value={5}
                                                    checked={
                                                      data.q43C_ProcedureType ===
                                                      5
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "15px",
                                                      marginLeft: "-5px",
                                                    }}
                                                  >
                                                    Magnetic resonance imaging
                                                    (MRI)
                                                  </span>
                                                </label>
                                              </div>
                                              <div
                                                className="option-without-input"
                                                style={{ display: "flex" }}
                                              >
                                                <label>
                                                  <input
                                                    type="radio"
                                                    id={`${idx}`}
                                                    name={`q43C_ProcedureType${idx}`}
                                                    value={6}
                                                    checked={
                                                      data.q43C_ProcedureType ===
                                                      6
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "15px",
                                                      marginLeft: "-5px",
                                                    }}
                                                  >
                                                    Hemodialysis
                                                  </span>
                                                </label>
                                              </div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                }}
                                              >
                                                <input
                                                  type="radio"
                                                  style={{ marginTop: "11px" }}
                                                  id={`${idx}`}
                                                  name={`q43C_ProcedureType${idx}`}
                                                  value={7}
                                                  checked={
                                                    data.q43C_ProcedureType ===
                                                    7
                                                  }
                                                  onChange={
                                                    HandleRepeaterFieldChange
                                                  }
                                                />
                                                <label
                                                  style={{
                                                    marginLeft: "5px",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "10px",
                                                      marginLeft: "-5px",
                                                      paddingTop: "4px",
                                                    }}
                                                  >
                                                    Other, please specify &nbsp;
                                                    <textarea
                                                      style={{
                                                        width: "264px",
                                                        marginTop: "4px",
                                                        display: "flex",
                                                      }}
                                                      // className="input-dashdash"
                                                      id={`${idx}`}
                                                      name={`q43C_ProcedureType_Txt${idx}`}
                                                      type="text"
                                                      value={
                                                        data.q43C_ProcedureType_Txt ||
                                                        ""
                                                      }
                                                      maxLength={300}
                                                      max={100}
                                                      onChange={
                                                        HandleRepeaterFieldChange
                                                      }
                                                    />
                                                  </span>
                                                </label>
                                              </div>
                                              <div
                                                className="option-without-input"
                                                style={{ display: "flex" }}
                                              >
                                                <label>
                                                  <input
                                                    type="radio"
                                                    id={`${idx}`}
                                                    name={`q43C_ProcedureType${idx}`}
                                                    value={99}
                                                    checked={
                                                      data.q43C_ProcedureType ===
                                                      99
                                                    }
                                                    onChange={
                                                      HandleRepeaterFieldChange
                                                    }
                                                  />
                                                  <span
                                                    className="radio-text-padding"
                                                    style={{
                                                      paddingLeft: "15px",
                                                      marginLeft: "-5px",
                                                    }}
                                                  >
                                                    Unknown
                                                  </span>
                                                </label>
                                              </div>
                                            </div>
                                            <ErrorField
                                              show={
                                                data.q43C_ProcedureTypeEmpty
                                              }
                                              message={
                                                CarTCharMsgs.q43_Radio_Empty
                                              }
                                            />
                                            <ErrorField
                                              show={
                                                data.q43C_ProcedureType_TxtEmpty
                                              }
                                              message={
                                                "Please enter text for Other option."
                                              }
                                            />
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                ))
                              : null}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CarTCharacteristics;
