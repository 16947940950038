import { IsAdmin } from "../Helpers/Utilities";
import WebServiceWrapper from "./WebServiceWrapper";

export const ClinicalOutcomes = {
  SaveTreatmentResponseData,
  LoadTreatmentResponseData,
};

function SaveTreatmentResponseData(payload) {

  return WebServiceWrapper.Post("/clinicalOutcomes/SaveTmtResponseProgressionData", payload);
}

function LoadTreatmentResponseData(pid) {
    return WebServiceWrapper.Get(`/clinicalOutcomes/LoadTmtResponseProgressionData?pid=${pid}&isAdmin=${IsAdmin()}`);
}
