import { IsAdmin } from "../Helpers/Utilities";
import WebServiceWrapper from "./WebServiceWrapper";

export const AdditionalTreatmentService = {
  SaveTTCharacteristicData,
  LoadTTCharacteristicData,
  SaveNdmmCharacteristics,
  LoadNdmmsctcharacteristics,
  LoadCarTMapData,
  SaveCarTCharacteristics,
  LoadCarTCharacteristics
};

function SaveTTCharacteristicData(payload) {
console.log(payload)
  return WebServiceWrapper.Post(
    "/additionalTreatment/SaveTTCharacteristicsData",
    payload
  );
}

function LoadTTCharacteristicData(pid) {
  return WebServiceWrapper.Get(
      `/additionalTreatment/LoadTTCharacteristicsData?pid=${pid}&isAdmin=${IsAdmin()}`
  );
}

function SaveNdmmCharacteristics(payload) {
  return WebServiceWrapper.Post(
    "/additionalTreatment/SaveNdmmCharacteristics",
    payload
  );
}

function LoadNdmmsctcharacteristics(pid) {
  return WebServiceWrapper.Get(
      `/additionalTreatment/LoadNdmmsctcharacteristics?pid=${pid}&isAdmin=${IsAdmin()}`
  );
}

function LoadCarTMapData() {
  return WebServiceWrapper.Get(`/additionalTreatment/LoadCarTMapData`);
}


function SaveCarTCharacteristics(payload) {

  return WebServiceWrapper.Post(
    "/additionalTreatment/SaveCarTCharacteristics",
    payload
  );
}


function LoadCarTCharacteristics(pid) {

    return WebServiceWrapper.Get(`/additionalTreatment/LoadCarTCharacteristics?pid=${pid}&isAdmin=${IsAdmin()}`);
}

