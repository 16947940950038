import WebServiceWrapper from "./WebServiceWrapper";

const UserLogin = (payload) => {
  return WebServiceWrapper.Post("/userLogin/AuthenticateUser", payload);
};

const UserLogout = () => {
  return WebServiceWrapper.Get(`/userLogin/Logout`);
};

const AuthenticateJWTToken = () => {
  return WebServiceWrapper.Get(`/userLogin/AuthenticateJWTToken`);
};

function ResetPassword(payload) {
  return WebServiceWrapper.Post("/userLogin/ResetPassword", payload);
}

function AuthenticateUserForPasswordReset(gUID) {
  return WebServiceWrapper.Get(`/userLogin/AuthenticateUserForPasswordReset?gUID=${gUID}`);
}

function ForgotPassword(emailId) {
  return WebServiceWrapper.Get(`/userLogin/ForgotPassword?emailId=${emailId}`);
}
export const UserLoginService = {
  UserLogin,
  UserLogout,
  AuthenticateJWTToken,
  AuthenticateUserForPasswordReset,
  ResetPassword,
  ForgotPassword,
};
