import { useContext, useEffect, useState } from "react";
import ErrorField from "../../Components/ErrorField";
import PatientDetails from "../../Components/PatientInformation";
import { Center_Msgs, CenterInfo_Msgs, CommonError_Msgs } from "../../Helpers/HelperText";
import { CenterMenuItems, HTTPResponse, PageDesignConstants, Role, ToastMessages, ToastMessageType } from "../../Helpers/Enums";
import { Dropdown } from "react-bootstrap";
import CenterMenu from "../../Components/CenterMenu";
import { AppContext } from "../../Contexts/AppContextProvider";
import { CenterService } from "../../WebApiServices/Center.service";
import { AddLocalStorageData, AllowOnlyIntegers, GetLocalStorageData, GetLoggedInUserID, isStringEmpty, ValueInRange } from "../../Helpers/Utilities";
import { useNavigate } from "react-router-dom";

const CenterInformation = () => {

    const { ShowToast, ToggleLoader, HandleSessionTimeout } = useContext(AppContext);
    const [showTopErrMsg, setShowTopErrMsg] = useState(false);

    let userDetails = GetLocalStorageData("user")
    userDetails = userDetails && JSON.parse(userDetails);
    const roleId = userDetails?.userRole ?? 0;
    const isAdmin = roleId === Role.Admin ?? false;
    const logInUserCenterId = userDetails?.centerId ?? 0;
    const centerId = GetLocalStorageData("editCenterId") ?? 0;
    const [stateList, setStateList] = useState([]);
    const [selectedState, setSelectedState] = useState('Select State');
    // const [roundList, setRoundList] = useState([]);//Active round dropdown (contains current and next round if exists)
    // const [activeRound, setActiveRound] = useState('Select Round');
    const [confirmStatus, setConfirmStatus] = useState(null);
    const navigate = useNavigate();
    const iconStyle = {
        width: 45,
        cursor: "pointer",
    };
    const [centerDetails, setCenterDetails] = useState({
        name: "",
        // activeRoundId: null,
        activeRound: "",
        // locked: false,
        rbl_c1_practice: null,
        txt_c1_practice: "",
        chk_c2_urban: false,
        chk_c2_suburban: false,
        chk_c2_rural: false,
        rb_c2_na: false,
        rbl_c3_state: null,
        dd_c3_state: null,
        rbl_c4_receive_sct: null,
        rbl_c5_receive_cart: null,
        rbl_c6_hosp_beds: null,
        txt_c6_hosp_beds: "",
        rbl_c7_pyhsicians: null,
        txt_c7_pyhsicians: "",
        rbl_c8_total_mm_pts: null,
        txt_c8_total_mm_pts: "",
        rbl_c9_new_mm_pts: null,
        txt_c9_new_mm_pts: "",
        rbl_c10_crs_mgmt: null,
        chk_c10_tocil: false,
        chk_c10_dexa: false,
        txt_c10_tocil: "",
        txt_c10_dexa: "",
    });
    const InitialHardErrList = {
        name_empty: false,
        name_exists: false,
        c1_empty: false,
        c1_txt_empty: false,
        c2_empty: false,
        c3_empty: false,
        c3_radio_empty: false,
        c4_empty: false,
        c5_empty: false,
        c6_empty: false,
        c6_invalid: false,
        c7_empty: false,
        c7_invalid: false,
        c8_empty: false,
        c8_invalid: false,
        c9_empty: false,
        c9_invalid: false,
        c10_empty: false,
        c10_yes_empty: false,
        c10_txt_tocil_empty: false,
        c10_txt_dexa_empty: false,
        confirm_empty: false,
    }
    const [hardErrList, setHardErrList] = useState(InitialHardErrList);

    const HandleFieldChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case "rbl_c1_practice":
                {
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: Number(value),
                        txt_c1_practice: ""
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c1_empty: false,
                        c1_txt_empty: false
                    }));
                }
                break;
            case "txt_c1_practice":
                {
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: value,
                        rbl_c1_practice: 3
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c1_empty: false,
                        c1_txt_empty: false
                    }));
                }
                break;
            case "chk_c2_urban":
            case "chk_c2_suburban":
            case "chk_c2_rural":
                {
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: !prev[name],
                        rb_c2_na: false,
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c2_empty: false,
                    }));
                }
                break;
            case "rb_c2_na":
                {
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: !prev[name],
                        chk_c2_urban: false,
                        chk_c2_suburban: false,
                        chk_c2_rural: false,
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c2_empty: false,
                    }));
                }
                break;
            case "rbl_c3_state":
                {
                    setSelectedState('Select State');
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: Number(value),
                        dd_c3_state: null
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c3_radio_empty: false,
                        c3_empty: false
                    }));
                }
                break;

            case "rbl_c4_receive_sct":
                {
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: Number(value),
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c4_empty: false,
                    }));
                }
                break;
            case "rbl_c5_receive_cart":
                {
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: Number(value),
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c5_empty: false,
                    }));
                }
                break;
            case "rbl_c6_hosp_beds":
                {
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: Number(value),
                        txt_c6_hosp_beds: ""
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c6_empty: false,
                        c6_invalid: false,
                    }));
                }
                break;
            case "txt_c6_hosp_beds":
                {
                    if (!isStringEmpty(value) && !AllowOnlyIntegers(value)) {
                        break;
                    }
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: value,
                        rbl_c6_hosp_beds: 1,
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c6_empty: false,
                        c6_invalid: !ValueInRange(value, 0, 20000),
                    }));
                }
                break;
            case "rbl_c7_pyhsicians":
                {
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: Number(value),
                        txt_c7_pyhsicians: ""
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c7_empty: false,
                        c7_invalid: false,
                    }));
                }
                break;
            case "txt_c7_pyhsicians":
                {
                    if (!isStringEmpty(value) && !AllowOnlyIntegers(value)) {
                        break;
                    }
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: value,
                        rbl_c7_pyhsicians: 1,
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c7_empty: false,
                        c7_invalid: !ValueInRange(value, 0, 20000),
                    }));
                }
                break;
            case "rbl_c8_total_mm_pts":
                {
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: Number(value),
                        txt_c8_total_mm_pts: ""
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c8_empty: false,
                        c8_invalid: false,
                    }));
                }
                break;
            case "txt_c8_total_mm_pts":
                {
                    if (!isStringEmpty(value) && !AllowOnlyIntegers(value)) {
                        break;
                    }
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: value,
                        rbl_c8_total_mm_pts: 1,
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c8_empty: false,
                        c8_invalid: !ValueInRange(value, 0, 200000),
                    }));
                }
                break;
            case "rbl_c9_new_mm_pts":
                {
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: Number(value),
                        txt_c9_new_mm_pts: ""
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c9_empty: false,
                        c9_invalid: false,
                    }));
                }
                break;
            case "txt_c9_new_mm_pts":
                {
                    if (!isStringEmpty(value) && !AllowOnlyIntegers(value)) {
                        break;
                    }
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: value,
                        rbl_c9_new_mm_pts: 1,
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c9_empty: false,
                        c9_invalid: !ValueInRange(value, 0, 40000),
                    }));
                }
                break;
            case "rbl_c10_crs_mgmt":
                {
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: Number(value),

                        chk_c10_tocil: false,
                        chk_c10_dexa: false,
                        txt_c10_tocil: "",
                        txt_c10_dexa: "",
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c10_empty: false,
                        c10_yes_empty: false,
                        c10_txt_dexa_empty: false
                    }));
                }
                break;

            case "chk_c10_tocil":
                {
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: !prev[name],
                        txt_c10_tocil: "",
                        rbl_c10_crs_mgmt: 1
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c10_yes_empty: false,
                        c10_txt_tocil_empty: false,
                        // c10_empty: false,
                    }));
                }
                break;
            case "chk_c10_dexa":
                {
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: !prev[name],
                        txt_c10_dexa: "",
                        rbl_c10_crs_mgmt: 1
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c10_yes_empty: false,
                        c10_txt_dexa_empty: false,
                        // c10_empty: false,
                    }));
                }
                break;
            case "txt_c10_tocil":
                {
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: value,
                        chk_c10_tocil: true,
                        rbl_c10_crs_mgmt: 1
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c10_yes_empty: false,
                        c10_txt_tocil_empty: false,
                        // c10_empty: false,
                    }));
                }
                break;
            case "txt_c10_dexa":
                {
                    setCenterDetails((prev) => ({
                        ...prev,
                        [name]: value,
                        chk_c10_dexa: true,
                        rbl_c10_crs_mgmt: 1
                    }));
                    setHardErrList((prev) => ({
                        ...prev,
                        c10_yes_empty: false,
                        c10_txt_dexa_empty: false,
                        // c10_empty: false,
                    }));

                }
                break;

            case "confirmStatus":
                {
                    setConfirmStatus(Number(value));
                    setHardErrList((prev) => ({
                        ...prev,
                        confirm_empty: false,
                    }));
                }
                break;
            default: break;
        }
    }
    const HandleStateChange = (stateId, stateName) => {
        setSelectedState(stateName);
        setCenterDetails(prev => ({
            ...prev,
            dd_c3_state: stateId,
            rbl_c3_state: 1,
        }));
        setHardErrList(prev => ({
            ...prev,
            c3_empty: false,
            c3_radio_empty: false
        }))
    }
    const HandleSubmitClick = async () => {
        try {
            //setHardErrList(InitialHardErrList);

            if (Validate()) {
                setShowTopErrMsg(false);
                const payload = {
                    CenterId: centerId,
                    //Name: centerDetails.name,
                    // ActiveRoundId: centerDetails.activeRoundId,
                    UserId: GetLoggedInUserID(),
                    PracticeType: centerDetails.rbl_c1_practice,
                    PracticeTypeOther: centerDetails.txt_c1_practice,
                    CenterLocatedUrban: centerDetails.chk_c2_urban,
                    CenterLocatedSuburban: centerDetails.chk_c2_suburban,
                    CenterLocatedRural: centerDetails.chk_c2_rural,
                    CenterLocatedNotApplicable: centerDetails.rb_c2_na,
                    // centerStateNotApplicable: centerDetails.rbl_c3_state,
                    StateId: centerDetails.rbl_c3_state === 1 ? centerDetails.dd_c3_state : centerDetails.rbl_c3_state === 99 ? 99 : null,
                    PtReceiveSct: centerDetails.rbl_c4_receive_sct,
                    PtReceiveCartTreatment: centerDetails.rbl_c5_receive_cart,
                    NumberHospitalBeds: centerDetails.txt_c6_hosp_beds,
                    HospitalbedsUnkNA: centerDetails.rbl_c6_hosp_beds,// === 99,
                    NumberPhysicians: centerDetails.txt_c7_pyhsicians,
                    PhysiciansUnkNA: centerDetails.rbl_c7_pyhsicians,// === 99,
                    NumberMmpatientsTotal: centerDetails.txt_c8_total_mm_pts,
                    MmpatientsTotalUnkNA: centerDetails.rbl_c8_total_mm_pts,// === 99,
                    NumberMmpatientsNew: centerDetails.txt_c9_new_mm_pts,
                    MmpatientsNewUnkNA: centerDetails.rbl_c9_new_mm_pts,// === 99
                    centerCrs: centerDetails.rbl_c10_crs_mgmt,
                    CenterTocilizumab: centerDetails.rbl_c10_crs_mgmt === 1 && centerDetails.chk_c10_tocil ? centerDetails.txt_c10_tocil : "",
                    CenterDexamethasone: centerDetails.rbl_c10_crs_mgmt === 1 && centerDetails.chk_c10_dexa ? centerDetails.txt_c10_dexa : "",
                }

                ToggleLoader(true);
                const response = await CenterService.SaveCenterInfo(payload);
                ToggleLoader(false);

                if (response?.status !== HTTPResponse.OK) {
                    if (response?.status === HTTPResponse.Unauthorized)
                        HandleSessionTimeout();
                    // else if (response?.status === HTTPResponse.Conflict) {
                    //     setHardErrList((prev) => ({
                    //         ...prev,
                    //         name_exists: true,
                    //     }))
                    //     setShowTopErrMsg(true);
                    // }
                    else {
                        throw response.error;
                    }
                } else {
                    await LoadData();
                    // const userArr = { ...userDetails, isCenterLocked: true  };
                    // AddLocalStorageData("user", JSON.stringify(userArr));
                    ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
                }
            } else {
                setShowTopErrMsg(true);
            }
        }
        catch (error) {
            ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed)
        }
    };
    const Validate = () => {
        let result = true;

        if (isStringEmpty(centerDetails.name)) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                name_empty: true,
            }))
        }
        if (centerDetails.rbl_c1_practice == null) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                c1_empty: true,
            }))
        } else if (centerDetails.rbl_c1_practice === 3 && isStringEmpty(centerDetails.txt_c1_practice)) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                c1_txt_empty: true,
            }))
        }
        if (!centerDetails.chk_c2_urban && !centerDetails.chk_c2_suburban && !centerDetails.chk_c2_rural && !centerDetails.rb_c2_na) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                c2_empty: true,
            }))
        }
        if (centerDetails.rbl_c3_state == null) {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                c3_radio_empty: true
            }))
        }
        else if (centerDetails.rbl_c3_state == 1 && centerDetails.dd_c3_state == null) {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                c3_empty: true
            }))
        }
        if (centerDetails.rbl_c4_receive_sct == null) {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                c4_empty: true
            }))
        }
        if (centerDetails.rbl_c5_receive_cart == null) {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                c5_empty: true
            }))
        }
        if (centerDetails.rbl_c6_hosp_beds == null) {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                c6_empty: true
            }))
        } else if (centerDetails.rbl_c6_hosp_beds === 1) {
            if (isStringEmpty(String(centerDetails.txt_c6_hosp_beds))) {
                result = false;
                setHardErrList((prev) => ({
                    ...prev,
                    c6_empty: true,
                }))
            } else if (hardErrList.c6_invalid) {
                result = false;
            }
        }
        if (centerDetails.rbl_c7_pyhsicians == null) {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                c7_empty: true
            }))
        } else if (centerDetails.rbl_c7_pyhsicians === 1) {
            if (isStringEmpty(String(centerDetails.txt_c7_pyhsicians))) {
                result = false;
                setHardErrList((prev) => ({
                    ...prev,
                    c7_empty: true,
                }))
            } else if (hardErrList.c7_invalid) {
                result = false;
            }
        }
        if (centerDetails.rbl_c8_total_mm_pts == null) {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                c8_empty: true
            }))
        } else if (centerDetails.rbl_c8_total_mm_pts === 1) {
            if (isStringEmpty(String(centerDetails.txt_c8_total_mm_pts))) {
                result = false;
                setHardErrList((prev) => ({
                    ...prev,
                    c8_empty: true,
                }))
            } else if (hardErrList.c8_invalid) {
                result = false;
            }
        }
        if (centerDetails.rbl_c9_new_mm_pts == null) {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                c9_empty: true
            }))
        } else if (centerDetails.rbl_c9_new_mm_pts === 1) {
            if (isStringEmpty(String(centerDetails.txt_c9_new_mm_pts))) {
                result = false;
                setHardErrList((prev) => ({
                    ...prev,
                    c9_empty: true,
                }))
            } else if (hardErrList.c9_invalid) {
                result = false;
            }
        }
        if (centerDetails.rbl_c10_crs_mgmt == null) {
            result = false
            setHardErrList((prev) => ({
                ...prev,
                c10_empty: true
            }))
        } else if (centerDetails.rbl_c10_crs_mgmt === 1) {
            if (!centerDetails.chk_c10_tocil && !centerDetails.chk_c10_dexa) {
                result = false;
                setHardErrList((prev) => ({
                    ...prev,
                    c10_yes_empty: true,
                }))
            } else {
                if (centerDetails.chk_c10_tocil && isStringEmpty(centerDetails.txt_c10_tocil)) {
                    result = false;
                    setHardErrList((prev) => ({
                        ...prev,
                        c10_txt_tocil_empty: true,
                    }))
                }
                if (centerDetails.chk_c10_dexa && isStringEmpty(centerDetails.txt_c10_dexa)) {
                    result = false;
                    setHardErrList((prev) => ({
                        ...prev,
                        c10_txt_dexa_empty: true,
                    }))
                }
            }
        }

        if (confirmStatus == null) {
            result = false;
            setHardErrList((prev) => ({
                ...prev,
                confirm_empty: true,
            }))
        }
        return result;
    }
    useEffect(() => {
        async function FetchData() {
            if (!isNaN(centerId) && Number(centerId) !== 0) {

                await LoadData();
            }
        }
        FetchData()
    }, [])
    async function LoadData() {
        try {
            ToggleLoader(true);
            const response = await CenterService.GetCenterInfo(centerId);
            ToggleLoader(false);
            if (response?.status === HTTPResponse.OK) {
                const { data } = response;
                if (data != null) {
                    setCenterDetails(prev => ({
                        ...prev,
                        name: data.centerName,
                        //activeRoundId: data.activeRoundId,
                        activeRound: data.activeRound,
                        locked: data.centerLocked ?? false,

                        rbl_c1_practice: data.practiceType,
                        txt_c1_practice: data.practiceTypeOther,
                        chk_c2_urban: data.centerLocatedUrban,
                        chk_c2_suburban: data.centerLocatedSuburban,
                        chk_c2_rural: data.centerLocatedRural,
                        rb_c2_na: data.centerLocatedNotApplicable,
                        // rbl_c3_state: data.centerStateNotApplicable,
                        rbl_c3_state: data.stateId !== null ? (data.stateId !== 99 ? 1 : data.stateId) : null,
                        dd_c3_state: data.stateId,
                        rbl_c4_receive_sct: data.ptReceiveSct,
                        rbl_c5_receive_cart: data.ptReceiveCartTreatment,
                        txt_c6_hosp_beds: data.numberHospitalBeds ?? "",
                        rbl_c6_hosp_beds: data.hospitalbedsUnkNA,// ? data.hospitalbedsUnkNA : !isStringEmpty(data.numberHospitalBeds) ? 1 : null,
                        txt_c7_pyhsicians: data.numberPhysicians ?? "",
                        rbl_c7_pyhsicians: data.physiciansUnkNA,// ? data.physiciansUnkNA : !isStringEmpty(data.numberPhysicians) ? 1 : null,
                        txt_c8_total_mm_pts: data.numberMmpatientsTotal ?? "",
                        rbl_c8_total_mm_pts: data.mmpatientsTotalUnkNA,// ? data.mmpatientsTotalUnkNA : !isStringEmpty(data.numberMmpatientsTotal) ? 1 : null,
                        txt_c9_new_mm_pts: data.numberMmpatientsNew ?? "",
                        rbl_c9_new_mm_pts: data.mmpatientsNewUnkNA,// ? data.mmpatientsNewUnkNA : !isStringEmpty(data.numberMmpatientsNew) ? 1 : null,
                        rbl_c10_crs_mgmt: data.centerCrs,
                        chk_c10_tocil: !isStringEmpty(data.centerTocilizumab),
                        chk_c10_dexa: !isStringEmpty(data.centerDexamethasone),
                        txt_c10_tocil: data.centerTocilizumab,
                        txt_c10_dexa: data.centerDexamethasone,
                    }));
                    const USStates = data.stateList;
                    setStateList(USStates);
                    const selectedStateObj = USStates.find(s => s.stateId === data.stateId);
                    if (selectedStateObj) {
                        setSelectedState(selectedStateObj.stateName);
                    }
                    //const roundList = data.roundList;
                    //setRoundList(roundList);
                    // const selectedRoundObj = roundList.find(s => s.roundId === data.activeRoundId);
                    // if (selectedRoundObj) {
                    //     setActiveRound(selectedRoundObj.roundName);
                    // }
                }
            } else {
                if (response?.status === HTTPResponse.Unauthorized)
                    HandleSessionTimeout();
                else
                    throw response.error;
            }
        } catch (er) {
            ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
        }
    }
    return (
        <>
            <section className="survey-patient-details-section">
                <PatientDetails />
            </section>
            <section className="main-survey-section">
                <div className="row">
                    {/* <div className="col-md-9 col-pad-left"> */}
                    <div className="survey-section-content">
                        {isAdmin && <CenterMenu activeTab={CenterMenuItems.CenterInfo} />}
                        <div className="survey-question-section center-info-padding">
                            {showTopErrMsg &&
                                <div className="clean-error ">
                                    <ErrorField show={showTopErrMsg} message={CommonError_Msgs.Hard_Empty} />
                                </div>
                            }
                            {!showTopErrMsg && centerId != 0 && centerDetails.locked &&
                                (<div className="survey-section-content-message">
                                    <p>
                                        {Center_Msgs.CENTER_LOCKED}
                                    </p>
                                </div>)
                            }
                            <div className="survey-question-content center-info ">
                                <div className="mb-3">
                                    {isAdmin && (
                                        <div className="row align-items-center mb-3">
                                            <img className="col-md-2 col-lg-2"
                                                src="../Assets/images/back-icon.png"
                                                alt="Back"
                                                style={iconStyle}
                                                onClick={() => { navigate("/ManageCenters") }}
                                                title="Back"
                                            />
                                            <div className="col-md-10 col-lg-10">
                                                <b>All Centers</b>
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <span className="col-md-2 col-lg-2"><b>Center ID: {centerId}</b></span>

                                        <div className="col-md-7 col-lg-7 text-wrap">
                                            <span><b>Center Name: {centerDetails.name}</b></span>
                                            {/* <input
                                                id="centerName"
                                                name="name"
                                                className="input-dash"//form-control common-text-input                                               
                                                type="text"
                                                placeholder="Center"
                                                value={centerDetails.name}
                                                onChange={HandleFieldChange}
                                                maxLength={100}
                                                disabled={!isAdmin}
                                            />
                                            <ErrorField show={hardErrList.name_empty} message={Center_Msgs.NAME_EMPTY} style={{ paddingLeft: '0px' }} />
                                            <ErrorField show={hardErrList.name_exists} message={Center_Msgs.NAME_EXISTS} style={{ paddingLeft: '0px' }} /> */}
                                        </div>
                                        <div className="col-md-3 col-lg-3">
                                            <span><b>Active Round: {centerDetails.activeRound}</b></span>
                                            {/*<span><b>Active Round: </b></span>
                                             <Dropdown className='custom-dropdown-caret' >
                                                <Dropdown.Toggle variant="" id="dropdown-basic" className="w150 custom-dropdown-button"
                                                    disabled={!isAdmin}>
                                                    {activeRound}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="custom-dropdown-menu" >
                                                    {roundList.map((r) => (
                                                        <Dropdown.Item
                                                            as="button"
                                                            name="pt_state"
                                                            key={r.roundId}
                                                            value={r.roundName}
                                                            className={`custom-dropdown-item ${r.roundName === activeRound && 'custom-dropdown-item-selected'}`}
                                                            onClick={() => HandleRoundChange(r.roundId, r.roundName)}
                                                        >
                                                            {r.roundName}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>   */}
                                        </div>
                                    </div>
                                </div>
                                <div style={PageDesignConstants.PageDescriptionStyle}>
                                    <i>
                                        We would like to understand more about your medical center.
                                        Please complete the following table once for each round of data abstraction.
                                    </i>
                                </div>
                                <div className="question-answer " disabled={centerDetails.locked || logInUserCenterId != centerId}>
                                    <div className="question-bot-sapce">
                                        <div className="question question-weight">
                                            <span>C1.</span>
                                            <span className="quest-text-pad">
                                                Practice type?
                                            </span>
                                        </div>
                                        <div className=" double-dig-answer">
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c1_practice_1"
                                                        name="rbl_c1_practice"
                                                        value={1}
                                                        checked={centerDetails.rbl_c1_practice === 1}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Academic
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c1_practice_2"
                                                        name="rbl_c1_practice"
                                                        value={2}
                                                        checked={centerDetails.rbl_c1_practice === 2}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Community
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c1_practice_3"
                                                        name="rbl_c1_practice"
                                                        value={3}
                                                        checked={centerDetails.rbl_c1_practice === 3}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Other, please specify:{" "}
                                                    </span>
                                                    <br />
                                                    <span style={{ paddingLeft: '30px' }}>
                                                        <textarea
                                                            type="text"
                                                            name="txt_c1_practice"
                                                            className="input-dash"
                                                            value={centerDetails.txt_c1_practice}
                                                            onChange={HandleFieldChange}
                                                            maxLength={300}
                                                        />
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <ErrorField show={hardErrList.c1_empty} message={CenterInfo_Msgs.C1} />
                                        <ErrorField show={hardErrList.c1_txt_empty} message={CenterInfo_Msgs.C1Txt} />
                                    </div>
                                    <div className="question-bot-sapce">
                                        <div className="question question-weight">
                                            <span>C2.</span>
                                            <span className="quest-text-pad">
                                                Setting that medical center primarily located?{" "}
                                                <i>Select all that apply.</i>
                                            </span>
                                        </div>
                                        <div className=" double-dig-answer">
                                            <div>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        id="chk_c2_urban"
                                                        name="chk_c2_urban"
                                                        checked={centerDetails.chk_c2_urban}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Urban
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        id="chk_c2_suburban"
                                                        name="chk_c2_suburban"
                                                        checked={centerDetails.chk_c2_suburban}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Suburban
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        id="chk_c2_rural"
                                                        name="chk_c2_rural"
                                                        checked={centerDetails.chk_c2_rural}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Rural
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rb_c2_na"
                                                        name="rb_c2_na"
                                                        checked={centerDetails.rb_c2_na}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Not applicable
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <ErrorField show={hardErrList.c2_empty} message={CenterInfo_Msgs.C2} />
                                    </div>
                                    <div className="question-bot-sapce ">
                                        <div className="question question-weight">
                                            <span>C3.</span>
                                            <span className="quest-text-pad">
                                                State that medical center primarily located?
                                            </span>
                                        </div>
                                        <div className=" double-dig-answer">
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c3_state_1"
                                                        name="rbl_c3_state"
                                                        value={1}
                                                        checked={centerDetails.rbl_c3_state === 1}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        <Dropdown className='custom-dropdown-caret' >
                                                            <Dropdown.Toggle variant="" id="dropdown-basic" className="w150 custom-dropdown-button" >
                                                                {selectedState}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="custom-dropdown-menu" >
                                                                {stateList.map((s) => (
                                                                    <Dropdown.Item
                                                                        as="button"
                                                                        name="pt_state"
                                                                        key={s.stateId}
                                                                        value={s.stateName}
                                                                        className={`custom-dropdown-item ${s.stateName === selectedState && 'custom-dropdown-item-selected'}`}
                                                                        onClick={() => HandleStateChange(s.stateId, s.stateName)}
                                                                    >
                                                                        {s.stateName}
                                                                    </Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c3_state_99"
                                                        name="rbl_c3_state"
                                                        value={99}
                                                        checked={centerDetails.rbl_c3_state === 99}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Not applicable
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <ErrorField show={hardErrList.c3_empty} message={CenterInfo_Msgs.C3} />
                                        <ErrorField show={hardErrList.c3_radio_empty} message={CenterInfo_Msgs.C1} />
                                    </div>
                                    <div className="question-bot-sapce">
                                        <div className="question question-weight">
                                            <span>C4.</span>
                                            <span className="quest-text-pad">
                                                Can patients with MM receive autologous or allogeneic stem cell transplants (SCTs) at your medical center?
                                            </span>
                                        </div>
                                        <div className=" double-dig-answer">
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c4_receive_sct_1"
                                                        name="rbl_c4_receive_sct"
                                                        value={1}
                                                        checked={centerDetails.rbl_c4_receive_sct === 1}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Yes
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c4_receive_sct_0"
                                                        name="rbl_c4_receive_sct"
                                                        value={0}
                                                        checked={centerDetails.rbl_c4_receive_sct === 0}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        No
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c4_receive_sct_2"
                                                        name="rbl_c4_receive_sct"
                                                        value={2}
                                                        checked={centerDetails.rbl_c4_receive_sct === 2}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Not applicable
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <ErrorField show={hardErrList.c4_empty} message={CenterInfo_Msgs.C4} />
                                    </div>
                                    <div className="question-bot-sapce">
                                        <div className="question question-weight">
                                            <span>C5.</span>
                                            <span className="quest-text-pad">
                                                Can patients with MM receive CAR-T treatment at your medical center?
                                            </span>
                                        </div>
                                        <div className=" double-dig-answer">
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c5_receive_cart_1"
                                                        name="rbl_c5_receive_cart"
                                                        value={1}
                                                        checked={centerDetails.rbl_c5_receive_cart === 1}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Yes
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c5_receive_cart_0"
                                                        name="rbl_c5_receive_cart"
                                                        value={0}
                                                        checked={centerDetails.rbl_c5_receive_cart === 0}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        No
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c5_receive_cart_2"
                                                        name="rbl_c5_receive_cart"
                                                        value={2}
                                                        checked={centerDetails.rbl_c5_receive_cart === 2}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Not applicable
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <ErrorField show={hardErrList.c5_empty} message={CenterInfo_Msgs.C5} />
                                    </div>
                                    <div className="question-bot-sapce">
                                        <div className="question question-weight">
                                            <span>C6.</span>
                                            <span className="quest-text-pad">
                                                Approximate number of hospital beds at center?
                                            </span>
                                        </div>
                                        <div className=" double-dig-answer">
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c6_hosp_beds_1"
                                                        name="rbl_c6_hosp_beds"
                                                        value={1}
                                                        checked={centerDetails.rbl_c6_hosp_beds === 1}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        <input
                                                            type="text"
                                                            name="txt_c6_hosp_beds"
                                                            className="sub-input-dash"
                                                            value={centerDetails.txt_c6_hosp_beds}
                                                            onChange={HandleFieldChange}
                                                        // maxLength={100}
                                                        />{" "}
                                                        beds
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c6_hosp_beds_99"
                                                        name="rbl_c6_hosp_beds"
                                                        value={99}
                                                        checked={centerDetails.rbl_c6_hosp_beds === 99}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">Unknown</span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c6_hosp_beds_2"
                                                        name="rbl_c6_hosp_beds"
                                                        value={2}
                                                        checked={centerDetails.rbl_c6_hosp_beds === 2}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Not applicable
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <ErrorField show={hardErrList.c6_empty || hardErrList.c6_invalid} message={CenterInfo_Msgs.C6} />
                                    </div>
                                    <div className="question-bot-sapce">
                                        <div className="question question-weight">
                                            <span>C7.</span>
                                            <span className="quest-text-pad">
                                                Approximate number of practicing MM physicians at center?
                                            </span>
                                        </div>
                                        <div className=" double-dig-answer">
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c7_pyhsicians_1"
                                                        name="rbl_c7_pyhsicians"
                                                        value={1}
                                                        checked={centerDetails.rbl_c7_pyhsicians === 1}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        <input
                                                            type="text"
                                                            name="txt_c7_pyhsicians"
                                                            className="sub-input-dash"
                                                            value={centerDetails.txt_c7_pyhsicians}
                                                            onChange={HandleFieldChange}
                                                        // maxLength={100}
                                                        />{" "}
                                                        physicians
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c7_pyhsicians_99"
                                                        name="rbl_c7_pyhsicians"
                                                        value={99}
                                                        checked={centerDetails.rbl_c7_pyhsicians === 99}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">Unknown</span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c7_pyhsicians_2"
                                                        name="rbl_c7_pyhsicians"
                                                        value={2}
                                                        checked={centerDetails.rbl_c7_pyhsicians === 2}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Not applicable
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <ErrorField show={hardErrList.c7_empty || hardErrList.c7_invalid} message={CenterInfo_Msgs.C7} />
                                    </div>
                                    <div className="question-bot-sapce">
                                        <div className="question question-weight">
                                            <span>C8.</span>
                                            <span className="quest-text-pad">
                                                Number of total MM patients per year that are treated at your center?
                                            </span>
                                        </div>
                                        <div className=" double-dig-answer">
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c8_total_mm_pts_1"
                                                        name="rbl_c8_total_mm_pts"
                                                        value={1}
                                                        checked={centerDetails.rbl_c8_total_mm_pts === 1}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        <input
                                                            type="text"
                                                            name="txt_c8_total_mm_pts"
                                                            className="sub-input-dash"
                                                            value={centerDetails.txt_c8_total_mm_pts}
                                                            onChange={HandleFieldChange}
                                                        // maxLength={100}
                                                        />{" "}
                                                        patients/year
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c8_total_mm_pts_99"
                                                        name="rbl_c8_total_mm_pts"
                                                        value={99}
                                                        checked={centerDetails.rbl_c8_total_mm_pts === 99}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">Unknown</span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c8_total_mm_pts_2"
                                                        name="rbl_c8_total_mm_pts"
                                                        value={2}
                                                        checked={centerDetails.rbl_c8_total_mm_pts === 2}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Not applicable
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <ErrorField show={hardErrList.c8_empty || hardErrList.c8_invalid} message={CenterInfo_Msgs.C8} />
                                    </div>
                                    <div className="question-bot-sapce">
                                        <div className="question question-weight">
                                            <span>C9.</span>
                                            <span className="quest-text-pad">
                                                Number of new MM patients per year that are treated at your center?
                                            </span>
                                        </div>
                                        <div className=" double-dig-answer">
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c9_new_mm_pts_1"
                                                        name="rbl_c9_new_mm_pts"
                                                        value={1}
                                                        checked={centerDetails.rbl_c9_new_mm_pts === 1}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        <input
                                                            type="text"
                                                            name="txt_c9_new_mm_pts"
                                                            className="sub-input-dash"
                                                            value={centerDetails.txt_c9_new_mm_pts}
                                                            onChange={HandleFieldChange}
                                                        // maxLength={100}
                                                        />{" "}
                                                        patients/year
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c9_new_mm_pts_99"
                                                        name="rbl_c9_new_mm_pts"
                                                        value={99}
                                                        checked={centerDetails.rbl_c9_new_mm_pts === 99}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">Unknown</span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c9_new_mm_pts_2"
                                                        name="rbl_c9_new_mm_pts"
                                                        value={2}
                                                        checked={centerDetails.rbl_c9_new_mm_pts === 2}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Not applicable
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <ErrorField show={hardErrList.c9_empty || hardErrList.c9_invalid} message={CenterInfo_Msgs.C9} />
                                    </div>
                                    <div className="question-bot-sapce">
                                        <div className="question question-weight">
                                            <span>C10.</span>
                                            <span className="quest-text-pad">
                                                Does your center use prophylactic tocilizumab or dexamethasone for CRS management?
                                                If yes, please describe how they’re used, including the timing of using,
                                                the treatment, and dosing.
                                            </span>
                                        </div>
                                        <div className=" double-dig-answer">
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c10_crs_mgmt_1"
                                                        name="rbl_c10_crs_mgmt"
                                                        value={1}
                                                        checked={centerDetails.rbl_c10_crs_mgmt === 1}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Yes
                                                    </span>
                                                </label>
                                                <div className=" double-dig-answer">
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="chk_c10_tocil"
                                                                name="chk_c10_tocil"
                                                                checked={centerDetails.chk_c10_tocil}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Tocilizumab:{" "}
                                                            </span>
                                                            <br />
                                                            <span style={{ paddingLeft: '30px' }}>
                                                                <textarea
                                                                    type="text"
                                                                    name="txt_c10_tocil"
                                                                    className="input-dash"
                                                                    value={centerDetails.txt_c10_tocil}
                                                                    onChange={HandleFieldChange}
                                                                    maxLength={300}
                                                                />
                                                            </span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                id="chk_c10_dexa"
                                                                name="chk_c10_dexa"
                                                                checked={centerDetails.chk_c10_dexa}
                                                                onChange={HandleFieldChange}
                                                            />
                                                            <span className="radio-text-padding">
                                                                Dexamethasone:{" "}
                                                            </span>
                                                            <br />
                                                            <span style={{ paddingLeft: '30px' }}>
                                                                <textarea
                                                                    type="text"
                                                                    name="txt_c10_dexa"
                                                                    className="input-dash"
                                                                    value={centerDetails.txt_c10_dexa}
                                                                    onChange={HandleFieldChange}
                                                                    maxLength={300}
                                                                />
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <ErrorField show={hardErrList.c10_yes_empty} message={CenterInfo_Msgs.C2} />
                                                <ErrorField show={hardErrList.c10_txt_tocil_empty || hardErrList.c10_txt_dexa_empty} message={CenterInfo_Msgs.C10Txt} />
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c10_crs_mgmt_0"
                                                        name="rbl_c10_crs_mgmt"
                                                        value={0}
                                                        checked={centerDetails.rbl_c10_crs_mgmt === 0}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        No
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        id="rbl_c10_crs_mgmt_2"
                                                        name="rbl_c10_crs_mgmt"
                                                        value={2}
                                                        checked={centerDetails.rbl_c10_crs_mgmt === 2}
                                                        onChange={HandleFieldChange}
                                                    />
                                                    <span className="radio-text-padding">
                                                        Not applicable
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <ErrorField show={hardErrList.c10_empty} message={CenterInfo_Msgs.C1} />
                                    </div>
                                    {!centerDetails.locked && logInUserCenterId == centerId && (
                                        <div className="dependent-section">
                                            {
                                                <div>
                                                    <span className="question-weight center-info-confirm-pad">Confirmation of Completion of Center Information</span>
                                                    <div className="question question-weight margin-top5">
                                                        <span className="quest-text-pad">
                                                            Please confirm that you have completed the <b><i>Center Information</i></b> section.
                                                            Once confirmed, the data entries for this section will be locked until the next round of data abstraction.
                                                        </span>
                                                    </div>
                                                    <div className=" double-dig-answer">
                                                        <div>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    id="confirmStatus_1"
                                                                    name="confirmStatus"
                                                                    value={1}
                                                                    checked={confirmStatus === 1}
                                                                    onChange={HandleFieldChange}
                                                                />
                                                                <span className="radio-text-padding">
                                                                    Yes
                                                                </span>
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <label>
                                                                <input
                                                                    type="radio"
                                                                    id="confirmStatus_0"
                                                                    name="confirmStatus"
                                                                    value={0}
                                                                    checked={confirmStatus === 0}
                                                                    onChange={HandleFieldChange}
                                                                />
                                                                <span className="radio-text-padding">
                                                                    No
                                                                </span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <ErrorField show={hardErrList.confirm_empty} message={CenterInfo_Msgs.Confirm} />

                                                </div>
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {!centerDetails.locked && logInUserCenterId == centerId && (
                            <div className="survey-section-content-foot">
                                {confirmStatus !== 0 &&
                                    < a
                                        className="btn save-btn"
                                        onClick={HandleSubmitClick}
                                        style={{ fontWeight: "600", fontSize: "large" }}
                                    >
                                        Submit
                                    </a>
                                }
                            </div>
                        )}
                    </div>
                    {/* </div> */}
                </div>
            </section >
        </>
    );
};

export default CenterInformation;