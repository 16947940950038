import React, { useContext, useEffect, useState } from "react";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import {
  SideMenuItems,
  HTTPResponse,
  ToastMessageType,
  ToastMessages,
  PageDesignConstants,
} from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";

import ErrorField from "../../Components/ErrorField";
import { CommonError_Msgs } from "../../Helpers/HelperText";
import { AppContext } from "../../Contexts/AppContextProvider";
import {
  validateRadiotherapyDate,
  GetLocalStorageData,
  GetLoggedInUserID,
  isStringEmpty,
  isStringEmptyDh,
  IsAdmin,
} from "../../Helpers/Utilities";
import { DiseaseHistErrorMsg } from "../../Helpers/HelperText";
import DateControl from "../../Components/DateControl";
import { DiseaseHistoryService } from "../../WebApiServices/DiseaseHistory.service";
import DatePickerComponent from "../../Components/DatePickerComponent";
import dayjs from "dayjs";

const DiseaseHistory = () => {
  const navigatePaths = {
    prevPage: "/Demographics",
    currPage: "/DiseaseHistory",
    nextPage: "/Comorbidities",
  };

  const pid = GetLocalStorageData("patientId") ?? 0;

  const storedData = GetLocalStorageData("CohortTreatment");

  let cohortTreatmentModelListt = [];
  if (storedData) {
    cohortTreatmentModelListt = JSON.parse(storedData);
  }

  const { ShowToast, ToggleLoader, HandleSessionTimeout,
    isSideMenuActive,
    setIsSideMenuActive, } =
    useContext(AppContext);

  const [diseaseHist, setDiseaseHist] = useState({
    userId: GetLoggedInUserID(),
    q11_Percentage: null,
    q11_Percentage_txt: "",

    diseaseHistoryId: 0,

    q12_1: null,
    q12_2: null,
    q12_3: null,
    q12_4: null,
    q12_5: null,
    q12_6: null,

    q16_1: null,
    q16_2: null,
    q16_3: null,
    q16_4: null,
    q16_5: null,
    q16_6: null,
    q16_7: null,

    q17_1: null,
    q17_2: null,
    q17_3: null,
    q17_4: null,
  });

  const RepeaterRow = {
    cohortTreatmentId: 0,
    diseasehistorytreatmentId: 0,
    diseaseHistoryId: 0,

    cohortIndexDate: "",
    cohortIndexDateMinusDays: "",
    cohortTreatmentNameSuperscript:"",
    cohortTreatmentName: "",

    q13: null,
    q13_hard_Err: false,

    q14: null,
    q14_hard_Err: false,

    q15: null,
    q15_1: null,
    q15_2: null,

    q15_hard_Err: false,
    q15_1_hard_Err: false,
    q15_1_date: "",
    q15_1_default_date: null,

    q15_2_date: null,
    q15_2_default_date: null,
    q15_2_hard_Err: false,
    q15_2_default_date: null,

    q15_1_date_Err: false,
    q15_2_date_Err: false,
    q15_1_DateInvalid : false,
    q15_2_DateInvalid:false,

    q15_1_date_Empty_Err: false,
    q15_2_date_Empty_Err: false,

    q15_2_date_Greater_Error: false,
  };

  const [repeaterDiseaseHist, setRepeaterDiseaseHist] = useState([RepeaterRow]);

  const [cohortIndexedDate, setCohortIndexedDate] = useState("");

  const [shouldRenderDiv, setShouldRenderDiv] = useState(false);

  const [showTopErrMsg, setShowTopErrMsg] = useState(false);

  //HARD ERRORS STATE
  const InitialHardErrList = {
    q11_hard_err: false,
    q11_Percentage_Empty: false,

    q12_1_hard_err: false,
    q12_2_hard_err: false,
    q12_3_hard_err: false,
    q12_4_hard_err: false,
    q12_5_hard_err: false,
    q12_6_hard_err: false,

    q16_1_hard_err: false,
    q16_2_hard_err: false,
    q16_3_hard_err: false,
    q16_4_hard_err: false,
    q16_5_hard_err: false,
    q16_6_hard_err: false,
    q16_7_hard_err: false,

    q17_1_hard_err: false,
    q17_2_hard_err: false,
    q17_3_hard_err: false,
    q17_4_hard_err: false,
  };
  const [hardErrList, setHardErrList] = useState(InitialHardErrList);

  /* <summary>
  date: 12-07-2024
  Name: VK
  description: List of mapping Data
  <summary>*/
  //const [loopState, setLoopState] = useState([]);

  const HandleFieldChange = (e) => {
    const { name, value, checked } = e.target;

    switch (name) {
      //QUESTION 11

      case "q11_Percentage_txt": {
        const newValue =
          value === 0 || value === "0" ? "0" : (value || "").toString().trim();
        setDiseaseHist((prev) => ({
          ...prev,
          q11_Percentage: newValue !== "" ? 1 : null,
          [name]: newValue,
        }));

        setHardErrList((prev) => ({
              ...prev,
              q11_Percentage_Empty: false,
              q11_hard_err : false,
            }));


        // if (newValue === "") {
        //   setHardErrList((prev) => ({
        //     ...prev,
        //     q11_Percentage_Empty: true,
        //   }));
        // } else {
        //   setHardErrList((prev) => ({
        //     ...prev,
        //     q11_Percentage_Empty: false,
        //   }));
        // }

        break;
      }

      case "q11_Percentage": {
        setDiseaseHist((prev) => ({
          ...prev,
          q11_Percentage: Number(value),
          q11_Percentage_txt: "",
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q11_hard_err: false,
          q11_Percentage_Empty: false,
        }));

        break;
      }

      // QUESTION 12
      case "q12_1": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q12_1_hard_err: false,
        }));
        break;
      }

      case "q12_2": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q12_2_hard_err: false,
        }));
        break;
      }

      case "q12_3": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q12_3_hard_err: false,
        }));
        break;
      }

      case "q12_4": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q12_4_hard_err: false,
        }));
        break;
      }

      case "q12_5": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q12_5_hard_err: false,
        }));
        break;
      }

      case "q12_6": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q12_6_hard_err: false,
        }));
        break;
      }

      //QUESTION 16
      case "q16_1": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q16_1_hard_err: false,
        }));
        break;
      }

      case "q16_2": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q16_2_hard_err: false,
        }));
        break;
      }

      case "q16_3": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q16_3_hard_err: false,
        }));
        break;
      }

      case "q16_4": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q16_4_hard_err: false,
        }));
        break;
      }

      case "q16_5": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q16_5_hard_err: false,
        }));
        break;
      }

      case "q16_6": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q16_6_hard_err: false,
        }));
        break;
      }

      case "q16_7": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q16_7_hard_err: false,
        }));
        break;
      }

      //Question 17

      case "q17_1": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q17_1_hard_err: false,
        }));
        break;
      }

      case "q17_2": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q17_2_hard_err: false,
        }));
        break;
      }

      case "q17_3": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q17_3_hard_err: false,
        }));
        break;
      }

      case "q17_4": {
        setDiseaseHist((prev) => ({
          ...prev,
          [name]: parseInt(value),
        }));

        setHardErrList((prev) => ({
          ...prev,
          q17_4_hard_err: false,
        }));
        break;
      }
    }
  };

  const HandleRepeaterFieldChange = (e) => {
    const { name, id, value } = e.target;
    const value_int = Number(value);

    // Extract loopIndex from the id
    const loopIndex = Number(id);

    // Create a copy of the current state
    let updatedArray = [...repeaterDiseaseHist];

    switch (name) {
      case `q13_${loopIndex}`: {
        updatedArray[loopIndex].q13 = value_int;
        updatedArray[loopIndex].q13_hard_Err = false;
        break;
      }
      case `q14_${loopIndex}`: {
        updatedArray[loopIndex].q14 = value_int;
        updatedArray[loopIndex].q14_hard_Err = false;
        break;
      }

      case `q15_${loopIndex}`: {
        updatedArray[loopIndex].q15 = value_int;
        updatedArray[loopIndex].q15_hard_Err = false;
        updatedArray[loopIndex].q15_1_date_Empty_Err = false;
        updatedArray[loopIndex].q15_2_date_Empty_Err = false;

        updatedArray[loopIndex].q15_1 = null;

        if (value_int == 2 || value_int == 99) {
          updatedArray[loopIndex].q15_1_date = "";
          updatedArray[loopIndex].q15_2 = null;
          updatedArray[loopIndex].q15_2_date = "";
          updatedArray[loopIndex].q15_1_date_Err = false;
          updatedArray[loopIndex].q15_1_DateInvalid = false;
          updatedArray[loopIndex].q15_2_DateInvalid = false;
          updatedArray[loopIndex].q15_2_date_Err = false;
          updatedArray[loopIndex].q15_2_hard_Err = false;
          updatedArray[loopIndex].q15_1_hard_Err = false;
          updatedArray[loopIndex].q15_1_date_Empty_Err = false;
          updatedArray[loopIndex].q15_2_date_Empty_Err = false;
          updatedArray[loopIndex].q15_2_date_Greater_Error = false;


        }

        break;
      }

      case `q15_1${loopIndex}`: {
        updatedArray[loopIndex].q15_1 = value_int;
        updatedArray[loopIndex].q15_1_hard_Err = false;
        updatedArray[loopIndex].q15_1_date = "";
        updatedArray[loopIndex].q15_1_date_Err = false;
        updatedArray[loopIndex].q15_1_DateInvalid = false;
        updatedArray[loopIndex].q15_2_DateInvalid = false;

        updatedArray[loopIndex].q15_1_date_Empty_Err = false;
        updatedArray[loopIndex].q15_2_date_Greater_Error = false;


        break;
      }

      case `q15_2${loopIndex}`: {
        updatedArray[loopIndex].q15_2 = value_int;
        updatedArray[loopIndex].q15_2_hard_Err = false;
        updatedArray[loopIndex].q15_2_date = "";
        updatedArray[loopIndex].q15_2_date_Err = false;
        updatedArray[loopIndex].q15_2_date_Empty_Err = false;
        updatedArray[loopIndex].q15_2_date_Greater_Error = false;

        break;
      }
      default:
        break;
    }

    setRepeaterDiseaseHist(updatedArray);
  };

  const HandleRepeaterDateChange = (name, date) => {
    const lastIndex = name.lastIndexOf("-");
    const id = name.slice(lastIndex + 1);

    let updatedArray = [...repeaterDiseaseHist];
    let cohortIndexedDate = updatedArray[id].cohortIndexDate;
    let datevalid = validateRadiotherapyDate(date, cohortIndexedDate);

    switch (true) {
      // outpatient Visit Date change
      case name.startsWith("q15_1_date-"):
        updatedArray[id].q15_1_date = updatedArray[id].q15 == 1 ? date : "";
        updatedArray[id].q15_1 = 1;
        updatedArray[id].q15_1_hard_Err = false;
        updatedArray[id].q15_1_date_Err = false;
        updatedArray[id].q15_1_DateInvalid = false;

       // updatedArray[id].q15_1_date_Empty_Err = datevalid ? false : true;
       updatedArray[id].q15_1_date_Empty_Err =false;
        updatedArray[id].q15_2_date_Greater_Error = false;

        break;

      case name.startsWith("q15_2_date-"):
        updatedArray[id].q15_2_date = date;
        updatedArray[id].q15_2 = 1;
        updatedArray[id].q15_2_hard_Err = false;
        updatedArray[id].q15_2_date_Err = false;
        updatedArray[id].q15_2_DateInvalid = false;

      //  updatedArray[id].q15_2_date_Empty_Err = datevalid ? false : true;
      updatedArray[id].q15_2_date_Empty_Err =false;
        updatedArray[id].q15_2_date_Greater_Error = false;

        break;

      default:
        // Handle other cases or do nothing
        return;
    }

    setRepeaterDiseaseHist(updatedArray);
  };

   function DateInRange (date, minDate, maxDate)  {
    return dayjs(date).isAfter(minDate) && dayjs(date).isBefore(maxDate);
  };
   

  function isValidNumber(value) {
    // Convert value to a number
    const number = parseFloat(value);

    // Check if the value is a number and within the range 0 to 100
    return !isNaN(number) && number >= 0 && number <= 100;
  }

  const ValidateForm = () => {
    let result = true;
    // let datevalid = validateRadiotherapyDate(date, cohortIndexedDate);

    // QUESTION 11

    if (
      diseaseHist.q11_Percentage === null &&
      diseaseHist.q11_Percentage_txt == ""
    ) {
      setHardErrList((prev) => ({
        ...prev,
        q11_hard_err: true,
      }));
      result = false;
    }

    if (
      diseaseHist.q11_Percentage == 1 &&
      (diseaseHist.q11_Percentage_txt !== "" ||
        diseaseHist.q11_Percentage_txt !== null)
    ) {
      const numValid = isValidNumber(diseaseHist.q11_Percentage_txt);
      setHardErrList((prev) => ({
        ...prev,
        q11_Percentage_Empty: !numValid,
      }));
      result = numValid ? true : false;
    }

    // QUESTION 12

    if (diseaseHist.q12_1 === null) {
      setHardErrList((prev) => ({
        ...prev,
        q12_1_hard_err: true,
      }));
      result = false;
    }

    if (diseaseHist.q12_2 === null) {
      setHardErrList((prev) => ({
        ...prev,
        q12_2_hard_err: true,
      }));
      result = false;
    }

    if (diseaseHist.q12_3 === null) {
      setHardErrList((prev) => ({
        ...prev,
        q12_3_hard_err: true,
      }));
      result = false;
    }

    if (diseaseHist.q12_4 === null) {
      setHardErrList((prev) => ({
        ...prev,
        q12_4_hard_err: true,
      }));
      result = false;
    }

    if (diseaseHist.q12_5 === null) {
      setHardErrList((prev) => ({
        ...prev,
        q12_5_hard_err: true,
      }));
      result = false;
    }

    if (diseaseHist.q12_6 === null) {
      setHardErrList((prev) => ({
        ...prev,
        q12_6_hard_err: true,
      }));
      result = false;
    }

    //Question 16
    if (shouldRenderDiv) {
      if (diseaseHist.q16_1 === null) {
        setHardErrList((prev) => ({
          ...prev,
          q16_1_hard_err: true,
        }));
        result = false;
      }

      if (diseaseHist.q16_2 === null) {
        setHardErrList((prev) => ({
          ...prev,
          q16_2_hard_err: true,
        }));
        result = false;
      }

      if (diseaseHist.q16_3 === null) {
        setHardErrList((prev) => ({
          ...prev,
          q16_3_hard_err: true,
        }));
        result = false;
      }

      if (diseaseHist.q16_4 === null) {
        setHardErrList((prev) => ({
          ...prev,
          q16_4_hard_err: true,
        }));
        result = false;
      }

      if (diseaseHist.q16_5 === null) {
        setHardErrList((prev) => ({
          ...prev,
          q16_5_hard_err: true,
        }));
        result = false;
      }

      if (diseaseHist.q16_6 === null) {
        setHardErrList((prev) => ({
          ...prev,
          q16_6_hard_err: true,
        }));
        result = false;
      }

      if (diseaseHist.q16_7 === null) {
        setHardErrList((prev) => ({
          ...prev,
          q16_7_hard_err: true,
        }));
        result = false;
      }

      //Question 17

      if (diseaseHist.q17_1 === null) {
        setHardErrList((prev) => ({
          ...prev,
          q17_1_hard_err: true,
        }));
        result = false;
      }

      if (diseaseHist.q17_2 === null) {
        setHardErrList((prev) => ({
          ...prev,
          q17_2_hard_err: true,
        }));
        result = false;
      }

      if (diseaseHist.q17_3 === null) {
        setHardErrList((prev) => ({
          ...prev,
          q17_3_hard_err: true,
        }));
        result = false;
      }

      if (diseaseHist.q17_4 === null) {
        setHardErrList((prev) => ({
          ...prev,
          q17_4_hard_err: true,
        }));
        result = false;
      }
    }



    if (repeaterDiseaseHist && repeaterDiseaseHist.length > 0) {
      const updatedRepeaterDiseaseHist = repeaterDiseaseHist.map((row, idx) => {

        let updatedRow = { ...row };
        if (row.q13 === null) {
          updatedRow.q13_hard_Err = true;
          result = false;
        } else {
          updatedRow.q13_hard_Err = false;
        }
        if (row.q14 === null) {
          updatedRow.q14_hard_Err = true;
          result = false;
        } else {
          updatedRow.q14_hard_Err = false;
        }

        if (row.q15 === null) {
          updatedRow.q15_hard_Err = true;
          result = false;
        } else if (row.q15 === 2 || row.q15 === 99) {
          updatedRow.q15_1_hard_Err = false;
          updatedRow.q15_2_hard_Err = false;
          updatedRow.q15_hard_Err = false;
        } else {
          updatedRow.q15_hard_Err = false;
        }

        if (row.q15 == 1) {
          if (row.q15_1 == null) {
            updatedRow.q15_1_hard_Err = true;
            result = false;
          } else {
            updatedRow.q15_1_hard_Err = false;
          }

          if (row.q15_1 == 1 && row.q15_1_date == "" ) {
            updatedRow.q15_1_date_Err = true;
            result = false;
          }

          // if (
          //   row.q15_1 == 1 &&
          //   row.q15_1_date !== "" &&
          //   row.q15_1_date_Empty_Err
          // ) {
          //   updatedRow.q15_1_date_Empty_Err = true;
          //   result = false;
          // }


       //   validateRadiotherapyDate(date, cohortIndexedDate)
       debugger

       if (
        row.q15_1 == 1 &&
        row.q15_1_date !== "" && row.q15_1_date !== "Invalid Date" && DateInRange(row.q15_1_date, dayjs("01-01-1920"), dayjs())
     &&  !validateRadiotherapyDate(row.q15_1_date, row.cohortIndexDate) 
        
      ) {
        updatedRow.q15_1_date_Empty_Err = true;
        result = false;
      }

      else if (row.q15_1_date === "Invalid Date" && row.q15_1 === 1 )
      {
       updatedRow.q15_1_DateInvalid = true;
       result = false;
      }
      else if (row.q15_1_date !== "Invalid Date" && row.q15_1_date !== "" && row.q15_1_date !== null && row.q15_1 === 1 && !DateInRange(row.q15_1_date, dayjs("01-01-1920"), dayjs())) {
        updatedRow.q15_1_DateInvalid = true;
        result = false;
      }
      


          if (row.q15_2 == 1 && row.q15_2_date == "") {
            updatedRow.q15_2_date_Err = true;
            result = false;
          }

          if (
            row.q15_2 == 1 &&
            row.q15_2_date !== "" &&
            row.q15_2_date_Empty_Err
          ) {
            updatedRow.q15_2_date_Empty_Err = true;
            result = false;
          }

          if (row.q15_2 == null) {
            updatedRow.q15_2_hard_Err = true;
            result = false;
          } else {
            updatedRow.q15_2_hard_Err = false;
          }

         

          if (
            row.q15_2 == 1 &&
            row.q15_2_date !== "" && row.q15_2_date !== "Invalid Date" && DateInRange(row.q15_2_date, dayjs("01-01-1920"), dayjs())
            && !validateRadiotherapyDate(row.q15_2_date, row.cohortIndexDate) 
            
          ) {
            updatedRow.q15_2_date_Empty_Err = true;
            result = false;
          }
          else if (row.q15_2_date === "Invalid Date" && row.q15_2 === 1 )
          {
           updatedRow.q15_2_DateInvalid = true;
           result = false;
          }
          else if (row.q15_2_date !== "Invalid Date" && row.q15_2_date !== "" && row.q15_2_date !== null && row.q15_2 === 1 && !DateInRange(row.q15_2_date, dayjs("01-01-1920"), dayjs())) {
            updatedRow.q15_2_DateInvalid = true;
            result = false;
          }




          // Ensure Q15.1 ≤ Q15.2
          if (row.q15_1_date !== "" && row.q15_2_date !== "" && row.q15_1_date !== "Invalid Date" && row.q15_2_date !== "Invalid Date") {
            const q15_1_date = new Date(row.q15_1_date);
            const q15_2_date = new Date(row.q15_2_date);

            if (q15_1_date > q15_2_date) {
              //updatedRow.q15_1_date_Err = true;
              updatedRow.q15_2_date_Greater_Error = true;
              result = false;
            } else {
              // updatedRow.q15_1_date_Err = false;
              updatedRow.q15_2_date_Greater_Error = false;
            }
          }
        }
        return updatedRow;
      });
      setRepeaterDiseaseHist(updatedRepeaterDiseaseHist);
    }

    return result;
  };

  const formatDates = (dateString) => {
    // Create a Date object from the dateString
    let initialDate = new Date(dateString);

    // Subtract 183 days
    initialDate.setDate(initialDate.getDate() - 91);

    // Format the date as MMDDYYYY
    let formattedDate = `${(initialDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${initialDate
      .getDate()
      .toString()
      .padStart(2, "0")}/${initialDate.getFullYear()}`;

    return formattedDate;
  };

  const LoadData = async () => {
    try {
      if (pid > 0) {
        ToggleLoader(true);
        const response = await DiseaseHistoryService.LoadDiseaseHistory(pid);
        ToggleLoader(false);

        if (response?.status === HTTPResponse.OK) {
          const { data } = response;

          const cohortTreatmentMap = new Map();
          if (data.cohortTreatmentModelList) {
            data.cohortTreatmentModelList.forEach((item) => {
              cohortTreatmentMap.set(item.cohortTreatmentID, {
                  cohortTreatmentName: (item.cohortTreatmentName === "DKRd (TE or TIE)") ? (item.cohortTreatmentSCT === 1 ? "DKRd (TE)" : (item.cohortTreatmentSCT === 2 ? "DKRd (TIE)" : item.cohortTreatmentName)) : item.cohortTreatmentName,
                cohortIndexDate: item.cohortIndexDate,
                cohortTreatmentNameSuperscript:item.cohortTreatmentNameSuperscript
              });
            });

            const hasIfNdmm = data.cohortTreatmentModelList.some(
              (item) => item.ndmm
            );
            setShouldRenderDiv(hasIfNdmm);
          }

          const repeaterData = [];

          if (
            data.diseaseHistory != null &&
            data.diseaseHistory.diseasehistorytreatmentList != null
          ) {
            data.diseaseHistory.diseasehistorytreatmentList.forEach((item) => {
              const cohortTreatment =
                cohortTreatmentMap.get(item.cohortTreatmentId) || {};

              repeaterData.push({
                cohortTreatmentId: item.cohortTreatmentId,
                cohortTreatmentName:
                  cohortTreatment.cohortTreatmentName || null,
                cohortIndexDate: cohortTreatment.cohortIndexDate || null,
                cohortTreatmentNameSuperscript:cohortTreatment.cohortTreatmentNameSuperscript || null,
                diseasehistorytreatmentId: item.diseasehistorytreatmentid,
                diseaseHistoryId: data.diseaseHistory.diseasehistoryid,
                q13: item.rissSystem || null,
                q13_hard_Err: false,
                q14: item.mmType || null,
                q14_hard_Err: false,
                q15: item.radiotherapyTreatment || null,
                q15_1_date: item.radiotherapyDate,
                q15_1: item.radiotherapyDateUnk
                  ? 99
                  : !isStringEmpty(item.radiotherapyDate)
                  ? 1
                  : null,
                q15_2_date: item.radiotherapyLastdate,
                q15_2: item.radiotherapyLastdateUnk
                  ? 99
                  : !isStringEmpty(item.radiotherapyLastdate)
                  ? 1
                  : null,
                q15_hard_Err: false,
                q15_1_hard_Err: false,
                q15_2_hard_Err: false,
                q15_1_date_Err: false,
                q15_1_DateInvalid : false,
                q15_2_DateInvalid : false,



                q15_2_date_Err: false,
                q15_date_Err: false,
                q15_1_date_Empty_Err: false,
                q15_2_date_Empty_Err: false,
                q15_2_date_Greater_Error: false,
              });
            });
          }

          // Add new items from cohortTreatmentModelList that don't exist in diseasehistorytreatmentList
          data.cohortTreatmentModelList.forEach((item) => {
            const exists =
              data.diseaseHistory?.diseasehistorytreatmentList?.some(
                (treatment) =>
                  treatment.cohortTreatmentId === item.cohortTreatmentID
              );
            if (!exists) {
              repeaterData.push({
                cohortTreatmentId: item.cohortTreatmentID,
                  cohortTreatmentName: ((item.cohortTreatmentName === "DKRd (TE or TIE)") ? (item.cohortTreatmentSCT === 1 ? "DKRd (TE)" : (item.cohortTreatmentSCT === 2 ? "DKRd (TIE)" : item.cohortTreatmentName)) : item.cohortTreatmentName) || null,
                cohortIndexDate: item.cohortIndexDate || null,
                cohortTreatmentNameSuperscript:item.cohortTreatmentNameSuperscript || null,
                diseasehistorytreatmentId: 0,
                diseaseHistoryId: data.diseaseHistory?.diseasehistoryid || 0,
                q13: null,
                q13_hard_Err: false,
                q14: null,
                q14_hard_Err: false,
                q15: null,
                q15_1: null,
                q15_2: null,
                q15_hard_Err: false,
                q15_1_date: "",
                q15_1_default_date: null,
                q15_2_date: null,
                q15_2_default_date: null,
                q15_date_Err: false,
                q15_1_hard_Err: false,
                q15_1_date_Err: false,
                q15_1_DateInvalid : false,
                q15_2_DateInvalid : false,


                q15_2_hard_Err: false,
                q15_2_date_Err: false,
                q15_2_date_Greater_Error: false,
              });
            }
          });

          setRepeaterDiseaseHist(repeaterData);

          if (data.diseaseHistory != null) {
            setDiseaseHist((prev) => ({
              ...prev,
              diseaseHistoryId: data.diseaseHistory.diseasehistoryid,
              q11_Percentage_txt: data.diseaseHistory.plasmaPercentage ?? "",
              // q11_Percentage: !isStringEmpty(
              //   data.diseaseHistory.plasmaPercentage
              // )
              //   ? 1
              //   : data.diseaseHistory.plasmaPercentageUnk
              //   ? 99
              //   : null,

              q11_Percentage:
                !isStringEmptyDh(data.diseaseHistory.plasmaPercentage) ||
                data.diseaseHistory.plasmaPercentage === "0"
                  ? 1
                  : data.diseaseHistory.plasmaPercentageUnk
                  ? 99
                  : null,

              q12_1: data.diseaseHistory.mutationDel,
              q12_2: data.diseaseHistory.mutationT14,
              q12_3: data.diseaseHistory.mutationT16,
              q12_4: data.diseaseHistory.mutationT20,
              q12_5: data.diseaseHistory.mutation1q21Gain,
              q12_6: data.diseaseHistory.mutation1q21Amplification,
              q16_1: data.diseaseHistory.crabdiagnosisHypercalcemia,
              q16_2: data.diseaseHistory.crabdiagnosisRenal,
              q16_3: data.diseaseHistory.crabdiagnosisAnemia,
              q16_4: data.diseaseHistory.crabdiagnosisBonelesion,
              q16_5: data.diseaseHistory.crabdiagnosisBonemarrow,
              q16_6: data.diseaseHistory.crabdiagnosisSerumfree,
              q16_7: data.diseaseHistory.crabdiagnosisMri,
              q17_1: data.diseaseHistory.resultBonelesions,
              q17_2: data.diseaseHistory.resultExtramedullary,
              q17_3: data.diseaseHistory.resultPlasmacytoma,
              q17_4: data.diseaseHistory.resultParamedullary,
            }));
          }
        } else if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  };

  const SavePage = async (validate) => {
    try {
      ToggleLoader(true);
      setHardErrList(InitialHardErrList);
      let isValid = validate ? ValidateForm() : true;
      if (isValid) {
        setShowTopErrMsg(false);

        const repeaterData = repeaterDiseaseHist.map((test) => ({
          Diseasehistorytreatmentid: test.diseasehistorytreatmentId,
          CohortTreatmentId: test.cohortTreatmentId,
          RissSystem: test.q13,
          MmType: test.q14,
          RadiotherapyTreatment: test.q15,
          RadiotherapyDate: test.q15 == 1 && test.q15_1_date != "Invalid Date" ? test.q15_1_date : test.q15_1_date === "Invalid Date" ? ""
          : "", 


          RadiotherapyDateUnk: test.q15_1 == 99,
          RadiotherapyLastdate: test.q15 == 1 && test.q15_2_date != "Invalid Date" ? test.q15_2_date : test.q15_2_date === "Invalid Date" ? ""
          : "", 

          RadiotherapyLastdateUnk: test.q15_2 == 99,
        }));

        const diseaseHistModel = {
          patientId: pid,
          plasmaPercentage: diseaseHist.q11_Percentage_txt,
          plasmaPercentageUnk: diseaseHist.q11_Percentage == 99,
          mutationDel: diseaseHist.q12_1,
          mutationT14: diseaseHist.q12_2,
          mutationT16: diseaseHist.q12_3,
          mutationT20: diseaseHist.q12_4,
          mutation1q21Gain: diseaseHist.q12_5,
          mutation1q21Amplification: diseaseHist.q12_6,

          CrabdiagnosisHypercalcemia: shouldRenderDiv
            ? diseaseHist.q16_1
            : null,
          CrabdiagnosisRenal: shouldRenderDiv ? diseaseHist.q16_2 : null,
          CrabdiagnosisAnemia: shouldRenderDiv ? diseaseHist.q16_3 : null,
          CrabdiagnosisBonelesion: shouldRenderDiv ? diseaseHist.q16_4 : null,
          CrabdiagnosisBonemarrow: shouldRenderDiv ? diseaseHist.q16_5 : null,
          CrabdiagnosisSerumfree: shouldRenderDiv ? diseaseHist.q16_6 : null,
          CrabdiagnosisMri: shouldRenderDiv ? diseaseHist.q16_7 : null,

          ResultBonelesions: shouldRenderDiv ? diseaseHist.q17_1 : null,
          ResultExtramedullary: shouldRenderDiv ? diseaseHist.q17_2 : null,
          ResultPlasmacytoma: shouldRenderDiv ? diseaseHist.q17_3 : null,
          ResultParamedullary: shouldRenderDiv ? diseaseHist.q17_4 : null,

          DiseasehistorytreatmentList: repeaterData,
          UserId: GetLoggedInUserID(),
          isValidated: validate,
          IsAdmin: IsAdmin()
        };

        if (pid > 0) {
          let response = await DiseaseHistoryService.SaveDiseaseHistory(
            diseaseHistModel
          );

          if (response.status !== HTTPResponse.OK) {
            if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();
            } else throw response.error;
          } else {
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
            await LoadData(pid);
          }

          ToggleLoader(false);
        }
        return true;
      } else {
        ToggleLoader(false);
        setShowTopErrMsg(true);
        return false;
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };

  useEffect(() => {
    LoadData();
  }, [pid]);

  const sidebar = document.getElementById("mySidebar");
  const main = document.getElementById("main");

  const toggleNav = () => {
    if (isSideMenuActive === true  ) { 
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
      setIsSideMenuActive(false)
    } else if( isSideMenuActive===false ){
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
      setIsSideMenuActive(true)
    }
}

useEffect(() => {
const   sidebar = document.getElementById("mySidebar");
const  main = document.getElementById("main");


  if (isSideMenuActive === true) {
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
    } else {
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
    }
});

  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div >
          <SideBarMenu activePageId={SideMenuItems.DiseaseHistory} />
          <>
          <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
         </>
          <div className="col-pad-left" id="main">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>DISEASE HISTORY</h4>
              </div>
              {showTopErrMsg && (
                <div className="clean-error">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div className="question-answer">
                    {/* Question 11. */}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q11.</span>
                        <span className="quest-text-pad">
                          Percentage of monoclonal plasma cells in bone marrow
                          reported at the initial MM diagnosis
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <label htmlFor="race_other">
                          <input
                            type="radio"
                            id="q11_Percentage"
                            name="q11_Percentage"
                            value={1}
                            checked={diseaseHist.q11_Percentage === 1}
                            onChange={HandleFieldChange}
                          />
                        </label>
                        <label htmlFor="race_other">
                          <span className="radio-text-padding">
                            <input
                              className="input-dash"
                              name="q11_Percentage_txt"
                              type="text"
                              value={diseaseHist.q11_Percentage_txt} //tk state
                              onKeyDown={(e) => {
                                // Allow backspace, arrow keys, and delete
                                if (
                                  [
                                    "Backspace",
                                    "ArrowLeft",
                                    "ArrowRight",
                                    "Delete",
                                    "Tab",
                                  ].includes(e.key)
                                ) {
                                  return;
                                }

                                // Prevent non-numeric characters, except '.'
                                if (!/[0-9.]/.test(e.key)) {
                                  e.preventDefault();
                                  return;
                                }

                                const value = e.target.value;
                                const selectionStart = e.target.selectionStart;
                                const newValue =
                                  value.slice(0, selectionStart) +
                                  e.key +
                                  value.slice(e.target.selectionEnd);

                                // Prevent multiple '.'
                                if (e.key === "." && value.includes(".")) {
                                  e.preventDefault();
                                  return;
                                }

                                // Prevent '.' as the first character
                                if (e.key === "." && value === "") {
                                  e.preventDefault();
                                  return;
                                }

                                // Allow only one digit after decimal point
                                if (
                                  newValue.includes(".") &&
                                  newValue.split(".")[1].length > 1
                                ) {
                                  e.preventDefault();
                                  return;
                                }
                              }}
                              onChange={HandleFieldChange}
                            />
                            {" "}%
                          </span>
                        </label>
                      </div>

                      <ErrorField
                        show={hardErrList.q11_Percentage_Empty}
                        message={DiseaseHistErrorMsg.RangeErrMsg}
                      />
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="q11_Percentage"
                              name="q11_Percentage"
                              value={99}
                              checked={diseaseHist.q11_Percentage === 99}
                              onChange={HandleFieldChange}
                            />

                            <span className="radio-text-padding">Unknown</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={hardErrList.q11_hard_err}
                        message={DiseaseHistErrorMsg.ProvideOptions}
                      />
                    </div>
                    {/* Question 12. */}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>Q12.</span>
                        <span className="quest-text-pad">
                          Which of the following cytogenic mutations have been
                          recorded for the patient at any time since initial MM
                          diagnosis?
                        </span>
                      </div>
                    </div>
                    <div className="sub-question NdmmChar" style={{}}>
                      <table
                        style={{
                          border: "1px solid black",
                          borderCollapse: "collapse",
                          width: "100%",
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            <span className="sub-question-quest">Q12.1.</span>
                            <span className="quest-text-pad">Del(17p)</span>
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="disease_Hist_12_1"
                                    name="q12_1"
                                    value={1}
                                    checked={diseaseHist.q12_1 === 1}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">
                                    Yes
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="disease_Hist_12_1"
                                    name="q12_1"
                                    value={2}
                                    checked={diseaseHist.q12_1 === 2}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">No</span>
                                </label>
                              </div>
                            </div>
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="disease_Hist_12_1"
                                    name="q12_1"
                                    value={99}
                                    checked={diseaseHist.q12_1 === 99}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">
                                    Unknown/ Not tested
                                  </span>
                                </label>
                              </div>
                            </div>
                            <ErrorField
                              show={hardErrList.q12_1_hard_err}
                              message={DiseaseHistErrorMsg.ProvideOptions}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            <span className="sub-question-quest">Q12.2.</span>
                            <span className="quest-text-pad">t[4;14] </span>
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="q12_2"
                                    name="q12_2"
                                    value={1}
                                    checked={diseaseHist.q12_2 === 1}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">
                                    Yes
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="q12_2"
                                    name="q12_2"
                                    value={2}
                                    checked={diseaseHist.q12_2 === 2}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">No</span>
                                </label>
                              </div>
                            </div>
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="q12_2"
                                    name="q12_2"
                                    value={99}
                                    checked={diseaseHist.q12_2 === 99}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">
                                    Unknown/ Not tested
                                  </span>
                                </label>
                              </div>
                            </div>
                            <ErrorField
                              show={hardErrList.q12_2_hard_err}
                              message={DiseaseHistErrorMsg.ProvideOptions}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            <span className="sub-question-quest">Q12.3.</span>
                            <span className="quest-text-pad">t[14;16] </span>
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="q12_3"
                                    name="q12_3"
                                    value={1}
                                    checked={diseaseHist.q12_3 === 1}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">
                                    Yes
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="q12_3"
                                    name="q12_3"
                                    value={2}
                                    checked={diseaseHist.q12_3 === 2}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">No</span>
                                </label>
                              </div>
                            </div>
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="q12_3"
                                    name="q12_3"
                                    value={99}
                                    checked={diseaseHist.q12_3 === 99}
                                    onChange={HandleFieldChange}
                                  />
                                  <span className="radio-text-padding">
                                    Unknown/ Not tested
                                  </span>
                                </label>
                              </div>
                            </div>
                            <ErrorField
                              show={hardErrList.q12_3_hard_err}
                              message={DiseaseHistErrorMsg.ProvideOptions}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            <span className="sub-question-quest">Q12.4.</span>
                            <span className="quest-text-pad">t[14;20] </span>
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="q12_4"
                                    name="q12_4"
                                    value={1}
                                    checked={diseaseHist.q12_4 === 1}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">
                                    Yes
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="q12_4"
                                    name="q12_4"
                                    value={2}
                                    checked={diseaseHist.q12_4 === 2}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">No</span>
                                </label>
                              </div>
                            </div>
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="q12_4"
                                    name="q12_4"
                                    value={99}
                                    checked={diseaseHist.q12_4 === 99}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">
                                    Unknown/ Not tested
                                  </span>
                                </label>
                              </div>
                            </div>
                            <ErrorField
                              show={hardErrList.q12_4_hard_err}
                              message={DiseaseHistErrorMsg.ProvideOptions}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            <span className="sub-question-quest">Q12.5.</span>
                            <span className="quest-text-pad">
                              1q21 gain (3 copies){" "}
                            </span>
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="q12_5_yes"
                                    name="q12_5"
                                    value={1}
                                    checked={diseaseHist.q12_5 === 1}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">
                                    Yes
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="q12_5_no"
                                    name="q12_5"
                                    value={2}
                                    checked={diseaseHist.q12_5 === 2}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">No</span>
                                </label>
                              </div>
                            </div>
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="q12_5_unknown"
                                    name="q12_5"
                                    value={99}
                                    checked={diseaseHist.q12_5 === 99}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">
                                    Unknown/ Not tested
                                  </span>
                                </label>
                              </div>
                            </div>
                            <ErrorField
                              show={hardErrList.q12_5_hard_err}
                              message={DiseaseHistErrorMsg.ProvideOptions}
                            />
                          </td>
                        </tr>

                        <tr>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            <span className="sub-question-quest">Q12.6.</span>
                            <span className="quest-text-pad">
                              1q21 amplification (≥ 4 copies){" "}
                            </span>
                          </td>
                          <td
                            style={{
                              border: "1px solid black",
                              padding: "8px",
                            }}
                          >
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="q12_6"
                                    name="q12_6"
                                    value={1}
                                    checked={diseaseHist.q12_6 === 1}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">
                                    Yes
                                  </span>
                                </label>
                              </div>
                            </div>
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="q12_6"
                                    name="q12_6"
                                    value={2}
                                    checked={diseaseHist.q12_6 === 2}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">No</span>
                                </label>
                              </div>
                            </div>
                            <div className="double-dig-answer">
                              <div className="option-without-input">
                                <label>
                                  <input
                                    type="radio"
                                    id="q12_6"
                                    name="q12_6"
                                    value={99}
                                    checked={diseaseHist.q12_6 === 99}
                                    onChange={HandleFieldChange}
                                  />

                                  <span className="radio-text-padding">
                                    Unknown/ Not tested
                                  </span>
                                </label>
                              </div>
                            </div>
                            <ErrorField
                              show={hardErrList.q12_6_hard_err}
                              message={DiseaseHistErrorMsg.ProvideOptions}
                            />
                          </td>
                        </tr>
                      </table>
                    </div>

                    {/* Question 13 */}

                    {repeaterDiseaseHist.map((data, index) => (
                      <div className="dependent-section">
                        <div className='cohort-header'>
                        <span>
                      
                          {data.cohortTreatmentName}
                          {data.cohortTreatmentNameSuperscript == null ? (
                            ''
                           ) : (
                            <>
                             <sup className="supscript">{data.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                             {data.cohortTreatmentNameSuperscript.slice(2)}
                             </>
                            )}
                        </span>

                        </div>
                        {/* QUESTION 13 */}
                        <div className="question-bot-space">
                          <div className="question question-weight">
                            <span>Q13.</span>
                            <span className="quest-text-pad">
                              Revised - International Staging System [R-ISS] on
                              or closest to the initiation of{" "}
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                {data.cohortTreatmentName}
                                {data.cohortTreatmentNameSuperscript == null ? (
                                  ''
                                ) : (
                                  <>
                                  <sup className="supscript">{data.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                                  {data.cohortTreatmentNameSuperscript.slice(2)}
                                  </>
                                  )}
                              </span>{" "}
                              on &nbsp;
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                {data.cohortIndexDate}
                              </span>{" "}
                              ? If R-ISS has been noted in the chart, chart
                              abstractor can use it as stated and does not need
                              to derive based on the criteria. Otherwise, please
                              consider deriving based on the data available in
                              the chart.
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="option-without-input">
                              <label
                                className="long-text-option"
                                // htmlFor={`q13_${index}`}
                              >
                                <input
                                  type="radio"
                                  id={index}
                                  name={`q13_${index}`}
                                  value={1}
                                  checked={data.q13 === 1}
                                  onChange={HandleRepeaterFieldChange}
                                />
                                <span className="radio-text-padding">
                                  I - Serum β2-microglobulin &lt; 3.5 mg/L,
                                  serum albumin ≥ 3.5 g/dL, standard-risk
                                  chromosomal abnormalities detected by iFISH,
                                  and normal serum lactase dehydrogenase (LDH)
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label
                                className="long-text-option"
                                //htmlFor={`q13_${index}`}
                              >
                                <input
                                  type="radio"
                                  id={index}
                                  name={`q13_${index}`}
                                  value={2}
                                  checked={data.q13 === 2}
                                  onChange={HandleRepeaterFieldChange}
                                />
                                <span className="radio-text-padding">
                                  II – Neither Stage I nor Stage III
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label
                                className="long-text-option"
                                // htmlFor={`q13_${index}`}
                              >
                                <input
                                  type="radio"
                                  id={index}
                                  name={`q13_${index}`}
                                  value={3}
                                  checked={data.q13 === 3}
                                  onChange={HandleRepeaterFieldChange}
                                />
                                <span className="radio-text-padding">
                                  III - Serum β2-microglobulin &gt; 5.5 mg/L and
                                  either high-risk chromosomal abnormalities
                                  detected by iFISH or high serum LDH
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label
                                className="long-text-option"
                                //htmlFor={`q13_${index}`}
                              >
                                <input
                                  type="radio"
                                  id={index}
                                  name={`q13_${index}`}
                                  value={99}
                                  checked={data.q13 === 99}
                                  onChange={HandleRepeaterFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={data.q13_hard_Err}
                            message={DiseaseHistErrorMsg.ProvideOptions}
                          />
                        </div>

                        {/* QUESTION 14 */}
                        <div className="question-bot-space">
                          <div className="question question-weight">
                            <span>Q14.</span>
                            <span className="quest-text-pad">
                              Type of measurable MM disease the patient has been
                              diagnosed with on or closest to the initiation of
                              &nbsp;
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                {data.cohortTreatmentName}
                                {data.cohortTreatmentNameSuperscript == null ? (
                                  ''
                                ) : (
                                  <>
                                  <sup className="supscript">{data.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                                  {data.cohortTreatmentNameSuperscript.slice(2)}
                                  </>
                                  )}
                              </span>
                              &nbsp; on &nbsp;
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                {data.cohortIndexDate}
                              </span>
                              ?
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="option-without-input">
                              <label
                                className="long-text-option"
                                //htmlFor={`q14_${index}`}
                              >
                                <input
                                  type="radio"
                                  id={index}
                                  name={`q14_${index}`}
                                  value={1}
                                  checked={data.q14 === 1}
                                  onChange={HandleRepeaterFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Serum measurable (e.g., IgG, IgA, etc.)
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label
                                className="long-text-option"
                                //htmlFor={`q14_${index}`}
                              >
                                <input
                                  type="radio"
                                  id={index}
                                  name={`q14_${index}`}
                                  value={2}
                                  checked={data.q14 === 2}
                                  onChange={HandleRepeaterFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Urine measurable only
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label
                                className="long-text-option"
                                //htmlFor={`q14_${index}`}
                              >
                                <input
                                  type="radio"
                                  id={index}
                                  name={`q14_${index}`}
                                  value={3}
                                  checked={data.q14 === 3}
                                  onChange={HandleRepeaterFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Serum free light chains only
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label
                                className="long-text-option"
                                //htmlFor={`q14_${index}`}
                              >
                                <input
                                  type="radio"
                                  id={index}
                                  name={`q14_${index}`}
                                  value={4}
                                  checked={data.q14 === 4}
                                  onChange={HandleRepeaterFieldChange}
                                />
                                <span className="radio-text-padding">
                                  No measurable disease – plasma cell only
                                </span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label
                                className="long-text-option"
                                //htmlFor={`q14_${index}`}
                              >
                                <input
                                  type="radio"
                                  id={index}
                                  name={`q14_${index}`}
                                  value={99}
                                  checked={data.q14 === 99}
                                  onChange={HandleRepeaterFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={data.q14_hard_Err}
                            message={DiseaseHistErrorMsg.ProvideOptions}
                          />
                        </div>

                        {/* QUESTION 15 */}
                        <div className="question-bot-space">
                          <div className="question question-weight">
                            <span>Q15.</span>
                            <span className="quest-text-pad">
                              Did the patient ever receive radiotherapy
                              treatment on or within 3 months prior to
                              initiating &nbsp;
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                {data.cohortTreatmentName}
                                {data.cohortTreatmentNameSuperscript == null ? (
                                  ''
                                ) : (
                                  <>
                                  <sup className="supscript">{data.cohortTreatmentNameSuperscript.slice(0, 2)}</sup>
                                  {data.cohortTreatmentNameSuperscript.slice(2)}
                                  </>
                                  )}
                              </span>
                              &nbsp; (i.e., from &nbsp;
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                {formatDates(
                                  cohortTreatmentModelListt[index]
                                    .cohortIndexDate
                                )}
                              </span>
                              &nbsp; to &nbsp;
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                {data.cohortIndexDate}
                              </span>
                              )?
                            </span>
                          </div>
                          <div className="double-dig-answer">
                            <div className="option-without-input">
                              <label
                                className="long-text-option"
                                // htmlFor={`q15_${index}`}
                              >
                                <input
                                  type="radio"
                                  id={index}
                                  name={`q15_${index}`}
                                  value={1}
                                  checked={data.q15 === 1}
                                  onChange={HandleRepeaterFieldChange}
                                />
                                <span className="radio-text-padding">Yes</span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label
                                className="long-text-option"
                                // htmlFor={`q15_${index}`}
                              >
                                <input
                                  type="radio"
                                  id={index}
                                  name={`q15_${index}`}
                                  value={2}
                                  checked={data.q15 === 2}
                                  onChange={HandleRepeaterFieldChange}
                                />
                                <span className="radio-text-padding">No</span>
                              </label>
                            </div>
                            <div className="option-without-input">
                              <label
                                className="long-text-option"
                                // htmlFor={`q15_${index}`}
                              >
                                <input
                                  type="radio"
                                  id={index}
                                  name={`q15_${index}`}
                                  value={99}
                                  checked={data.q15 === 99}
                                  onChange={HandleRepeaterFieldChange}
                                />
                                <span className="radio-text-padding">
                                  Unknown
                                </span>
                              </label>
                            </div>
                          </div>
                          <ErrorField
                            show={data.q15_hard_Err}
                            message={DiseaseHistErrorMsg.ProvideOptions}
                          />
                        </div>
                        {data.q15 == 1 && (
                          <div className="question-bot-space">
                            <div className="sub-question">
                              <span className="sub-question-quest">Q15.1.</span>
                              <span className="quest-text-pad">
                                Date of first radiotherapy on or within 3 months
                                prior to initiating{" "}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                    fontWeight: "647",
                                  }}
                                >
                                  {data.cohortTreatmentName}
                                 
                                </span>
                              </span>
                              <div className="double-dig-answer">
                                <div className="option-without-input">
                                  <input
                                    type="radio"
                                    id={index}
                                    name={`q15_1${index}`}
                                    value={1}
                                    checked={data.q15_1 === 1}
                                    onChange={HandleRepeaterFieldChange}
                                  />
                                  <span className="radio-text-padding">
                                    {/* <DateControl
                                      ctrlId={`q15_1_date-${index}`}
                                      HandleDateChange={
                                        HandleRepeaterDateChange
                                      }
                                      //defDate={data.q15_1_default_date}
                                      date={data.q15_1_date}
                                    /> */}

                                  <DatePickerComponent
                                    ctrlId={`q15_1_date-${index}`}
                                    // selectedDate={selectedDate}
                                    // onDateChange={(newDate) => setSelectedDate(newDate)}
                                    HandleDateChange={HandleRepeaterDateChange}
                                    //optionId={1}
                                    minDate={dayjs("01-01-1920")} 
                                    maxDate={dayjs()} 
                                    locale="en" // for dd/mm/yyyy use "en-gb"
                                    date={data.q15_1_date}
                                  />
                                   

                                  </span>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="radio"
                                      id={index}
                                      name={`q15_1${index}`}
                                      value={99}
                                      checked={data.q15_1 === 99}
                                      onChange={HandleRepeaterFieldChange}
                                    />

                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={data.q15_1_hard_Err}
                                message={DiseaseHistErrorMsg.ProvideOptions}
                              />

                              <ErrorField
                                show={data.q15_1_date_Err}
                                message={DiseaseHistErrorMsg.DateFirstErrMsg}
                              />

                              <ErrorField
                                show={data.q15_1_DateInvalid}
                                message={CommonError_Msgs.InvalidDate}
                              />
                              <ErrorField
                                show={data.q15_1_date_Empty_Err}
                                message={DiseaseHistErrorMsg.DateFirstValidMsg}
                              />
                            </div>
                            <div className="sub-question">
                              <span className="sub-question-quest">Q15.2.</span>
                              <span className="quest-text-pad">
                                Date of last radiotherapy on or within 3 months
                                prior to initiating{" "}
                                <span
                                  style={{
                                    color: PageDesignConstants.fontColor,
                                    fontWeight: "647",
                                  }}
                                >
                                  {data.cohortTreatmentName}
                                 
                                </span>
                              </span>
                              <div className="double-dig-answer">
                                <div className="option-without-input">
                                  <input
                                    type="radio"
                                    id={index}
                                    name={`q15_2${index}`}
                                    value={1}
                                    checked={data.q15_2 === 1}
                                    onChange={HandleRepeaterFieldChange}
                                  />
                                  <span className="radio-text-padding">
                                    {/* <DateControl
                                      ctrlId={`q15_2_date-${index}`}
                                      HandleDateChange={
                                        HandleRepeaterDateChange
                                      }
                                      //defDate={data.q15_2_default_date}
                                      date={data.q15_2_date}
                                    /> */}
                                     <DatePickerComponent
                                    ctrlId={`q15_2_date-${index}`}
                                    // selectedDate={selectedDate}
                                    // onDateChange={(newDate) => setSelectedDate(newDate)}
                                    HandleDateChange={HandleRepeaterDateChange}
                                    //optionId={1}
                                    minDate={dayjs("01-01-1920")} 
                                    maxDate={dayjs()} 
                                    locale="en" // for dd/mm/yyyy use "en-gb"
                                    date={data.q15_2_date}
                                  />
                                  </span>
                                </div>
                                <div className="option-without-input">
                                  <label>
                                    <input
                                      type="radio"
                                      id={index}
                                      name={`q15_2${index}`}
                                      value={99}
                                      checked={data.q15_2 === 99}
                                      onChange={HandleRepeaterFieldChange}
                                    />

                                    <span className="radio-text-padding">
                                      Unknown
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <ErrorField
                                show={data.q15_2_hard_Err}
                                message={DiseaseHistErrorMsg.ProvideOptions}
                              />

                              <ErrorField
                                show={data.q15_2_DateInvalid}
                                message={CommonError_Msgs.InvalidDate}
                              />

                              <ErrorField
                                show={data.q15_2_date_Err}
                                message={DiseaseHistErrorMsg.DateLastErrMsg}
                              />

                              <ErrorField
                                show={data.q15_2_date_Greater_Error}
                                message={"Date of first radiotherapy should be less than or equal to Date of last radiotherapy"}
                              />

                              <ErrorField
                                show={data.q15_2_date_Empty_Err}
                                message={DiseaseHistErrorMsg.DateLastValidMsg}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                    <div></div>
                    {/* Question 16 */}
                    {shouldRenderDiv && (
                      <div>
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>Q16.</span>
                            <span className="quest-text-pad">
                              Please provide any SLiM-CRAB diagnostic criteria
                              recorded in the patient’s chart at initial MM
                              diagnosis.
                            </span>
                          </div>
                        </div>
                        <div className="sub-question">
                          <table
                            style={{
                              border: "1px solid black",
                              borderCollapse: "collapse",
                              width: "100%",
                            }}
                          >
                            <div>
                              <b> &nbsp; &nbsp;Evidence of end organ damage</b>{" "}
                            </div>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <span className="sub-question-quest">
                                  Q16.1.
                                </span>
                                <span className="quest-text-pad">
                                  {
                                    "Hypercalcemia  (defined as serum calcium >0.25 mmol/L [>1 mg/dL] higher than the upper limit of normal or >2.75 mmol/L [>11 mg/dL]) "
                                  }
                                </span>
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_1"
                                        name="q16_1"
                                        value={1}
                                        checked={diseaseHist.q16_1 === 1}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Yes
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_1"
                                        name="q16_1"
                                        value={2}
                                        checked={diseaseHist.q16_1 === 2}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        No
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_1"
                                        name="q16_1"
                                        value={99}
                                        checked={diseaseHist.q16_1 === 99}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Unknown/ Not tested
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <ErrorField
                                  show={hardErrList.q16_1_hard_err}
                                  message={DiseaseHistErrorMsg.ProvideOptions}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <span className="sub-question-quest">
                                  Q16.2.
                                </span>
                                <span className="quest-text-pad">
                                  {
                                    "Renal insufficiency(defined as creatinine clearance <40 mL/min measured or estimated by validated equations, or serum creatinine >177 μmol/L [>2 mg/dL])"
                                  }
                                </span>
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_2"
                                        name="q16_2"
                                        value={1}
                                        checked={diseaseHist.q16_2 === 1}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Yes
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_2"
                                        name="q16_2"
                                        value={2}
                                        checked={diseaseHist.q16_2 === 2}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        No
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_2"
                                        name="q16_2"
                                        value={99}
                                        checked={diseaseHist.q16_2 === 99}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Unknown/ Not tested
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <ErrorField
                                  show={hardErrList.q16_2_hard_err}
                                  message={DiseaseHistErrorMsg.ProvideOptions}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <span className="sub-question-quest">
                                  Q16.3.
                                </span>
                                <span className="quest-text-pad">
                                  {
                                    "Anemia  (defined as hemoglobin value of >20 g/L below the lower limit of normal, or a hemoglobin value <100 g/L) "
                                  }
                                </span>
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_3"
                                        name="q16_3"
                                        value={1}
                                        checked={diseaseHist.q16_3 === 1}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Yes
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_3"
                                        name="q16_3"
                                        value={2}
                                        checked={diseaseHist.q16_3 === 2}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        No
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_3"
                                        name="q16_3"
                                        value={99}
                                        checked={diseaseHist.q16_3 === 99}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Unknown/ Not tested
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <ErrorField
                                  show={hardErrList.q16_3_hard_err}
                                  message={DiseaseHistErrorMsg.ProvideOptions}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <span className="sub-question-quest">
                                  Q16.4.
                                </span>
                                <span className="quest-text-pad">
                                  {
                                    "Lytic bone lesions  (ie., one or more osteolytic lesions on skeletal radiography, computed tomography (CT), or Positron Emission Tomography [PET]-CT) "
                                  }
                                </span>
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_4"
                                        name="q16_4"
                                        value={1}
                                        checked={diseaseHist.q16_4 === 1}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Yes
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_4"
                                        name="q16_4"
                                        value={2}
                                        checked={diseaseHist.q16_4 === 2}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        No
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_4"
                                        name="q16_4"
                                        value={99}
                                        checked={diseaseHist.q16_4 === 99}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Unknown/ Not tested
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <ErrorField
                                  show={hardErrList.q16_4_hard_err}
                                  message={DiseaseHistErrorMsg.ProvideOptions}
                                />
                              </td>
                            </tr>
                            <div>
                              <b> &nbsp; &nbsp;Biomarkers of malignancy</b>{" "}
                            </div>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <span className="sub-question-quest">
                                  Q16.5.
                                </span>
                                <span className="quest-text-pad">
                                  {
                                    "Clonal bone marrow plasma cell percentage ≥ 60% "
                                  }
                                </span>
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_5"
                                        name="q16_5"
                                        value={1}
                                        checked={diseaseHist.q16_5 === 1}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Yes
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_5"
                                        name="q16_5"
                                        value={2}
                                        checked={diseaseHist.q16_5 === 2}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        No
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_5"
                                        name="q16_5"
                                        value={99}
                                        checked={diseaseHist.q16_5 === 99}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Unknown/ Not tested
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <ErrorField
                                  show={hardErrList.q16_5_hard_err}
                                  message={DiseaseHistErrorMsg.ProvideOptions}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <span className="sub-question-quest">
                                  Q 16.6.
                                </span>
                                <span className="quest-text-pad">
                                  {
                                    "Involved:uninvolved serum free light chain ratio ≥100 "
                                  }
                                </span>
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_6"
                                        name="q16_6"
                                        value={1}
                                        checked={diseaseHist.q16_6 === 1}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Yes
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_6"
                                        name="q16_6"
                                        value={2}
                                        checked={diseaseHist.q16_6 === 2}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        No
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_6"
                                        name="q16_6"
                                        value={99}
                                        checked={diseaseHist.q16_6 === 99}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Unknown/ Not tested
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <ErrorField
                                  show={hardErrList.q16_6_hard_err}
                                  message={DiseaseHistErrorMsg.ProvideOptions}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <span className="sub-question-quest">
                                  Q 16.7.
                                </span>
                                <span className="quest-text-pad">
                                  {
                                    "More than 1 focal lesion on magnetic resonance imaging (MRI) studies  "
                                  }
                                </span>
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_7"
                                        name="q16_7"
                                        value={1}
                                        checked={diseaseHist.q16_7 === 1}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Yes
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_7"
                                        name="q16_7"
                                        value={2}
                                        checked={diseaseHist.q16_7 === 2}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        No
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q16_7"
                                        name="q16_7"
                                        value={99}
                                        checked={diseaseHist.q16_7 === 99}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Unknown/ Not tested
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <ErrorField
                                  show={hardErrList.q16_7_hard_err}
                                  message={DiseaseHistErrorMsg.ProvideOptions}
                                />
                              </td>
                            </tr>
                          </table>
                        </div>
                        {/* Question 17 */}
                        <div className="question-bot-sapce">
                          <div className="question question-weight">
                            <span>Q17.</span>
                            <span className="quest-text-pad">
                              Please provide results for each disease assessment
                              based on the record on or closest to the
                              initiation of &nbsp;
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                {
                                  cohortTreatmentModelListt[0]
                                    .cohortTreatmentName
                                }
                              
                              </span>
                              &nbsp; on &nbsp;
                              <span
                                style={{ color: PageDesignConstants.fontColor }}
                              >
                                {cohortTreatmentModelListt[0].cohortIndexDate}
                              </span>
                              &nbsp;.
                            </span>
                          </div>
                        </div>
                        <div className="sub-question">
                          <table
                            style={{
                              border: "1px solid black",
                              borderCollapse: "collapse",
                              width: "100%",
                            }}
                          >
                            <tr>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <span className="sub-question-quest">
                                  Q17.1.
                                </span>
                                <span className="quest-text-pad">
                                  Lytic bone lesion assessment
                                </span>
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q17_1"
                                        name="q17_1"
                                        value={1}
                                        checked={diseaseHist.q17_1 === 1}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Present
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q17_1"
                                        name="q17_1"
                                        value={2}
                                        checked={diseaseHist.q17_1 === 2}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Absent
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q17_1"
                                        name="q17_1"
                                        value={99}
                                        checked={diseaseHist.q17_1 === 99}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                       Unknown or not assessed
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <ErrorField
                                  show={hardErrList.q17_1_hard_err}
                                  message={DiseaseHistErrorMsg.ProvideOptions}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <span className="sub-question-quest">
                                  Q17.2.
                                </span>
                                <span className="quest-text-pad">
                                  Extramedullary disease assessment{" "}
                                </span>
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q17_2"
                                        name="q17_2"
                                        value={1}
                                        checked={diseaseHist.q17_2 === 1}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Present
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q17_2"
                                        name="q17_2"
                                        value={2}
                                        checked={diseaseHist.q17_2 === 2}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Absent
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q17_2"
                                        name="q17_2"
                                        value={99}
                                        checked={diseaseHist.q17_2 === 99}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Unknown or not assessed
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <ErrorField
                                  show={hardErrList.q17_2_hard_err}
                                  message={DiseaseHistErrorMsg.ProvideOptions}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <span className="sub-question-quest">
                                  Q 17.3.
                                </span>
                                <span className="quest-text-pad">
                                  Extramedullary or soft tissue plasmacytoma
                                  assessment{" "}
                                </span>
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q17_3"
                                        name="q17_3"
                                        value={1}
                                        checked={diseaseHist.q17_3 === 1}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Present
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q17_3"
                                        name="q17_3"
                                        value={2}
                                        checked={diseaseHist.q17_3 === 2}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Absent
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q17_3"
                                        name="q17_3"
                                        value={99}
                                        checked={diseaseHist.q17_3 === 99}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                      Unknown or not assessed
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <ErrorField
                                  show={hardErrList.q17_3_hard_err}
                                  message={DiseaseHistErrorMsg.ProvideOptions}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <span className="sub-question-quest">
                                  Q 17.4.
                                </span>
                                <span className="quest-text-pad">
                                  Paramedullary plasmacytoma assessment{" "}
                                </span>
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "8px",
                                }}
                              >
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q17_4"
                                        name="q17_4"
                                        value={1}
                                        checked={diseaseHist.q17_4 === 1}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Present
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q17_4"
                                        name="q17_4"
                                        value={2}
                                        checked={diseaseHist.q17_4 === 2}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                        Absent
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <div className="double-dig-answer">
                                  <div className="option-without-input">
                                    <label>
                                      <input
                                        type="radio"
                                        id="q17_4"
                                        name="q17_4"
                                        value={99}
                                        checked={diseaseHist.q17_4 === 99}
                                        onChange={HandleFieldChange}
                                      />

                                      <span className="radio-text-padding">
                                       Unknown or not assessed
                                      </span>
                                    </label>
                                  </div>
                                </div>
                                <ErrorField
                                  show={hardErrList.q17_4_hard_err}
                                  message={DiseaseHistErrorMsg.ProvideOptions}
                                />
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="survey-section-content-foot">
                  <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DiseaseHistory;
