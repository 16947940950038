import { IsAdmin } from "../Helpers/Utilities";
import WebServiceWrapper from "./WebServiceWrapper";

export const DiseaseHistoryService = {
  SaveDiseaseHistory,
  LoadDiseaseHistory,
};

function SaveDiseaseHistory(payload) {
  return WebServiceWrapper.Post("/diseaseHistory/SaveDiseaseHistory", payload);
}

function LoadDiseaseHistory(pid) {
    return WebServiceWrapper.Get(`/diseaseHistory/LoadDiseaseHistory?pid=${pid}&isAdmin=${IsAdmin()}`);
}
