import React, { useContext, useEffect, useState } from "react";
import SaveAndNavigate from "../../../Components/SaveAndNavigate";
import PatientDetails from "../../../Components/PatientInformation";
import SideBarMenu from "../../../Components/SideBarMenu";
import {
  HTTPResponse,
  SideMenuItems,
  ToastMessageType,
  ToastMessages,
} from "../../../Helpers/Enums";
import DateControl from "../../../Components/DateControl";
import ErrorField from "../../../Components/ErrorField";
import { Tooltip } from "react-tooltip";
import {
  CohortDefiningTreatment_Msgs,
    CommonError_Msgs,
  hyperlinkTextQ19,
} from "../../../Helpers/HelperText";
import { CohortDefiningTreatmentService } from "../../../WebApiServices/CohortDefiningTreatment.service";
import {
  AddLocalStorageData,
  DateInRange,
  GetLocalStorageData,
  GetLoggedInUserID,
  IsAdmin,
  convertDateFormat,
} from "../../../Helpers/Utilities";
import { AppContext } from "../../../Contexts/AppContextProvider";
import InfoPopUp from "../../../Components/InfoPopUp";
import dayjs from "dayjs";
import DatePickerComponent from "../../../Components/DatePickerComponent";

const CohortDefiningTreatment = () => {
  const pid = GetLocalStorageData("patientId") ?? 0;
  let patientData = GetLocalStorageData("patient");
  patientData = patientData && JSON.parse(patientData);
  const chartAbstractionDate = patientData?.surveyDate ?? "";

  const storedData = GetLocalStorageData("CohortTreatment");
  let cohortTreatmentModelListt = [];

  if (storedData) {
    cohortTreatmentModelListt = JSON.parse(storedData);
  }

  const NdmmIsPresent = cohortTreatmentModelListt.some(
    (item) => item.ndmm === true && item.isEligible === true
  );

  const navigatePaths = {
    prevPage: "/Comorbidities",
    currPage: "/CohortEligibilityTreatment",
    nextPage: NdmmIsPresent ? "/NDMMTreatmentCohort" : "/BispecificsCartTreatmentCohort",
  };

  const [latestCohortIndexDate, setLatestCohortIndexDate] = useState("");

  const [showHideHyperlinkModalQ19, setShowHideHyperlinkModalQ19] =
    useState(false);

    const { ShowToast, ToggleLoader, ShowActionPopUp, HandleSessionTimeout,isSideMenuActive,setIsSideMenuActive } =
    useContext(AppContext);
  const [showTopErrMsg, setShowTopErrMsg] = useState(false);
  const [cohortTreatmentData, setCohortTreatmentData] = useState({
    LastPatientEncounterDate: "",
    DeathDateDate: "",
    DeathDate: null,
  });

  const [errorList, setErrorList] = useState({
    LastPatientEncounterDateEmpty: false,
    LastPatientEncounterDateValidError: false,
    LastPatientEncounterDateInvalid: false,
    DeathDateEmpty: false,
    DeathDateInvalid:false,
    DeathDateValid: false,
    DeathDateValidError: false,
    DeathDateDateEmpty: false,
  });

  const HandleFieldChange = (e) => {
    const { name, value } = e.target;

    if (name === "DeathDate") {
      setCohortTreatmentData((prevData) => ({
        ...prevData,
        [name]: parseInt(value),
        DeathDateDate: value === "2" ? "" : prevData.DeathDateDate,
      }));
      setErrorList((prev) => ({
        ...prev,
        DeathDateEmpty: false,
        DeathDateDateEmpty: false,
        DeathDateValidError: false,
      }));
    }
  };

  const HandleDateChange = (ctrlId, date) => {
    // Clear related error if date is filled

    if (ctrlId === "LastPatientEncounterDate" && date !== "") {
      // let PatientEncounterDateValid = compareLastPatientEncounterDate(
      //   latestCohortIndexDate,
      //   date,
      //   chartAbstractionDate
      // );
      setCohortTreatmentData((prevData) => ({
        ...prevData,
        LastPatientEncounterDate: date,
      }));
      setErrorList((prev) => ({
        ...prev,
        LastPatientEncounterDateEmpty: false,
        LastPatientEncounterDateInvalid:false,
        // LastPatientEncounterDateValidError: !PatientEncounterDateValid
        //   ? true
        //   : false,
      }));
    }

    if (ctrlId === "DeathDateDate" && date !== "") {
      debugger
      // let DeathDateValid = CohortDeathDateValidation(
      //   latestCohortIndexDate,
      //   cohortTreatmentData.LastPatientEncounterDate,
      //   date,
      //   chartAbstractionDate
      // );
      setCohortTreatmentData((prevData) => ({
        ...prevData,
        DeathDateDate: date,
        DeathDate: 1,
      }));
      setErrorList((prev) => ({
        ...prev,
        DeathDateEmpty: false,
        DeathDateDateEmpty: false,
        DeathDateValid:false,
        DeathDateInvalid:false,

        // DeathDateValidError: !DeathDateValid ? true : false,
      }));
    }
  };

  const ValidateForm = () => {
    let valid = true;
    const errors = {
      LastPatientEncounterDateEmpty: false,
      DeathDateEmpty: false,
      DeathDateDateEmpty: false,
      DeathDateValid: false,
      DeathDateInvalid:false,
      LastPatientEncounterDateValidError: false,
      LastPatientEncounterDateInvalid:false,
      DeathDateValidError: false,
    };
debugger
  
    if (cohortTreatmentData.LastPatientEncounterDate === "" )
     {
      errors.LastPatientEncounterDateEmpty = true;
      valid = false;
     } 

    else if (cohortTreatmentData.LastPatientEncounterDate !== "" && cohortTreatmentData.LastPatientEncounterDate != "Invalid Date" && DateInRange(cohortTreatmentData.LastPatientEncounterDate , dayjs("01-01-1920"), dayjs())) 
    {
      let PatientEncounterDateValid = compareLastPatientEncounterDate(
        latestCohortIndexDate,
        cohortTreatmentData.LastPatientEncounterDate,
        chartAbstractionDate
      );
      if (!PatientEncounterDateValid) {
        errors.LastPatientEncounterDateValidError = true;
        valid = false;
      }
    }
    
    if (cohortTreatmentData.LastPatientEncounterDate === "Invalid Date")
    {
      errors.LastPatientEncounterDateInvalid = true;
      valid = false;
    }

    else if(cohortTreatmentData.LastPatientEncounterDate !== "Invalid Date" && cohortTreatmentData.LastPatientEncounterDate !== "" && !DateInRange(cohortTreatmentData.LastPatientEncounterDate, dayjs("01-01-1920"), dayjs())){
      errors.LastPatientEncounterDateInvalid = true;
      valid = false;
    }  

    //Question 20   
    if (cohortTreatmentData.DeathDate === null) {
      errors.DeathDateEmpty = true;
      valid = false;
    } else if (
      cohortTreatmentData.DeathDate === 1 &&
      cohortTreatmentData.DeathDateDate === "" && cohortTreatmentData.DeathDateDate !== "Invalid Date"
    ) {
      errors.DeathDateDateEmpty = true;
      valid = false;
    } else if (cohortTreatmentData.DeathDate === 1 && cohortTreatmentData.DeathDateDate !== "" && DateInRange(cohortTreatmentData.DeathDateDate, dayjs("01-01-1920"), dayjs())) {
      let DeathDateValid = CohortDeathDateValidation(
        latestCohortIndexDate,
        cohortTreatmentData.LastPatientEncounterDate,
        cohortTreatmentData.DeathDateDate,
        chartAbstractionDate
      );
      if (!DeathDateValid && cohortTreatmentData.DeathDateDate !== "Invalid Date") {
        errors.DeathDateValidError = true;
        valid = false;
      }
    }
    if ( cohortTreatmentData.DeathDateDate === "Invalid Date")
    {
      errors.DeathDateInvalid = true;
      valid = false;
    }
    else if(cohortTreatmentData.DeathDateDate !== "Invalid Date" && cohortTreatmentData.DeathDateDate !== "" && !DateInRange(cohortTreatmentData.DeathDateDate, dayjs("01-01-1920"), dayjs())){
      errors.DeathDateInvalid = true;
      valid = false;
    }  

    setErrorList(errors);
    return valid;
  };

  useEffect(() => {
    LoadData();
  }, []);

  async function LoadData() {
    try {
      if (pid > 0) {
        ToggleLoader(true);
        const response =
          await CohortDefiningTreatmentService.LoadCohortDefiningTreatment(pid);
        ToggleLoader(false);
        if (response?.status === HTTPResponse.OK) {
          const { cohortDefiningTreatment, cohortTreatmentModelList } =
            response.data;

          // Find the latest cohortIndexDate from cohortTreatmentModelList
          let latestCohortIndexDate = null;
          if (cohortTreatmentModelList && cohortTreatmentModelList.length > 0) {
            latestCohortIndexDate = cohortTreatmentModelList
              .map((item) => item.cohortIndexDate)
              .filter((date) => date !== null)
              .sort((a, b) => {
                const dateA = parseDate(a);
                const dateB = parseDate(b);
                return dateB - dateA;
              })[0];

            setLatestCohortIndexDate(latestCohortIndexDate);
          }

          if (cohortDefiningTreatment) {
            setCohortTreatmentData((prev) => ({
              ...prev,
              LastPatientEncounterDate:
                cohortDefiningTreatment.lastencounterDate ?? "",
              DeathDateDate: cohortDefiningTreatment.deathDate ?? "",
              DeathDate: cohortDefiningTreatment.patientAlive ? 2 : 1,
            }));
          }
        } else if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (er) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }

  const SavePage = async (validate) => {
    try {
      ToggleLoader(true);
      let isValid = validate ? ValidateForm() : true;
      if (isValid) {
        const payload = {
          patientId: pid,
         // lastEncounterDate: cohortTreatmentData.LastPatientEncounterDate,

          lastEncounterDate : cohortTreatmentData.LastPatientEncounterDate != "Invalid Date" && cohortTreatmentData.LastPatientEncounterDate
            ? cohortTreatmentData.LastPatientEncounterDate :
            cohortTreatmentData.LastPatientEncounterDate === "Invalid Date"?""
            : "",
          deathDate:
            cohortTreatmentData.DeathDate === 1 && cohortTreatmentData.DeathDate  != "Invalid Date"
              ? cohortTreatmentData.DeathDateDate
              : "",
             

          patientAlive: cohortTreatmentData.DeathDate === 2,


          endOfFollowUpDate:
            cohortTreatmentData.DeathDate === 1
              ? cohortTreatmentData.DeathDateDate
              : cohortTreatmentData.LastPatientEncounterDate,
          userId: GetLoggedInUserID(),
          isValidated: validate,
          IsAdmin: IsAdmin()
        };

        if (pid > 0) {
          const response =
            await CohortDefiningTreatmentService.SaveCohortDefiningTreatment(
              payload
            );

          if (response.status !== HTTPResponse.OK) {
            if (response?.status === HTTPResponse.Unauthorized) {
              HandleSessionTimeout();
            } else throw response.error;
          } else {
            ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
            setErrorList({
              LastPatientEncounterDateEmpty: false,
              LastPatientEncounterDateValidError: false,
              LastPatientEncounterDateInvalid:false,
              DeathDateEmpty: false,
              DeathDateValid: false,
              DeathDateInvalid:false,
              DeathDateValidError: false,
              DeathDateDateEmpty: false,
            });
              setShowTopErrMsg(false);


              if (response?.data?.showDateChangeAffectAlert) {
                  ShowAlertBox();
              }
          }
        }
        return true;
      } else {
        setShowTopErrMsg(true);
        return false;
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    } finally {
      ToggleLoader(false);
    }
  };

    /* <summary>
        date: 27-08-2024
        Name: BV
        description: Show an alert message when other page(end of follow up) could be affected
        <summary>*/
    const ShowAlertBox = async () => {
        const choice = await ShowActionPopUp(
            "Alert", //title
            CommonError_Msgs.DateChangeAlert, //message
            "Ok", //Confirm button text
            true
        );
    };


  function parseDate(dateStr) {
    const [month, day, year] = dateStr.split("/");
    return new Date(year, month - 1, day);
  }

  function compareLastPatientEncounterDate(
    cohortIndexDateStr,
    lastEncounterDateStr,
    chartAbstractionDateStr
  ) {
    const cohortIndexDate = cohortIndexDateStr
      ? parseDate(cohortIndexDateStr)
      : null;
    const lastEncounterDate = lastEncounterDateStr
      ? parseDate(lastEncounterDateStr)
      : null;
    const chartAbstractionDate = chartAbstractionDateStr
      ? parseDate(chartAbstractionDateStr)
      : null;

    // Ensure all dates are valid before comparison
    if (cohortIndexDate && lastEncounterDate && chartAbstractionDate) {
      return (
        cohortIndexDate <= lastEncounterDate &&
        lastEncounterDate <= chartAbstractionDate
      );
    }

    // If any of the dates are invalid, return false
    return false;
  }

  function CohortDeathDateValidation(
    latestIndexDate,
    lastEncounterDate,
    deathDate,
    chartAbstractionDate
  ) {
    // Parse all dates
    const latestIndex = parseDate(latestIndexDate);
    const lastEncounter = parseDate(lastEncounterDate);
    const death = parseDate(deathDate);
    const chartAbstraction = parseDate(chartAbstractionDate);

    // If death date is not provided, we assume the patient is alive
    if (!death) {
      // In this case, we only need to check if lastEncounterDate is valid
      return lastEncounter && lastEncounter <= chartAbstraction;
    }

    // Validate: Latest of NDMM index date or Bispecific/CAR-T index date ≤ date of death
    const isIndexDateValid = latestIndex && latestIndex <= death;

    // Validate: Date of last encounter ≤ Date of death ≤ Date of chart abstraction
    const isDeathDateValid =
      lastEncounter &&
      chartAbstraction &&
      lastEncounter <= death &&
      death <= chartAbstraction;

    return isIndexDateValid && isDeathDateValid;
  }

  const sidebar = document.getElementById("mySidebar");
  const main = document.getElementById("main");

  const toggleNav = () => {
    if (isSideMenuActive === true  ) { 
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
      setIsSideMenuActive(false)
    } else if( isSideMenuActive===false ){
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
      setIsSideMenuActive(true)
    }
}

useEffect(() => {
const   sidebar = document.getElementById("mySidebar");
const  main = document.getElementById("main");


  if (isSideMenuActive === true) {
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
    } else {
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
    }
});


  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div >
          <SideBarMenu activePageId={SideMenuItems.CohortDefiningTreatment} />

          <>
          <button className={ isSideMenuActive? "openbtn": "openbtn active" }
        // onclick="toggleNav()" 
        onClick={() => toggleNav()} 
         >
          <i
            id="arrowNavigation"
            class="fa fa-arrow-left"
            aria-hidden="true"
          ></i>
         </button>
         </>
          <div className="col-pad-left" id="main">
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>Cohort Defining Treatment</h4>
              </div>
              {showTopErrMsg && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
              <div className="survey-question-section">
                <div className="survey-question-content">
                  <div
                    className="question-bot-sapce"
                    style={{ marginBottom: "25px" }}
                  >
                    <div className="question question-weight">
                      <span style={{ fontWeight: "500" }}>Q19.</span>
                      <span
                        className="quest-text-pad"
                        style={{ fontWeight: "500" }}
                      >
                        <span>&nbsp;Date of</span>{" "}
                        <span
                          className="modal-popup"
                          data-tooltip-id="encounterTip"
                          onClick={() => setShowHideHyperlinkModalQ19(true)}
                        >
                          last patient encounter
                        </span>
                        {showHideHyperlinkModalQ19 && (
                          <InfoPopUp
                            description={hyperlinkTextQ19}
                            OkClick={() => setShowHideHyperlinkModalQ19(false)}
                          />
                        )}{" "}
                      </span>
                    </div>
                    <div className="double-dig-answer">
                      <div
                        className="option-without-input"
                        style={{ display: "inline-flex" }}
                      >
                        <span
                          className="radio-text-padding"
                          style={{ paddingLeft: "17px" }}
                        >
                          {/* <DateControl
                            ctrlId="LastPatientEncounterDate"
                            HandleDateChange={HandleDateChange}
                            date={cohortTreatmentData.LastPatientEncounterDate}
                          /> */}
                           <DatePickerComponent
                                    ctrlId="LastPatientEncounterDate"
                                    // selectedDate={selectedDate}
                                    // onDateChange={(newDate) => setSelectedDate(newDate)}
                                    HandleDateChange={HandleDateChange}
                                    //optionId={1}
                                    minDate={dayjs("01-01-1920")} 
                                    maxDate={dayjs()} 
                                    locale="en" // for dd/mm/yyyy use "en-gb"
                                    date={cohortTreatmentData.LastPatientEncounterDate}
                                  />
                                   
                        </span>
                      </div>
                    </div>
                    <ErrorField
                      show={errorList.LastPatientEncounterDateEmpty}
                      message={CohortDefiningTreatment_Msgs.Q19Empty}
                    />
                    <ErrorField
                      show={errorList.LastPatientEncounterDateInvalid}
                      message={CommonError_Msgs.InvalidDate}
                    />
                    <ErrorField
                      show={errorList.LastPatientEncounterDateValidError}
                      message={CohortDefiningTreatment_Msgs.Q19DateValidError}
                    />
                  </div>
                  {/* QUESTION 20 */}
                  <div
                    className="question-bot-sapce"
                    style={{ marginBottom: "25px" }}
                  >
                    <div className="question question-weight">
                      <span style={{ fontWeight: "500" }}>Q20.</span>
                      <span
                        className="quest-text-pad"
                        style={{ fontWeight: "500" }}
                      >
                        &nbsp;Date of death
                      </span>
                    </div>
                    <div className="double-dig-answer">
                      <div
                        className="option-without-input"
                        style={{ display: "inline-flex" }}
                      >
                        <input
                       
                          type="radio"
                          id="DeathDate"
                          name="DeathDate"
                          value={1}
                          checked={cohortTreatmentData.DeathDate == 1}
                          onChange={HandleFieldChange}
                        />

                        <span className="radio-text-padding" style={{paddingLeft:"6px"}}>
                          {/* <DateControl
                            ctrlId="DeathDateDate"
                            HandleDateChange={HandleDateChange}
                            date={cohortTreatmentData.DeathDateDate}
                          /> */}
                           <DatePickerComponent
                                    ctrlId="DeathDateDate"
                                    // selectedDate={selectedDate}
                                    // onDateChange={(newDate) => setSelectedDate(newDate)}
                                    HandleDateChange={HandleDateChange}
                                    //optionId={1}
                                    minDate={dayjs("01-01-1920")} 
                                    maxDate={dayjs()} 
                                    locale="en" // for dd/mm/yyyy use "en-gb"
                                    date={cohortTreatmentData.DeathDateDate}
                                  />
                        </span>
                      </div>
                      <div className="option-without-input">
                        <label htmlFor="DeathDaterd">
                          <input
                            type="radio"
                            id="DeathDaterd"
                            name="DeathDate"
                            value={2}
                            checked={cohortTreatmentData.DeathDate === 2}
                            onChange={HandleFieldChange}
                          />

                          <span className="radio-text-padding">
                            &nbsp;Patient is believed to be alive at the time of
                            chart abstraction.
                          </span>
                        </label>
                      </div>
                    </div>
                    <ErrorField
                      show={errorList.DeathDateEmpty}
                      message={CohortDefiningTreatment_Msgs.Q20Empty}
                    />

                    <ErrorField
                      show={errorList.DeathDateInvalid}
                      message={CommonError_Msgs.InvalidDate}
                    />

                    <ErrorField
                      show={errorList.DeathDateDateEmpty}
                      message={CohortDefiningTreatment_Msgs.Q20EmptyDate}
                    />
                    <ErrorField
                      show={errorList.DeathDateValidError}
                      message={CohortDefiningTreatment_Msgs.Q20DateValidError}
                    />
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CohortDefiningTreatment;
