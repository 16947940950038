import React, { useContext, useEffect, useState } from "react";
import PatientDetails from "../../Components/PatientInformation";
import SaveAndNavigate from "../../Components/SaveAndNavigate";
import {
  SideMenuItems,
  HTTPResponse,
  ToastMessageType,
  ToastMessages,
} from "../../Helpers/Enums";
import SideBarMenu from "../../Components/SideBarMenu";
import ErrorField from "../../Components/ErrorField";
import {
  CommonError_Msgs,
  demographicScreen_Msgs,
  EligibilityCriteria_Msgs,
} from "../../Helpers/HelperText";
import { AppContext } from "../../Contexts/AppContextProvider";
import {
  GetLocalStorageData,
  GetLoggedInUserID,
  IsAdmin,
  IsDateAfter,
  isStringEmpty,
} from "../../Helpers/Utilities";
import DateControl from "../../Components/DateControl";
import { EligibilityCriteriaService } from "../../WebApiServices/EligibilityCriteria.service";
import DatePickerComponent from "../../Components/DatePickerComponent";
import dayjs from "dayjs";

const GeneralInformation = () => {
  const [isEligible, setIsEligible] = useState(false);
  const [isAllDataFilled, setIsAllDataFilled] = useState(false);

  const pid = GetLocalStorageData("patientId") ?? 0;
  let patientData = GetLocalStorageData("patient");
  patientData = patientData && JSON.parse(patientData);
  const centerId = patientData?.ptCenterId ?? 0;
  const surveyDate = patientData?.surveyDate ?? "";
  const eligibilityCriteriaLocked =
    patientData?.eligibilityCriteriaLocked ?? false;

  const navigatePaths = {
    prevPage: "/ChartSelection",
    currPage: "/GeneralInformation",
    nextPage: eligibilityCriteriaLocked
      ? "/NDMMCriteria"
      : isAllDataFilled && isEligible
      ? "/NDMMCriteria"
      : isAllDataFilled && !isEligible
      ? "/ManagePatients"
      : "/GeneralInformation",
  };

  const {
    ShowToast,
    ToggleLoader,
    HandleSessionTimeout,
    isSideMenuActive,
    setIsSideMenuActive,
  } = useContext(AppContext);

  useEffect(() => {
    async function FetchData() {
      if (!isNaN(pid) && Number(pid) !== 0) {
        ToggleLoader(true);
        await LoadData();
        ToggleLoader(false);
      }
    }
    FetchData();
  }, []);

  const [genInfo, setGenInfo] = useState({
    userId: GetLoggedInUserID(),
    pt_s2_RD_MM: null,
    pt_s3_RD_MM_DATE: null,
    pt_s4_RD_MM_Date: null,
    s3_initial_mm_dt: null,
    s4_birth_date: null,
      metInclusionCriteria: null,
    eligibilityDisabled:false,
  });

  const [showTopErrMsg, setShowTopErrMsg] = useState(false);

  const InitialSoftErrList = {
    s1Terminate: false,
    s2Terminate: false,
    s2_unk: false,
    s3Terminate: false,
    s4Terminate: false,
    s4_unk: false,
  };
  const [softErrList, setSoftErrList] = useState(InitialSoftErrList);

  const InitialHardErrList = {
    s1Empty: false,
    s2Empty: false,
    s3Empty: false,
    s3EmptyDate: false,
    s4Empty: false,
    s4EmptyDate: false,
    s4DOBValidate: false,
    DOBInvalid:false,
    Invalid_s3_initial_mm_dt:false,

  };
  const [hardErrList, setHardErrList] = useState(InitialHardErrList);

 
  async function LoadData() {
    
    try {
      if (pid > 0) {
        // Fetch data from the service
        const response =
          await EligibilityCriteriaService.LoadGeneralInformation(pid);

        if (response?.status === HTTPResponse.OK) {
          const data = response.data;

          // Update the state with the fetched data
          setGenInfo((prev) => ({
            ...prev,
            pt_s2_RD_MM: data.mmDiagnosisConfirmed
              ? 1
              : data.mmDiagnosisConfirmed == false
              ? 0
              : null,
            s3_initial_mm_dt: !isStringEmpty(data.mmDiagnosisDate)
              ? data.mmDiagnosisDate
              : null,
            pt_s3_RD_MM_DATE: data.mmDiagnosisDateUnk
              ? 99
              : !isStringEmpty(data.mmDiagnosisDate)
              ? 1
              : null,
            s4_birth_date: !isStringEmpty(data.birthDate)
              ? data.birthDate
              : null,
            pt_s4_RD_MM_Date: data.birthDateUnk
              ? 99
              : !isStringEmpty(data.birthDate)
              ? 1
              : null,
              metInclusionCriteria: data.metInclusionCriteria,
              eligibilityDisabled: data.eligibilityLocked,
          }));
        } else if (response?.status === HTTPResponse.Unauthorized) {
          HandleSessionTimeout();
        }
      }
    } catch (error) {
      ShowToast(CommonError_Msgs.LOAD_ERR, ToastMessageType.Failed);
    }
  }

  const HandleFieldChange = (e) => {
    const { name, id, value } = e.target;
    switch (name) {
      case "pt_s2_RD_MM": {
        const value_int = Number(value);
        setGenInfo((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
        }));

        setSoftErrList((prev) => ({
          ...prev,
          s2Terminate: value_int === 2 || value_int === 0,
        }));

        setHardErrList((prev) => ({
          ...prev,
          s2Empty: false,
        }));
        break;
      }
      case "pt_s3_RD_MM_DATE": {
        const value_int = Number(value);
        setGenInfo((prev) => ({
          ...prev,
          [name]: parseInt(value), //value,
        }));

        setHardErrList((prev) => ({
          ...prev,
          s3Empty: false,
          s3EmptyDate: false,
          Invalid_s3_initial_mm_dt:false
        }));

        setGenInfo((prev) => ({
          ...prev,
          s3_initial_mm_dt: "",

          // Clear the date field
        }));

        break;
      }
      case "pt_s4_RD_MM_Date": {
        const value_int = Number(value);
        setGenInfo((prev) => ({
          ...prev,
          [name]: parseInt(value),

          //value,
        }));
        setSoftErrList((prev) => ({
          ...prev,
          s4Terminate: value_int === 99,
        }));

        setHardErrList((prev) => ({
          ...prev,
          s4Empty: false,
          s4EmptyDate: false,
          DOBInvalid:false
        }));

        setGenInfo((prev) => ({
          ...prev,
          s4_birth_date: "",

          // Clear the date field
        }));

        break;
      }

      default:
        break;
    }
  };

  const DateInRange = (date, minDate, maxDate) => {
    return dayjs(date).isAfter(minDate) && dayjs(date).isBefore(maxDate);
  };
  

  const ValidateForm = () => {
    let isValid = true;
    //S2.
    if (genInfo.pt_s2_RD_MM === null) {
      setHardErrList((prev) => ({
        ...prev,
        s2Empty: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        s2Empty: false,
      }));
    }

    //S3.
    if (genInfo.pt_s3_RD_MM_DATE === null) {
      setHardErrList((prev) => ({
        ...prev,
        s3Empty: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        s3Empty: false,
      }));
    }

    if (
      genInfo.pt_s3_RD_MM_DATE === 1 &&
      (genInfo.s3_initial_mm_dt === null || genInfo.s3_initial_mm_dt === "")
    ) {
      setHardErrList((prev) => ({
        ...prev,
        s3EmptyDate: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        s3EmptyDate: false,
      }));
    }



    if(genInfo.pt_s3_RD_MM_DATE === 1 && genInfo.s3_initial_mm_dt === "Invalid Date")
    {
      
      setHardErrList((prev) => ({
        ...prev,
        Invalid_s3_initial_mm_dt: true, 
      }));
      isValid = false;
    }
    
    
    if(genInfo.pt_s3_RD_MM_DATE === 1 &&
       genInfo.s3_initial_mm_dt && !DateInRange(
        genInfo.s3_initial_mm_dt, dayjs("01-01-1920"), dayjs()
       )
      )
    {
      
      setHardErrList((prev) => ({
        ...prev,
        Invalid_s3_initial_mm_dt: true, 
      }));
      isValid = false;
    }


    //S4.
    if (genInfo.pt_s4_RD_MM_Date === null) {
      setHardErrList((prev) => ({
        ...prev,
        s4Empty: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        s4Empty: false,
      }));
    }



    if (
      genInfo.pt_s4_RD_MM_Date === 1 &&
      (!genInfo.s4_birth_date || genInfo.s4_birth_date === "" )
    ) {
      setHardErrList((prev) => ({
        ...prev,
        s4EmptyDate: true,
      }));
      isValid = false;
    } else {
      setHardErrList((prev) => ({
        ...prev,
        s4EmptyDate: false,
      }));
    }

if(genInfo.pt_s4_RD_MM_Date === 1 && genInfo.s4_birth_date === "Invalid Date")
{
  
  setHardErrList((prev) => ({
    ...prev,
    DOBInvalid: true, 
  }));
  isValid = false;
}


if(genInfo.pt_s4_RD_MM_Date === 1 &&
   genInfo.s4_birth_date && !DateInRange(
    genInfo.s4_birth_date, dayjs("01-01-1920"), dayjs()
   )
  )
{
  
  setHardErrList((prev) => ({
    ...prev,
    DOBInvalid: true, 
  }));
  isValid = false;
}



    // Date Validation: Check that date of birth ≤ initial MM diagnosis date
    if (genInfo.s4_birth_date && genInfo.s3_initial_mm_dt && DateInRange(
      genInfo.s3_initial_mm_dt, dayjs("01-01-1920"), dayjs()) ){
      const isDOBInValid = IsDateAfter(
        genInfo.s4_birth_date,
        genInfo.s3_initial_mm_dt
      );
      if (isDOBInValid) {
        setHardErrList((prev) => ({
          ...prev,
          s4DOBValidate: true, // Add a new error field to track this specific validation
        }));
        isValid = false;
      } else {
        setHardErrList((prev) => ({
          ...prev,
          s4DOBValidate: false,
        }));
      }
    }

    setIsAllDataFilled(isValid);

    return isValid;
  };

  const SavePage = async () => {
    try {
      debugger
      setHardErrList(InitialHardErrList);
      setSoftErrList(InitialSoftErrList);
      ToggleLoader(true);

      if (!ValidateForm()) {
        setShowTopErrMsg(true);
        ToggleLoader(false);
        return false;
      }
      setShowTopErrMsg(false);

      const eligibilityCriteriademographicModel = {
        patientId: pid,
        centerId: centerId,
        userId: GetLoggedInUserID(),
        mmdiagnosisConfirmed: genInfo.pt_s2_RD_MM,
        mmdiagnosisDate: !isStringEmpty(genInfo.s3_initial_mm_dt)
          ? genInfo.s3_initial_mm_dt
          : null,
        mmdiagnosisDateUnk: genInfo.pt_s3_RD_MM_DATE === 99 ? 1 : 0,
        birthDate: !isStringEmpty(genInfo.s4_birth_date)
          ? genInfo.s4_birth_date
          : null,
        birthDateUnk: genInfo.pt_s4_RD_MM_Date === 99 ? 1 : 0,
        metInclusionCriteria:
          genInfo.pt_s2_RD_MM == 0 || genInfo.pt_s4_RD_MM_Date == 99
            ? false
            : true,

        cohortTreatmentModelList: [],
        IsAdmin: IsAdmin(),
      };

      setIsEligible(eligibilityCriteriademographicModel.metInclusionCriteria);

      if (pid > 0) {
        const response =
          await EligibilityCriteriaService.SaveGeneralInformation(
            eligibilityCriteriademographicModel
          );
        ToggleLoader(false);
        if (response.status !== HTTPResponse.OK) {
          if (response?.status === HTTPResponse.Unauthorized) {
            HandleSessionTimeout();
          } else throw response.error;
        } else {
          ShowToast(ToastMessages.Save_Success, ToastMessageType.Success);
          await LoadData();

          if (eligibilityCriteriademographicModel.metInclusionCriteria == true)
            navigatePaths.nextPage = "/NDMMCriteria";
        }
      }
      return true;
    } catch (error) {
      ShowToast(CommonError_Msgs.SAVE_ERR, ToastMessageType.Failed);
    }
  };

  const HandleDateChange = (name, date, optionId, monthOrYearSelect) => {
    debugger
    switch (name) {
      case "s3_initial_mm_dt":
        {
          setGenInfo((prev) => ({
            ...prev,
            [name]: date,
            pt_s3_RD_MM_DATE: 1,
          }));
          setHardErrList((prev) => ({
            ...prev,
            s3EmptyDate: false,
            s3Empty: false,
            Invalid_s3_initial_mm_dt:false,
          }));
        }
        break;
      case "s4_birth_date":
        {
          setGenInfo((prev) => ({
            ...prev,
            [name]: date,
            pt_s4_RD_MM_Date: 1,
          }));

          setHardErrList((prev) => ({
            ...prev,
            s4EmptyDate: false,
            s4Empty: false,
            s4DOBValidate: false,
            DOBInvalid:false,
            
          }));

          setSoftErrList((prev) => ({
            ...prev,
            s4Terminate: false,
          }));
        }
        break;
      default:
        break;
    }
  };


  const sidebar = document.getElementById("mySidebar");
  const main = document.getElementById("main");

  const toggleNav = () => {

    if (isSideMenuActive === true) {
      sidebar.style.width = "0";
      main.style.marginLeft = "0";
      setIsSideMenuActive(false);
    } else {
      sidebar.style.width = "350px";
      main.style.marginLeft = "350px";
      setIsSideMenuActive(true);
    }
  };

  useEffect(() => {
    const sidebar = document.getElementById("mySidebar");
    const main = document.getElementById("main");
    if (isSideMenuActive === true) {
        sidebar.style.width = "350px";
        main.style.marginLeft = "350px";
      } else {
        sidebar.style.width = "0";
        main.style.marginLeft = "0";
      }
  });


  return (
    <>
      <section className="survey-patient-details-section">
        <div className="row">
          <PatientDetails />
          <div className="col-12 col-sm-5 col-md-5 col-lg-6 col-content-alignment">
            <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
          </div>
        </div>
      </section>
      <section className="main-survey-section">
        <div>
          <SideBarMenu activePageId={SideMenuItems.GeneralInformation} />
          <>
            <button
              className={isSideMenuActive ? "openbtn" : "openbtn active"}
              // onclick="toggleNav()"
              onClick={() => toggleNav()}
            >
              <i
                id="arrowNavigation"
                class="fa fa-arrow-left"
                aria-hidden="true"
              ></i>
            </button>
          </>
                  <div className="col-pad-left eligibiltylocked" id="main" >
            <div className="survey-section-content">
              <div className="survey-section-content-header">
                <h4>GENERAL INFORMATION</h4>
              </div>

              {!showTopErrMsg &&
                pid != 0 &&
                genInfo.metInclusionCriteria != null &&
                genInfo.metInclusionCriteria == false && (
                  <div className="clean-error">
                    <p>{EligibilityCriteria_Msgs.IneligiblePatient}</p>
                  </div>
                )}

              {showTopErrMsg && (
                <div className="clean-error ">
                  <ErrorField
                    show={showTopErrMsg}
                    message={CommonError_Msgs.Hard_Empty}
                  />
                </div>
              )}
                          <div className="survey-question-section" disabled={genInfo.eligibilityDisabled}>
                <div className="survey-question-content">
                  <div className="question-answer">
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>S1.</span>
                        <span className="quest-text-pad">
                          Date of chart abstraction:
                        </span>
                        &nbsp;&nbsp;&nbsp;{surveyDate}
                      </div>
                    </div>
                    {genInfo.metInclusionCriteria != null}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>S2.</span>
                        <span className="quest-text-pad">
                          Confirmed diagnosis of MM (this excludes patients with
                          a diagnosis of smoldering MM only)
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="s2_RD_MM_Yes"
                              name="pt_s2_RD_MM"
                              value={1}
                              checked={
                                genInfo.pt_s2_RD_MM === 1 //"1"
                              }
                              onChange={HandleFieldChange}
                            />

                            <span className="radio-text-padding">Yes</span>
                          </label>
                        </div>
                        <div className="option-without-input">
                          <label>
                            <input
                              type="radio"
                              id="s2_RD_MM_No"
                              name="pt_s2_RD_MM"
                              value={0}
                              checked={genInfo.pt_s2_RD_MM === 0}
                              onChange={HandleFieldChange}
                            />

                            <span className="radio-text-padding">No</span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={hardErrList.s2Empty}
                        message={EligibilityCriteria_Msgs.Radio}
                      />

                      <ErrorField
                        show={softErrList.s2Terminate}
                        message={EligibilityCriteria_Msgs.INELIGIBLE}
                      />
                    </div>
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>S3.</span>
                        <span className="quest-text-pad">
                          Date of initial MM diagnosis
                        </span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <input
                            type="radio"
                            id="s3_RD_MM_DATE_YES"
                            name="pt_s3_RD_MM_DATE"
                            value={1}
                            checked={genInfo.pt_s3_RD_MM_DATE === 1}
                            onChange={HandleFieldChange}
                          />

                          <span className="radio-text-padding">
                            {/* <DateControl
                              ctrlId={"s3_initial_mm_dt"}
                              HandleDateChange={HandleDateChange}
                              optionId={1}
                              date={genInfo.s3_initial_mm_dt}
                            /> */}

                        <DatePickerComponent
                          ctrlId={"s3_initial_mm_dt"}
                        // selectedDate={selectedDate}
                          //onDateChange={(newDate) => setSelectedDate(newDate)}
                          HandleDateChange={HandleDateChange}
                          // optionId={1}
                          minDate={dayjs("01-01-1920")} 
                          maxDate={dayjs()} 
                          locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                          date={genInfo.s3_initial_mm_dt}
                        />
                          </span>
                        </div>
                        <div className="option-without-input">
                          <input
                            type="radio"
                            id="s3_RD_MM_DATE_NO"
                            name="pt_s3_RD_MM_DATE"
                            value={99}
                            checked={genInfo.pt_s3_RD_MM_DATE === 99}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="s3_RD_MM_DATE_NO">
                            <span className="radio-text-padding">
                              Date of diagnosis unknown
                            </span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={hardErrList.s3Empty}
                        message={EligibilityCriteria_Msgs.Radio}
                      />

                      <ErrorField
                        show={hardErrList.s3EmptyDate}
                        message={EligibilityCriteria_Msgs.S3Date}
                      />
                      <ErrorField
                        show={hardErrList.Invalid_s3_initial_mm_dt}
                        message={CommonError_Msgs.InvalidDate}
                      />
                    </div>

                    {/* S4. */}
                    <div className="question-bot-sapce">
                      <div className="question question-weight">
                        <span>S4.</span>
                        <span className="quest-text-pad">Date of birth</span>
                      </div>
                      <div className="double-dig-answer">
                        <div className="option-without-input">
                          <input
                            type="radio"
                            id="s4_RD_MM_Date_Yes"
                            name="pt_s4_RD_MM_Date"
                            value={1}
                            checked={genInfo.pt_s4_RD_MM_Date === 1}
                            onChange={HandleFieldChange}
                          />

                          <span className="radio-text-padding">
                            {/* <DateControl
                              ctrlId={"s4_birth_date"}
                              HandleDateChange={HandleDateChange}
                              optionId={1}
                              date={genInfo.s4_birth_date}
                            /> */}

                <DatePickerComponent
                    ctrlId="s4_birth_date"
                   // selectedDate={selectedDate}
                    //onDateChange={(newDate) => setSelectedDate(newDate)}
                    HandleDateChange={HandleDateChange}
                    optionId={1}
                    minDate={dayjs("01-01-1920")} 
                    maxDate={dayjs()} 
                    locale="en" // current it is mm/dd/yyyy but for dd/mm/yyyy use "en-gb"
                    date={genInfo.s4_birth_date}
                  />
                  
                          </span>
                        </div>
                        <div className="option-without-input">
                          <input
                            type="radio"
                            id="s4_RD_MM_Date_No"
                            name="pt_s4_RD_MM_Date"
                            value={99}
                            checked={genInfo.pt_s4_RD_MM_Date === 99}
                            onChange={HandleFieldChange}
                          />
                          <label htmlFor="s4_RD_MM_Date_No">
                            <span className="radio-text-padding">
                              Date of birth unknown
                            </span>
                          </label>
                        </div>
                      </div>
                      <ErrorField
                        show={hardErrList.s4Empty}
                        message={EligibilityCriteria_Msgs.Radio}
                      />

                      <ErrorField
                        show={hardErrList.s4EmptyDate}
                        message={EligibilityCriteria_Msgs.S4Date}
                      />
                      <ErrorField
                        show={hardErrList.s4DOBValidate}
                        message={EligibilityCriteria_Msgs.S4DOBValidate}
                      />
                           <ErrorField
                        show={hardErrList.DOBInvalid}
                        message={CommonError_Msgs.InvalidDate}
                      />
                      
                      <ErrorField
                        show={softErrList.s4Terminate}
                        message={EligibilityCriteria_Msgs.INELIGIBLE}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="survey-section-content-foot">
                <SaveAndNavigate paths={navigatePaths} Save={SavePage} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GeneralInformation;
